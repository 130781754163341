import React from 'react';
import PropTypes from 'prop-types';
import { graphql, navigate } from 'gatsby';
import styled from 'styled-components';

import Layout from 'src/components/Layout';
import SEO from 'src/components/SEO';
import { ContentBlock, LimitedWidth } from 'src/components/Block';
import PageTemplate from 'src/components/PageTemplate';
import Separator from 'src/components/Separator';
import { HTMLContent } from 'src/components/Content';

const Dark = styled.div`
  background-color: whitesmoke;
`;

const RegionSelection = styled.div`
  padding-top: 3em;
`;

const Text = styled.div`
  font-size: 1.5em;
`;

const Select = styled.select`
  margin: 1em 0;
  width: 100%;
  font-size: 1em;
  height: 2em;
`;

const Option = styled.option``;

const TermPageTemplate = ({ title, city, html, location, regions }) => {
  const handleChange = e => {
    const selectedRegion = regions.find(item => item.city === e.target.value);
    if (selectedRegion) {
      navigate(selectedRegion.slug);
    }
  };

  return (
    <Layout location={location}>
      <SEO title={title} />
      {regions.length !== 0 && (
        <Dark>
          <LimitedWidth>
            <ContentBlock>
              <RegionSelection>
                <Text>
                  Choose the terms & conditions you would like to view
                </Text>
                <Select value={city} onChange={handleChange}>
                  {regions.map((term, index) => {
                    return (
                      <Option key={index} value={term.city}>
                        {term.title}, {term.city}
                      </Option>
                    );
                  })}
                </Select>
              </RegionSelection>
            </ContentBlock>
          </LimitedWidth>
        </Dark>
      )}
      <PageTemplate
        title={`${title}, ${city}`}
        html={html}
        contentComponent={HTMLContent}
      />
      <Separator large noColor />
    </Layout>
  );
};

TermPageTemplate.propTypes = {
  title: PropTypes.string.isRequired,
  city: PropTypes.string.isRequired,
  html: PropTypes.string.isRequired,
  location: PropTypes.string.isRequired,
  regions: PropTypes.arrayOf(
    PropTypes.shape({
      city: PropTypes.string.isRequired,
      slug: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired
    })
  )
};

const TermPage = ({ data, location }) => {
  const { frontmatter, html } = data.markdownRemark;

  const regions = data.regions.edges.map(item =>
    Object.assign({}, { ...item.node.fields }, { ...item.node.frontmatter })
  );

  return (
    <TermPageTemplate
      title={frontmatter.title}
      city={frontmatter.city}
      html={html}
      location={location.pathname}
      regions={regions}
    />
  );
};

TermPage.propTypes = {
  data: PropTypes.object,
  location: PropTypes.object
};

export default TermPage;

export const pageQuery = graphql`
  query TermPageTemplate($id: String!) {
    markdownRemark(
      frontmatter: { templateKey: { eq: "term" } }
      id: { eq: $id }
    ) {
      html
      frontmatter {
        city
        title
      }
    }
    regions: allMarkdownRemark(
      filter: {
        frontmatter: { templateKey: { eq: "term" }, publish: { eq: true } }
      }
    ) {
      edges {
        node {
          fields {
            slug
          }
          frontmatter {
            city
            title
          }
        }
      }
    }
  }
`;
