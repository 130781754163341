import React from 'react';
import PropTypes from 'prop-types';
import { useStaticQuery, graphql } from 'gatsby';

import ArticlesWidgetView from './ArticlesWidgetView';

const ArticlesWidget = ({ separator }) => {
  const data = useStaticQuery(graphql`
    query ArticlesWidgetQuery {
      widget: markdownRemark(
        frontmatter: { templateKey: { eq: "articles-widget" } }
      ) {
        frontmatter {
          title
        }
      }
      articles: allMarkdownRemark(
        limit: 3
        sort: { fields: [frontmatter___date], order: DESC }
        filter: {
          frontmatter: { templateKey: { eq: "article" }, publish: { eq: true } }
        }
      ) {
        edges {
          node {
            fields {
              slug
            }
            frontmatter {
              title
              description
              type
              link
              image {
                childImageSharp {
                  gatsbyImageData(layout: FULL_WIDTH)
                }
              }
            }
          }
        }
      }
    }
  `);

  const articles = data.articles.edges.map(item =>
    Object.assign({}, item.node.frontmatter, { slug: item.node.fields.slug })
  );

  return (
    <ArticlesWidgetView
      separator={separator}
      title={data.widget.frontmatter.title}
      articles={articles}
    />
  );
};

ArticlesWidget.propTypes = {
  separator: PropTypes.bool
};

export default ArticlesWidget;
