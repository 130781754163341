import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { getImage } from 'gatsby-plugin-image';
import { BgImage } from 'gbimage-bridge';

const BackgroundImagePreview = styled.div`
  background-image: ${props => (props.image ? `url(${props.image})` : '')};
`;

const PreviewCompatibleBackgroundImage = ({
  image: baseImage,
  stack,
  children,
  alt = '',
  ...otherProps
}) => {
  if (!baseImage) {
    // passing critical & fadeIn to a div element will make react complain
    delete otherProps.critical;
    delete otherProps.fadeIn;
    return (
      <div {...otherProps} alt={alt}>
        {children}
      </div>
    );
  }

  if (typeof baseImage === 'string') {
    return (
      <BackgroundImagePreview image={baseImage} {...otherProps} alt={alt}>
        {children}
      </BackgroundImagePreview>
    );
  }

  const pluginImage = getImage(baseImage);
  const image = stack ? [pluginImage, ...stack] : pluginImage;
  return (
    <BgImage image={image} {...otherProps} alt={alt}>
      {children}
    </BgImage>
  );
};

PreviewCompatibleBackgroundImage.propTypes = {
  image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  alt: PropTypes.string,
  stack: PropTypes.array,
  children: PropTypes.node,
  otherProps: PropTypes.object
};

export default PreviewCompatibleBackgroundImage;
