/* eslint-disable no-console, react/prop-types */

import React, { useState } from 'react'
import {toHTML, getProfile} from 'src/utils'

export const PrimeContext = React.createContext()

const extendPrime = rawPrime => ({
  ...rawPrime,
  caseStudiesExt: rawPrime.casestudies.edges
    ?.map(item => ({...item.node.frontmatter, slug: item.node.fields.slug}))
    ?? [],
  profilesExt: rawPrime.profiles.edges
    // ?.map(item => ({...item.node.frontmatter, slug: item.node.fields.slug}))
    ?.map(item => getProfile(item.node))
    ?? [],
  rolesExt: rawPrime.roles.edges
    ?.map(item => ({...item.node.frontmatter, slug: item.node.fields.slug}))
    ?? [],
  sectorsExt: rawPrime.sectors.edges
    ?.map(item => ({...item.node.frontmatter, slug: item.node.fields.slug}))
    ?? []
})

export const PrimeProvider = ({children, prime: primeInitial}) => {
  const primeInitialExtended = extendPrime(primeInitial)
  const [prime, setPrime] = useState(primeInitialExtended)
  // console.log('PRIME', prime)

  const updatePrime = newPrime => setPrime(extendPrime(newPrime))

  return (
    <PrimeContext.Provider value={{ prime, updatePrime }}>
      {children}
    </PrimeContext.Provider>
  )
}
