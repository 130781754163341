import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';

import GroupWidgetView from 'src/components/widgets/GroupWidget/GroupWidgetView';

const GroupWidget = () => {
  const data = useStaticQuery(graphql`
    query GroupWidgetQuery {
      widget: markdownRemark(
        frontmatter: { templateKey: { eq: "group-widget" } }
      ) {
        frontmatter {
          title
          companies {
            description
            logo {
              childImageSharp {
                gatsbyImageData(layout: FIXED, width: 200)
              }
            }
            calltoaction
            link
          }
        }
      }
    }
  `);

  return (
    <GroupWidgetView
      companies={data.widget.frontmatter.companies}
      title={data.widget.frontmatter.title}
    />
  );
};

export default GroupWidget;
