import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { graphql } from 'gatsby';

import {
  PreviewCompatibleImage,
  PreviewCompatibleBackgroundImage,
  PreviewCompatibleLink as BasePreviewCompatibleLink
} from 'src/components/PreviewCompatible';
import { getAltImage } from 'src/utils';

const PreviewCompatibleLink = styled(BasePreviewCompatibleLink)`
  text-decoration: none;
  color: inherit;
`;

const Name = styled.h2`
  font-size: 1em;
  font-weight: 500;
  @media screen and (min-width: ${({ theme }) =>
    theme.breakpoints.desktopMin}) {
    margin: 0.5em 0;
    ${props =>
    props.small &&
    css`
        margin: 0;
      `}
  }
  @media not screen and (min-width: ${({ theme }) =>
    theme.breakpoints.desktopMin}) {
    margin: 0;
  }
`;

const ItemContent = styled.div`
  cursor: pointer;
  display: flex;
  ${props =>
    !props.small &&
    css`
      @media screen and (min-width: ${({ theme }) =>
        theme.breakpoints.desktopMin}) {
        display: block;
      }
    `}
  ${props =>
    (props.small || props.card) &&
    css`
      padding: 1em;
    `}
`;

const ItemDetails = styled.div`
  ${props =>
    (props.small || props.card) &&
    css`
      display: flex;
      flex-direction: column;
      justify-content: center;
    `}
`;

const Card = styled.div`
  ${props =>
    !props.small &&
    css`
      height: 100%;
    `}
  ${props =>
    !props.small &&
    !props.card &&
    css`
      margin-bottom: 2em;
    `}
  ${props =>
    props.selected &&
    css`
      background-color: ${props => props.theme.secondaryColor};
      color: white;
    `}
`;

const H4 = styled.h4`
  font-size: 0.9em;
  font-weight: 300;
  margin: 0.5em 0 0;
  ${props =>
    props.secondary &&
    css`
      font-size: 0.7em;
      font-weight: 600;
      text-transform: uppercase;
      color: ${props => props.theme.primaryColor};
    `}
`;

const HoverState = styled.div`
  visibility: hidden;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-content: flex-start;
  background-color: ${props => props.theme.darkenLight};
  border-bottom: 3px solid ${props => props.theme.primaryColor};
  ${Card}:hover & {
    visibility: visible;
  }
`;

const Picture = styled(PreviewCompatibleBackgroundImage)``;

const EmptyPicture = styled(Picture)`
  margin-right: 1em;
  flex-basis: 100px;
  width: 100px;
  height: 100px;
  ${props =>
    props.small &&
    css`
      width: 70px;
      height: 70px;
    `}
  ${props =>
    !props.small &&
    css`
      @media screen and (min-width: ${({ theme }) =>
        theme.breakpoints.desktopMin}) {
        height: 13em;
        width: auto;
        flex-basis: auto;
        margin: 0;
      }
    `}
`;

const RoundedPicture = styled(PreviewCompatibleImage)`
  border-radius: 50%;
  flex-shrink: 0;
  flex-basis: 100px;
  width: 100px;
  height: 100px;
  margin-right: 1em;
  ${props =>
    props.small &&
    css`
      flex-basis: 70px;
      width: 70px;
      height: 70px;
    `}
  ${props =>
    !props.small &&
    css`
      @media screen and (min-width: ${({ theme }) =>
        theme.breakpoints.desktopMin}) {
        margin: 0 auto;
        flex-basis: auto;
        width: 10em;
        height: 10em;
      }
    `}
`;

const SquarePicture = styled(Picture)`
  flex-shrink: 0;
  flex-basis: 100px;
  width: 100px;
  height: 100px;
  margin-right: 1em;
  ${props =>
    props.small &&
    css`
      flex-basis: 70px;
      width: 70px;
      height: 70px;
    `}
  ${props =>
    !props.small &&
    css`
      @media screen and (min-width: ${({ theme }) =>
        theme.breakpoints.desktopMin}) {
        margin: 0 auto;
        flex-basis: auto;
        width: 13em;
        height: 13em;
      }
    `}
`;

const PictureBlock = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  @media screen and (min-width: ${({ theme }) =>
    theme.breakpoints.desktopMin}) {
    ${props =>
    !props.small &&
    css`
        height: ${props => (props.card ? '12em' : '13em')};
      `}
  }
`;

export const ProfileCard = React.forwardRef(
  (
    {
      selected,
      name,
      position,
      office = '-',
      picture,
      secondary,
      small,
      card,
      onClick
    },
    ref
  ) => (
    <Card
      small={small}
      card={card}
      selected={selected}
      onClick={onClick}
      ref={ref}
    >
      {/* {console.log('ProfileCard', {name, position, office})} */}
      <ItemContent small={small} card={card}>
        <PictureBlock small={small} card={card}>
          {picture && picture.childImageSharp && (
            <>
              {(small || card) && (
                <RoundedPicture
                  image={picture}
                  small={small}
                  alt={getAltImage('profile', { name, position, office })}
                />
              )}
              {!small && !card && (
                <SquarePicture
                  image={picture}
                  small={small}
                  alt={getAltImage('profile', { name, position, office })}
                >
                  <HoverState />
                </SquarePicture>
              )}
            </>
          )}
          {(!picture || !picture.childImageSharp) && (
            <EmptyPicture
              src="/img/people.png"
              small={small}
              alt={getAltImage('profile', { name, position, office })}
            >
              <HoverState />
            </EmptyPicture>
          )}
        </PictureBlock>
        <ItemDetails small={small}>
          <Name small={small}>{name}</Name>
          <H4>{position}</H4>
          {office && <H4 secondary={secondary}>{office}</H4>}
        </ItemDetails>
      </ItemContent>
    </Card>
  )
);

ProfileCard.displayName = 'ProfileCard';

ProfileCard.defaultProps = {
  small: false,
  card: false,
  secondary: false,
  selected: false
};

ProfileCard.propTypes = {
  onClick: PropTypes.func,
  name: PropTypes.string.isRequired,
  position: PropTypes.string.isRequired,
  office: PropTypes.string, // .isRequired,
  picture: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  secondary: PropTypes.bool,
  small: PropTypes.bool,
  card: PropTypes.bool,
  selected: PropTypes.bool
};

export const ProfileCardLink = React.forwardRef(
  ({ data, onClick, secondary, small, card, selected }, ref) => (
    <PreviewCompatibleLink
      to={data.slug}
      state={{ profile: data }}
      onClick={onClick}
    >
      <ProfileCard
        {...data}
        secondary={secondary}
        small={small}
        card={card}
        selected={selected}
        ref={ref}
      />
    </PreviewCompatibleLink>
  )
);

ProfileCardLink.displayName = 'ProfileCardLink';

ProfileCardLink.defaultProps = {
  secondary: false,
  selected: false,
  card: false,
  small: false
};

ProfileCardLink.propTypes = {
  onClick: PropTypes.func,
  secondary: PropTypes.bool,
  selected: PropTypes.bool,
  small: PropTypes.bool,
  card: PropTypes.bool,
  data: PropTypes.shape({
    slug: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    position: PropTypes.string.isRequired,
    office: PropTypes.string, // .isRequired,
    picture: PropTypes.oneOfType([PropTypes.object, PropTypes.string])
  })
};

export const profileQuery = graphql`
  fragment ProfileDetails on ProfileType {
    id
    fields {
      articles {
        id
        fields {
          slug
        }
        frontmatter {
          link
          title
          type
          image {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH)
            }
          }
        }
      }
      slug
      office
      sectors {
        fields {
          slug
        }
        frontmatter {
          name
        }
      }
      roles {
        fields {
          slug
        }
        frontmatter {
          name
        }
      }
    }
    frontmatter {
      name
      position
      office
      category
      picture {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
      description
      contactDetails {
        email
        phone
      }
      roles
      sectors
    }
  }
`;
