import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';

import JoinUsPageTemplate from 'src/components/pages/JoinUsPageTemplate';
import {toHTML} from 'src/utils';

const JoinUsPage = ({ data, location, }) => {
  const { frontmatter, html } = data.markdownRemark;
  // console.log('JoinUsPage', {data}) // eslint-disable-line

  return (
    <JoinUsPageTemplate
      html={html}
      articlesWidget={frontmatter.articlesWidget}
      groupWidget={frontmatter.groupWidget}
      image={frontmatter.image}
      whyHeader={frontmatter.why.header}
      whyContent={toHTML(frontmatter.why.content)}
      heading={frontmatter.heading}
      seo={frontmatter.seo}
      numbers={frontmatter.numbers}
      location={location.pathname}
      offerHeader={frontmatter.offer.header}
      offerItems={frontmatter.offer.items}
      explore={frontmatter.explore}
      vacancies={frontmatter.vacancies}
      looking={frontmatter.looking}
      whatIsItLike={frontmatter.whatIsItLike}
    />
  );
};

JoinUsPage.propTypes = {
  data: PropTypes.object,
  location: PropTypes.object
};

export default JoinUsPage;

export const pageQuery = graphql`
  query JoinMarlinHawkPageTemplate {
    markdownRemark(
      frontmatter: { templateKey: { eq: "join-marlin-hawk-page" } }
    ) {
      html
      frontmatter {
        articlesWidget
        groupWidget
        image {
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH)
          }
        }
        why {
          header
          content
        }
        offer {
          header
          items {
            label
            image {
              childImageSharp {
                gatsbyImageData(layout: FIXED, height: 100)
              }
              extension
              publicURL
            }
          }
        }
        vacancies {
            header
            description
            items {
              title
              subtitle
              link
            }
        }
        explore {
            header
            items {
              label
              link
              image {
                  childImageSharp {
                    gatsbyImageData(layout: FULL_WIDTH)
                  }
              }
            }
        }
        looking {
            header
            description
            items {
               image {
                  childImageSharp {
                    gatsbyImageData(layout: FIXED, width: 50)
                  }
                  extension
                  publicURL
                }
                label
            }
            blocks {
                header
                image {
                  childImageSharp {
                    gatsbyImageData(layout: FULL_WIDTH)
                  }
                }
                content
            }
        }
        whatIsItLike {
            header
            items {
                name
                description
                link
                office
                image {
                  childImageSharp {
                    gatsbyImageData(layout: FULL_WIDTH)
                  }
                }
            }
        }
        heading
        seo {
          metaDescription
          focusKeyword
        }
      }
    }
  }
`;
