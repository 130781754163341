import React, { useState } from 'react';
import styled from 'styled-components';
// import PropTypes from 'prop-types';

import { PreviewCompatibleBackgroundImage } from 'src/components/PreviewCompatible';
import { H3 } from 'src/components/Block';

const RegionBlock = styled.div`
  flex-basis: 30%;
  margin: 3.8em 0 5em;
  min-width: 250px;
  min-height: 280px;
`;

const RegionSpace = styled.div`
  flex-basis: 70%;
  margin: 0;
  min-width: 750px;
  min-height: 280px;
`;


const Block = styled.div`
  @media screen and (min-width: ${({ theme }) =>
      theme.breakpoints.desktopMin}) {
    margin-left: .5em;
  }
`;

const OfficesBlock = styled.div`
  background-color: ${props => props.theme.bgColor};
  display: none;
  @media screen and (min-width: ${({ theme }) =>
      theme.breakpoints.desktopMin}) {
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding: 5em 5em;
  }
`;

const Background = styled(PreviewCompatibleBackgroundImage)`
  display: flex;
  justify-content: center;
  background-repeat: no-repeat;
  background-position: center;
  background-origin: content-box;
  background-size: contain;
  position: relative;
  top: 20px;
`;

const SmallText = styled.p`
  font-size: 0.7em;
  margin: 0.2em 0;
  a {
    color: inherit;
    text-decoration: none;
  }
`;

const City = styled.h6`
  font-size: 1em;
  font-weight: 500;
  margin-bottom: 0.5em;
`;

const Regions = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const Separator = styled.div`
  margin: 0 2em;
  border-right: 1px solid ${props => props.theme.primaryColor};
`;

const FilterContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
`;

const RegionTag = styled.div`
  cursor: pointer;
  text-align: center;
  border-radius: 5em;
  margin: 0.5em 0;
  padding: 0.5em 2em;
  font-size: 0.7em;
  background-color: ${props =>
    props.active ? props.theme.primaryColor : 'transparent'};
  color: ${props => props.theme.secondaryColor};
  border: 1px solid ${props => props.theme.primaryColor};
  text-transform: uppercase;
  font-weight: 500;
`;

const RegionTitle = styled(H3)`
  color: ${props => props.theme.secondaryColor};
`;

/* eslint-disable no-console, react/prop-types */

const OfficesMapWidgetView = ({ offices, manyOffices, regions, image }) => {
  const [selectedOffice, setOffice] = useState('London')
  const officeDetails = manyOffices.find(office => office.city === selectedOffice)

  // console.log({regions, offices, image, officeDetails, selectedOffice})

  return (
    <OfficesBlock>
      <Regions>
        <FilterContent>
          <RegionTitle>Locations</RegionTitle>
          {manyOffices.map(({city: office}, index) => {
            return (
              <RegionTag
                key={index}
                active={office === selectedOffice}
                onClick={() => setOffice(office)}
              >
                {office}
              </RegionTag>
            );
          })}
        </FilterContent>
        <Separator />
      </Regions>
      <Background
        image={image}
        style={{ backgroundSize: 'contain', backgroundOrigin: 'content-box', width: '1000px' }}
      >
        {officeDetails && 
          <RegionBlock key='rb'>
            <Block>
              <div key='ll'>
                <City>{officeDetails.city}</City>
                <SmallText key='aa'>Address:<br/></SmallText>
                {officeDetails.address.split('\n').map((text, ix) => <SmallText key={ix}>{text}</SmallText>)}
                {officeDetails.phone && <SmallText><br/>Phone:<br/>{officeDetails.phone}</SmallText>}
                {officeDetails.gmapslink && 
                  <a href={officeDetails.gmapslink}>
                    <img src='/img/gps.png' style={{width: '32px', marginLeft: '-8px'}} />
                  </a>}
              </div>
            </Block>
          </RegionBlock>}
        <RegionSpace />  
      </Background>
    </OfficesBlock>
  );
};

// OfficesMapWidgetView.propTypes = {
//   regions: PropTypes.arrayOf(PropTypes.string),
//   offices: PropTypes.arrayOf(
//     PropTypes.shape({
//       region: PropTypes.string,
//       offices: PropTypes.arrayOf(
//         PropTypes.shape({
//           city: PropTypes.string,
//           address: PropTypes.string,
//           phone: PropTypes.string
//         })
//       )
//     })
//   ).isRequired,
//   image: PropTypes.oneOfType([PropTypes.object, PropTypes.string])
// };

export default OfficesMapWidgetView;
