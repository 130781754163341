import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import {
  NavLink,
  NavLinkExternal,
  NavLinkWrapper,
  NavLinkInner
} from 'src/components/Block';
import HoverMenu from 'src/components/Header/HoverMenu';
import SubMenu from 'src/components/Header/SubMenu';

// const NavLinkImg = styled(NavLink)`
//     padding: 22px 8px 21px;
// `
const NavLinkImgExternal = styled(NavLinkExternal)`
  padding: 22px 8px 21px;
`;
const WhatWeDoSubMenu = ({
  items,
  name,
  to,
  showDropdown,
  setShowDropdown
}) => {
  const Content = (
    <>
      <SubMenu to={to} items={items} />
    </>
  );

  return (
    <HoverMenu
      showDropdown={showDropdown}
      setShowDropdown={setShowDropdown}
      content={Content}
    >
      <NavLinkWrapper
        onClick={() => setShowDropdown(!showDropdown)}
        className={showDropdown ? 'active' : ''}
      >
        <NavLink to={to}>
          <NavLinkInner>{name}</NavLinkInner>
        </NavLink>
      </NavLinkWrapper>
    </HoverMenu>
  );
};

WhatWeDoSubMenu.propTypes = {
  items: PropTypes.array,
  name: PropTypes.string,
  to: PropTypes.string,
  showDropdown: PropTypes.bool.isRequired,
  setShowDropdown: PropTypes.func
};

const Nav = styled.div`
  display: none;
  @media screen and (min-width: ${({ theme }) =>
      theme.breakpoints.desktopMin}) {
    display: flex;
  }
  align-items: center;
  justify-content: flex-end;
`;

const DesktopMenu = ({
  services,
  roles,
  sectors,
  setShowDropdown,
  showDropdown,
  showArticles,
  showCaseStudies
}) => (
  <Nav>
    <NavLinkWrapper>
      <NavLink to="/about-us/">
        <NavLinkInner>About Us</NavLinkInner>
      </NavLink>
    </NavLinkWrapper>
    <WhatWeDoSubMenu
      items={services}
      name="Services"
      to="/what-we-do/services/"
      showDropdown={showDropdown === 'services'}
      setShowDropdown={value => setShowDropdown(value ? 'services' : null)}
    />
    <WhatWeDoSubMenu
      items={roles}
      name="Functions"
      to="/what-we-do/roles/"
      showDropdown={showDropdown === 'roles'}
      setShowDropdown={value => setShowDropdown(value ? 'roles' : null)}
    />
    <WhatWeDoSubMenu
      items={sectors}
      name="Industries"
      to="/what-we-do/sectors/"
      showDropdown={showDropdown === 'sectors'}
      setShowDropdown={value => setShowDropdown(value ? 'sectors' : null)}
    />

    {showCaseStudies && (
      <NavLinkWrapper>
        <NavLink to="/portfolio/">
          <NavLinkInner>Portfolio</NavLinkInner>
        </NavLink>
      </NavLinkWrapper>
    )}
    <WhatWeDoSubMenu
      items={[
        { name: 'Global Leadership', path: '/management/' },
        { name: 'Meet the Team', path: '/team/' },
        { name: 'Join Marlin Hawk', path: '/join-marlin-hawk/' }
      ]}
      name="Our Team"
      to="/team/"
      showDropdown={showDropdown === 'team'}
      setShowDropdown={value =>
        setShowDropdown(value !== 'team' ? 'team' : null)
      }
    />
    {showArticles && (
      <WhatWeDoSubMenu
        items={[{ name: 'Media', path: '/media/' }]}
        name="News & Insights"
        to="/articles/"
        showDropdown={showDropdown === 'news'}
        setShowDropdown={value =>
          setShowDropdown(value !== 'news' ? 'news' : null)
        }
      />
    )}
    <NavLinkWrapper>
      <NavLink to="/contact-us/">
        <NavLinkInner>Contact Us</NavLinkInner>
      </NavLink>
    </NavLinkWrapper>

    <NavLinkWrapper>
      <NavLinkImgExternal
        href="https://www.linkedin.com/company/the-marlin-hawk-group/"
        target="_blank"
        rel="noopener noreferrer"
      >
        <img src="/img/linkedin.png" style={{ width: '20px' }} />
      </NavLinkImgExternal>
    </NavLinkWrapper>
    <NavLinkWrapper>
      <NavLinkImgExternal
        href="https://twitter.com/MarlinHawk"
        target="_blank"
        rel="noopener noreferrer"
      >
        <img src="/img/x.png" style={{ width: '20px' }} />
      </NavLinkImgExternal>
    </NavLinkWrapper>
  </Nav>
);

DesktopMenu.propTypes = {
  services: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      path: PropTypes.string
    })
  ).isRequired,
  roles: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      path: PropTypes.string
    })
  ).isRequired,
  sectors: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      path: PropTypes.string
    })
  ).isRequired,
  showDropdown: PropTypes.string,
  setShowDropdown: PropTypes.func.isRequired,
  showArticles: PropTypes.bool,
  showCaseStudies: PropTypes.bool
};

export default DesktopMenu;
