import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

export const TextIcon = styled.span`
  font-size: 2em;
  line-height: 0.8em;
  color: ${props => props.theme.primaryColor};
`;

const IconBorder = styled.span`
  border: 1px solid ${props => props.theme.primaryColor};
  width: 2em;
  height: 2em;
  text-align: center;
  display: block;
`;

export const TextIconWithValue = ({ value }) => <TextIcon>{value}</TextIcon>;

TextIconWithValue.propTypes = {
  value: PropTypes.string.isRequired
};

export const CrossTextIcon = () => <TextIconWithValue value="&#215;" />;

export const IconWithBorder = ({ children }) => (
  <IconBorder>{children}</IconBorder>
);

IconWithBorder.propTypes = {
  children: PropTypes.node
};
