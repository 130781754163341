import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';

import Layout from 'src/components/Layout';
import PageTemplate from 'src/components/PageTemplate';
import SEO from 'src/components/SEO';
import Separator from 'src/components/Separator';
import { HTMLContent } from 'src/components/Content';

const CookiesPage = ({ data, location }) => {
  const { frontmatter, html } = data.markdownRemark;

  return (
    <Layout location={location.pathname}>
      <SEO
        title={frontmatter.title}
        description={frontmatter.seo.metaDescription}
        keywords={frontmatter.seo.focusKeyword}
      />
      <PageTemplate
        title={frontmatter.title}
        html={html}
        contentComponent={HTMLContent}
      />
      <Separator large noColor />
    </Layout>
  );
};

CookiesPage.propTypes = {
  data: PropTypes.object,
  location: PropTypes.object
};

export default CookiesPage;

export const pageQuery = graphql`
  query CookiesPageTemplate {
    markdownRemark(frontmatter: { templateKey: { eq: "cookies-page" } }) {
      html
      frontmatter {
        title
        seo {
          metaDescription
          focusKeyword
        }
      }
    }
  }
`;
