import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { PreviewCompatibleLink } from 'src/components/PreviewCompatible';

const NavLinks = styled.div`
  display: table;
  border-collapse: collapse;
  width: 100%;
`;

const Border = styled.div`
  padding: 1em 5em 0.5em 5em;
  border-bottom: 1px solid ${props => props.theme.primaryColor};
  margin-bottom: 1em;
`;

const NavLink = styled.div`
  display: table-cell;
  width: 50%;
  & + div {
    border-left: 1px solid ${props => props.theme.primaryColor};
  }
`;

const NavLinkRight = styled(NavLink)`
  text-align: right;
`;

const LinkText = styled.div`
  color: ${props => props.theme.primaryColor};
  font-size: 1em;
`;

const LinkTitle = styled.div`
  font-weight: 500;
  font-size: 1.2em;
  margin: 1em 0 0;
  ${props =>
    props.location &&
    css`
      color: grey;
      margin: 0 0 1em;
    `}
`;

const BlockLink = styled(PreviewCompatibleLink)`
  padding: 1em 1em 2.5em;
  color: black;
  text-decoration: none;
  display: block;
  color: #3b3b3b;
`;

const PrevLink = ({ prev, name }) => (
  <NavLink>
    <BlockLink to={prev.fields.slug}>
      <LinkTitle>{prev.frontmatter.title}</LinkTitle>
      <LinkTitle location>{prev.frontmatter.location}</LinkTitle>
      <LinkText>
        <img
          src="/img/arrow-link.png"
          width="20"
          style={{ transform: 'scaleX(-1)' }}
        />{' '}
        Previous {name}
      </LinkText>
    </BlockLink>
  </NavLink>
);

PrevLink.propTypes = {
  prev: PropTypes.object,
  name: PropTypes.string
};

const NextLink = ({ next, name }) => (
  <NavLinkRight>
    <BlockLink to={next.fields.slug}>
      <LinkTitle>{next.frontmatter.title}</LinkTitle>
      <LinkTitle location>{next.frontmatter.location}</LinkTitle>
      <LinkText>
        Next {name} <img src="/img/arrow-link.png" width="20" />
      </LinkText>
    </BlockLink>
  </NavLinkRight>
);

NextLink.propTypes = {
  next: PropTypes.object,
  name: PropTypes.string
};

const EmptyLink = styled(NavLink)`
  background-color: initial;
  border: none;
`;

const NextPrevious = ({ prev, next, name }) => (
  <Border>
    <NavLinks>
      {prev && <PrevLink prev={prev} name={name} />}
      {!prev && <EmptyLink />}
      {next && <NextLink next={next} name={name} />}
      {!next && <EmptyLink />}
    </NavLinks>
  </Border>
);

NextPrevious.displayName = 'NextPrevious';

NextPrevious.propTypes = {
  name: PropTypes.string,
  next: PropTypes.object,
  prev: PropTypes.object
};

export default NextPrevious;
