import React from 'react';
import PropTypes from 'prop-types';
import { useStaticQuery, graphql } from 'gatsby';

import VerticalSocialMediaWidgetView from './VerticalSocialMediaWidgetView';

const VerticalSocialMediaWidget = ({ url }) => {
  const data = useStaticQuery(graphql`
    query VerticalSocialMediaWidgetQuery {
      markdownRemark(
        frontmatter: { templateKey: { eq: "social-media-widget" } }
      ) {
        ...SocialMediaWidgetFragment
      }
    }
  `);

  const {
    markdownRemark: {
      frontmatter: { linkedin, facebook, twitter, weblink, vertical }
    }
  } = data;

  if (!vertical) {
    return null;
  }

  return (
    <VerticalSocialMediaWidgetView
      url={url}
      linkedInIcon={linkedin.display && linkedin.icon ? linkedin.icon : null}
      facebookIcon={facebook.display && facebook.icon ? facebook.icon : null}
      twitterIcon={twitter.display && twitter.icon ? twitter.icon : null}
      weblinkIcon={weblink.display && weblink.icon ? weblink.icon : null}
    />
  );
};

VerticalSocialMediaWidget.propTypes = {
  url: PropTypes.string
};

export default VerticalSocialMediaWidget;
