import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';

import AboutPageTemplate from 'src/components/pages/AboutPageTemplate';

const AboutPage = ({ data, location }) => {
  const { frontmatter, html } = data.markdownRemark;
  // console.log('AboutUsPage data', {data}) // eslint-disable-line
 
  return (
    <AboutPageTemplate
      html={html}
      articlesWidget={frontmatter.articlesWidget}
      groupWidget={frontmatter.groupWidget}
      valueWidget={frontmatter.valueWidget}
      image={frontmatter.image}
      heading={frontmatter.heading}
      seo={frontmatter.seo}
      numbers={frontmatter.numbers}
      location={location.pathname}
    />
  );
};

AboutPage.propTypes = {
  data: PropTypes.object,
  location: PropTypes.object
};

export default AboutPage;

export const pageQuery = graphql`
  query AboutPageTemplate {
    markdownRemark(
      frontmatter: { templateKey: { eq: "about-us-history-page" } }
    ) {
      html
      frontmatter {
        articlesWidget
        groupWidget
        image {
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH)
          }
        }
        valueWidget {
          header
          items {
            head
            desc
            image {
              childImageSharp {
                gatsbyImageData(layout: FIXED, height: 100)
              }
              extension
              publicURL
            }
          }
        }
        heading
        seo {
          metaDescription
          focusKeyword
        }
      }
    }
  }
`;
