import React, { useState } from 'react';
import PropTypes from 'prop-types';

const useProfileModal = initialProfile => {
  const [profile, setProfile] = useState(initialProfile);
  const [modalIsOpen, setIsOpen] = useState(initialProfile ? true : false);

  const selectProfile = data => {
    setProfile(data);
    if (data) {
      setIsOpen(true);
    } else {
      setIsOpen(false);
    }
  };

  return { modalIsOpen, profile, selectProfile };
};

const ProfilesWithModal = ({ initialProfile, children }) => {
  const { modalIsOpen, profile, selectProfile } = useProfileModal(
    initialProfile
  );

  return <>{children({ modalIsOpen, profile, selectProfile })}</>;
};

ProfilesWithModal.propTypes = {
  initialProfile: PropTypes.object,
  children: PropTypes.func.isRequired
};

export { useProfileModal, ProfilesWithModal };
