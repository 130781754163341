import styled from 'styled-components';

export const RegionTitle = styled.h1`
  font-weight: 300;
  font-size: 1.8em;
  text-align: center;
  border-bottom: 1px solid ${props => props.theme.inputColor};
  margin: 1em 0 0.3em;
  padding-bottom: 0.3em;
`;

export const List = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0.5em 0;
  @media screen and (min-width: ${({ theme }) =>
      theme.breakpoints.desktopMin}) {
    padding: 3em 5em 0;
  }
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
`;

export const Item = styled.li`
  @media screen and (min-width: ${({ theme }) =>
      theme.breakpoints.desktopMin}) {
    padding: 0.5em 0;
  }
  @media not screen and (min-width: ${({ theme }) =>
      theme.breakpoints.desktopMin}) {
    margin: 0.5em 0;
  }
`;
