/* eslint-disable no-console, react/prop-types */

import React, {useContext} from 'react'
import styled from 'styled-components'
import {ListContainer, ListBlock, ListLink} from 'src/components/PageBlock'
// import {ComponentId} from 'src/components/Block'
import { getAltImage } from 'src/utils'
import {PrimeContext} from 'src/hooks/PrimeContext'
import { PreviewCompatibleLink } from 'src/components/PreviewCompatible'
import { StyledContent } from 'src/components/Block';
import { HTMLContent } from 'src/components/Content';

const PortfolioListContainer = styled(ListContainer)`
  display: block;
`
const PortfolioListBlock = styled(ListBlock)`
  width: calc(100% - 22px);
  max-width: 1200px;
  margin-top: 4px;
`

export const PortfolioListTemplate = ({casestudies}) => 
  <PortfolioListContainer>
    {casestudies.map((data, index) => {
      return (
        <PortfolioListBlock key={index} border>
          <ListLink data={data} tag="Case Study" alt={getAltImage('casestudy', data)}
          />
        </PortfolioListBlock>
      )
    })}
  </PortfolioListContainer>

const Link = styled(PreviewCompatibleLink)`
  text-decoration: none;
  color: inherit;
  position: relative;
  display: inline-block;
`;
const ArticlesBlock = styled.div`
  padding-bottom: 2em;
`;

// font-weight: 400;
// font-size: 0.9em;
// margin-bottom: 1em;
const ArticlesHeading = styled.div`
  font-size: 1em;
  font-weight: 500;
  line-height: 2em;
  margin-bottom: 1.5em;
  border-bottom: 1px solid #ff9da2;
`;

const ArticlesLink = styled(Link)`
  margin: 2em 0 0;
  text-decoration: underline;
  text-align: right;
  display: block;
`;

const getCaseStudiesConditional = (loc, caseStudiesExt) => {
  const [value, type] = (loc?.split('/') ?? []).filter(Boolean).reverse()
  // console.log('PortfolioList pre-condition', {loc, value, type})
  if (type === 'roles') {  // what-we-do/roles/technology-officer
    const rolesTag = value // technology-officer
    return caseStudiesExt.filter(caseStudy => caseStudy.rolesTags?.includes(rolesTag))
  } else if (type === 'sectors') {
      const sectorsTag = value
      return caseStudiesExt.filter(caseStudy => caseStudy.sectorsTags?.includes(sectorsTag))
  } else if (type) {
    const personTag = value
    return caseStudiesExt.filter(caseStudy => caseStudy.peopleTags?.includes(personTag))
  } else {
    console.log('UNKNOWN')
    return []
  }
}

export const PortfolioList = ({loc, title, children}) => {
  const {prime} = useContext(PrimeContext) ?? {}
  const caseStudies = getCaseStudiesConditional(loc, prime?.caseStudiesExt ?? [])

  // console.log('PortFolioList', {loc, caseStudies})

  return caseStudies.length
    ? <ArticlesBlock> 
        {children}
        {title && <ArticlesHeading>{title}</ArticlesHeading>}
        {/* {title && <StyledContent html={`<h1>${title}</h1>`} contentComponent={HTMLContent} />} */}
        <PortfolioListTemplate casestudies={caseStudies} />
        <ArticlesLink to="/portfolio/">View all Client Stories</ArticlesLink>
      </ArticlesBlock>
    : null
}