import React from 'react';
import PropTypes from 'prop-types';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';

const PreviewCompatibleImage = ({
  image: baseImage,
  alt = '',
  ...otherProps
}) => {
  if (!baseImage) {
    return null;
  }

  if (typeof baseImage === 'string') {
    const { style, className, imgStyle, ...rest } = otherProps;
    return (
      <div className={className} style={style}>
        <img src={baseImage} style={imgStyle} {...rest} alt={alt} />
      </div>
    );
  }

  // passing small to GatsbyImage throws a warning. However, this is required by other
  // styled components to add styles. So removing it here.
  delete otherProps.small;

  const pluginImage = getImage(baseImage);
  return <GatsbyImage image={pluginImage} {...otherProps} alt={alt} width={120} height={120 + 'px'} />;
};

PreviewCompatibleImage.propTypes = {
  image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]).isRequired,
  alt: PropTypes.string,
  style: PropTypes.object,
  imgStyle: PropTypes.object,
  className: PropTypes.string
};

export default PreviewCompatibleImage;
