import React from 'react';
import styled, { css } from 'styled-components';
import PropTypes from 'prop-types';
import { PreviewCompatibleLink } from 'src/components/PreviewCompatible';

export const activeTag = css`
  background-color: ${props => props.theme.primaryColor};
`;

const Tag = styled.span`
  text-transform: uppercase;
  font-weight: 500;
  border: 1px solid
    ${props => (props.secondary ? 'white' : props.theme.primaryColor)};
  border-radius: 3px;
  padding: 5px 10px;
  font-size: 0.8em;
  display: inline-block;
  margin: 5px;
  text-align: center;
  background-color: inherit;
  color: ${props => props.theme.primaryColor};
  ${props =>
    props.rounded &&
    css`
      ${props =>
        props.plain &&
        css`
          border-color: ${props => props.theme.secondaryColor};
        `}
      color: ${props =>
        props.secondary
          ? props.theme.primaryColor
          : props.theme.secondaryColor};
      border-radius: 20px;
      margin: 5px 2px;
      padding: 10px 10px;
    `}
`;

export default Tag;

export const InvertedTag = styled(Tag)`
  font-weight: 500;
`;

export const TagLink = styled(PreviewCompatibleLink)`
  text-decoration: none;
  color: black;
  margin: 5px;
`;

export const ActiveTagLink = props => (
  <TagLink {...props} activeClassName="active-tag">
    {props.children}
  </TagLink>
);

ActiveTagLink.propTypes = {
  children: PropTypes.node.isRequired
};
