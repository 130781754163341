import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import LinkedInIcon from 'src/components/icons/LinkedInIcon';
import FacebookIcon from 'src/components/icons/FacebookIcon';
import TwitterIcon from 'src/components/icons/TwitterIcon';
import WeblinkIcon from 'src/components/icons/WeblinkIcon';

const Icon = styled.div`
  margin-bottom: 8px;
`;

const IconWidget = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 40px;
  justify-content: center;
`;

const VerticalSocialMediaWidgetView = ({
  url,
  linkedInIcon,
  facebookIcon,
  twitterIcon,
  weblinkIcon
}) => (
  <IconWidget>
    {linkedInIcon && (
      <Icon>
        <LinkedInIcon url={url} icon={linkedInIcon} />
      </Icon>
    )}
    {facebookIcon && (
      <Icon>
        <FacebookIcon url={url} icon={facebookIcon} />
      </Icon>
    )}
    {twitterIcon && (
      <Icon>
        <TwitterIcon url={url} icon={twitterIcon} />
      </Icon>
    )}
    {weblinkIcon && (
      <Icon style={{ marginBottom: '0px' }}>
        <WeblinkIcon url={url} icon={weblinkIcon} place="right" />
      </Icon>
    )}
  </IconWidget>
);

VerticalSocialMediaWidgetView.propTypes = {
  url: PropTypes.string,
  linkedInIcon: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  facebookIcon: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  twitterIcon: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  weblinkIcon: PropTypes.oneOfType([PropTypes.object, PropTypes.string])
};

export default VerticalSocialMediaWidgetView;
