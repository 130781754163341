import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  justify-content: center;
`;

const Line = styled.div`
  width: ${props => (props.large ? '90%' : '150px')};
  border-bottom: 1px solid
    ${props =>
      !props.noColor ? props.theme.primaryColor : props.theme.tertiaryColor};
`;

Line.propTypes = {
  large: PropTypes.bool,
  color: PropTypes.bool
};

const Separator = ({ large, noColor }) => (
  <Container>
    <Line large={large} noColor={noColor} />
  </Container>
);

Separator.displayName = 'Separator';

Separator.defaultProps = {
  large: false,
  noColor: false
};

Separator.propTypes = {
  large: PropTypes.bool,
  noColor: PropTypes.bool
};

export default Separator;
