import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';

import { tree, toHTML, getProfile } from 'src/utils';
import { WhatWeDoPageTemplate } from 'src/components/pages/WhatWeDoPageTemplate';

const SectorPage = ({ data, location }) => {
  const { frontmatter, html, fields } = data.markdownRemark;
  const sectors = data.sectors.edges.map(item =>
    Object.assign({}, item.node.frontmatter, { slug: item.node.fields.slug })
  );

  const profiles = fields.peoples
    ? fields.peoples.map(item => getProfile(item))
    : [];

  const parentSector = sectors.find(item => item.name === frontmatter.category);

  const category = frontmatter.category
    ? {
        name: frontmatter.category,
        slug: parentSector ? parentSector.slug : null
      }
    : null;

  return (
    <WhatWeDoPageTemplate
      articlesWidget={frontmatter.articlesWidget}
      withSubNavigation
      topBreadcrumb={data.sectorsPage.fields.slug}
      name={frontmatter.name}
      seo={frontmatter.seo}
      html={html}
      howWeHelp={frontmatter.howWeHelp ? toHTML(frontmatter.howWeHelp) : null}
      category={category}
      items={tree(sectors)}
      breadcrumb={data.title.frontmatter.sector.breadcrumb}
      location={location}
      profiles={profiles}
    />
  );
};

SectorPage.propTypes = {
  data: PropTypes.object,
  location: PropTypes.object
};

export default SectorPage;

export const pageQuery = graphql`
  query SectorByName($id: String!) {
    markdownRemark(
      frontmatter: { templateKey: { eq: "sector" } }
      id: { eq: $id }
    ) {
      html
      frontmatter {
        articlesWidget
        name
        category
        howWeHelp
        seo {
          metaDescription
          focusKeyword
        }
      }
      fields {
        peoples {
          id
          fields {
            slug
            office
            sectors {
              fields {
                slug
              }
              frontmatter {
                name
              }
            }
            roles {
              fields {
                slug
              }
              frontmatter {
                name
              }
            }
          }
          frontmatter {
            name
            position
            office
            description
            contactDetails {
              email
              phone
            }
            picture {
              childImageSharp {
                gatsbyImageData(layout: FULL_WIDTH)
              }
            }
          }
        }
      }
    }
    sectors: allMarkdownRemark(
      filter: { frontmatter: { templateKey: { eq: "sector" } } }
      sort: { fields: [frontmatter___name], order: ASC }
    ) {
      edges {
        node {
          fields {
            slug
          }
          frontmatter {
            name
            category
          }
        }
      }
    }
    title: markdownRemark(
      frontmatter: { templateKey: { eq: "what-we-do-overview-page" } }
    ) {
      frontmatter {
        sector {
          breadcrumb
        }
      }
    }
    sectorsPage: markdownRemark(
      frontmatter: { templateKey: { eq: "what-we-do-sectors-page" } }
    ) {
      fields {
        slug
      }
    }
  }
`;
