import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';

import { tree } from 'src/utils';
import { WhatWeDoPageTemplate } from 'src/components/pages/WhatWeDoPageTemplate';

const SectorsPage = ({ data, location }) => {
  const { frontmatter, html } = data.markdownRemark;
  const sectors = data.sectors.edges.map(item =>
    Object.assign({}, item.node.frontmatter, { slug: item.node.fields.slug })
  );

  return (
    <WhatWeDoPageTemplate
      articlesWidget={frontmatter.articlesWidget}
      withSubNavigation
      isTopCategory
      name={frontmatter.title}
      seo={frontmatter.seo}
      html={html}
      category={null}
      items={tree(sectors)}
      breadcrumb={data.title.frontmatter.sector.breadcrumb}
      location={location}
    />
  );
};

SectorsPage.propTypes = {
  data: PropTypes.object,
  location: PropTypes.object
};

export default SectorsPage;

export const pageQuery = graphql`
  query SectorsPage {
    markdownRemark(
      frontmatter: { templateKey: { eq: "what-we-do-sectors-page" } }
    ) {
      html
      frontmatter {
        articlesWidget
        title
        seo {
          metaDescription
          focusKeyword
        }
      }
    }
    sectors: allMarkdownRemark(
      filter: { frontmatter: { templateKey: { eq: "sector" } } }
      sort: { fields: [frontmatter___name], order: ASC }
    ) {
      edges {
        node {
          fields {
            slug
          }
          frontmatter {
            name
            category
          }
        }
      }
    }
    title: markdownRemark(
      frontmatter: { templateKey: { eq: "what-we-do-overview-page" } }
    ) {
      frontmatter {
        sector {
          breadcrumb
        }
      }
    }
  }
`;
