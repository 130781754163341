import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import {
  Tabs as BaseTabs,
  TabList as BaseTabList,
  Tab as BaseTab,
  TabPanel
} from 'react-tabs';

import BodyContent from 'src/components/BodyContent';
import { HTMLContent } from 'src/components/Content';
import ArticlesWidget from 'src/components/widgets/ArticlesWidget/ArticlesWidget';
import Layout from 'src/components/Layout';
import SEO from 'src/components/SEO';
import {
  CenteredBlock,
  Header,
  H1,
  H2,
  P2 as BaseP2,
  Link,
  PartialBackground
} from 'src/components/Block';

const HeaderContent = styled(CenteredBlock)`
  padding: 0.5em 0.5em;
  @media screen and (min-width: ${({ theme }) =>
      theme.breakpoints.desktopMin}) {
    padding: 2em 10em;
  }
`;

const HeaderTitle = styled(H1)`
  font-weight: 300;
  padding: 0 0.5em;
  @media screen and (min-width: ${({ theme }) =>
      theme.breakpoints.desktopMin}) {
    padding: 0;
  }
`;

const P2 = styled(BaseP2)`
  padding: 0 0.5em;
  @media screen and (min-width: ${({ theme }) =>
      theme.breakpoints.desktopMin}) {
    padding: 0;
  }
`;

const ContentWrapper = styled.div`
  background-color: ${({ theme }) => theme.bgColor};
`;

const CardContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  align-content: center;
  margin-bottom: 2em;
  padding: 0 2em;

  @media screen and (min-width: ${({ theme }) =>
      theme.breakpoints.desktopMin}) {
    flex-direction: row;
    justify-content: center;
    padding: 0 6.25em;
  }
`;

const Card = styled.div`
  width: 100%;
  box-sizing: border-box;
  border: 1.5px solid ${({ theme }) => theme.primaryColor};
  color: ${({ textColor }) => textColor || 'black'};
  border-radius: 3px;
  padding: 1em 0.75em;
  font-size: 0.9em;
  display: flex;
  flex-direction: column;
  text-align: left;
  ${Link} {
    color: ${({ textColor }) => textColor || 'black'};
  }

  margin: 5px 0;
  @media screen and (min-width: ${({ theme }) =>
      theme.breakpoints.desktopMin}) {
    width: ${({ width }) => width || '15.5em'};
    margin: 5px;
  }
`;

const CardTextContainer = styled.div`
  min-height: ${({ minHeight }) => minHeight || '11.5em'};
`;

const CardLinksContainer = styled.div`
  border-top: 1px solid ${({ theme }) => theme.primaryColor};
  padding-top: 1em;
  ${Link} {
    color: ${({ theme }) => theme.primaryColor};
  }
  font-size: 0.75em;
  text-transform: uppercase;
`;

const CardLink = styled(Link)`
  border: 1px solid ${({ theme }) => theme.primaryColor};
  border-radius: 3px;
  margin-right: 9px;
  margin-bottom: 9px;
  padding: 5px;
  display: inline-block;
  font-weight: 500;

  &:last-child {
    margin: 0;
  }
`;

const Tabs = styled(BaseTabs)`
  color: ${({ theme }) => theme.secondaryColor};
`;

const TabList = styled(BaseTabList)`
  list-style-type: none;
  padding: 0;
  display: flex;
  flex-direction: column;
  text-align: center;
  margin-bottom: 0.25em;
  border-bottom: 1px solid ${({ theme }) => theme.primaryColor};

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.small}) {
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
  }
`;
// Required for accessibility on custom components
TabList.tabsRole = 'TabList';

const Tab = styled(BaseTab)`
  padding: 1em;
  cursor: pointer;

  &:last-child {
    margin-right: 0;
  }

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.small}) {
    margin-right: 5px;
  }

  &.is-selected {
    border-bottom: 3px solid ${({ theme }) => theme.accentColor};
  }
`;
// Required for accessibility on custom components
Tab.tabsRole = 'Tab';

const TabPanelContainer = styled(CenteredBlock)`
  padding: 2em 1em;
  min-height: 7em;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.small}) {
    padding: 2em 5em;
    max-width: 45em;
  }
`;

const SectorsContainer = styled(CenteredBlock)`
  margin-top: 3em;
  max-width: 78em;
`;

const RolesContainer = styled(SectorsContainer)`
  border-radius: 9px;
  background: ${({ theme }) => theme.bgDarkColor};
  color: white;
  padding: 2.8em 0;
  @media screen and (min-width: ${({ theme }) =>
      theme.breakpoints.desktopMin}) {
    padding: 3em 8em 4em;
  }
`;

export const PageContent = ({
  image,
  header,
  sector,
  role,
  services,
  sectors,
  roles
}) => {
  return (
    <React.Fragment>
      <Header image={image} height="200px" />
      <HeaderContent>
        <HeaderTitle>{header.heading}</HeaderTitle>
        <P2>{header.subheading}</P2>
      </HeaderContent>
      <ContentWrapper>
        <Tabs selectedTabClassName="is-selected">
          <TabList>
            {services.map((service, index) => {
              return <Tab key={index}>{service.name}</Tab>;
            })}
          </TabList>
          <TabPanelContainer>
            {services.map((service, index) => {
              return (
                <TabPanel key={index}>
                  <BodyContent
                    html={service.bodySummary}
                    contentComponent={HTMLContent}
                  />
                </TabPanel>
              );
            })}
          </TabPanelContainer>
        </Tabs>
        <SectorsContainer>
          <H2>{sector.title}</H2>
          <CardContainer>
            {sectors.map((category, index) => {
              const hasSubItems = category.items && category.items.length !== 0;
              return (
                <Card key={index}>
                  <CardTextContainer>
                    <Link to={category.slug}>
                      <p>
                        <strong>{category.name}</strong>
                      </p>
                      <p>{category.bodySummary}</p>
                    </Link>
                  </CardTextContainer>
                  {hasSubItems && (
                    <CardLinksContainer>
                      <CenteredBlock>
                        {category.items.map((subCategory, index) => {
                          return (
                            <CardLink key={index} to={subCategory.slug}>
                              {subCategory.name}
                            </CardLink>
                          );
                        })}
                      </CenteredBlock>
                    </CardLinksContainer>
                  )}
                </Card>
              );
            })}
          </CardContainer>
        </SectorsContainer>
        <PartialBackground paddingBottom="4.5em">
          <RolesContainer>
            <H2>{role.title}</H2>
            <CardContainer>
              {roles.map((category, index) => {
                const hasSubItems =
                  category.items && category.items.length !== 0;
                return (
                  <Card key={index} textColor="white" width="30.5em">
                    <CardTextContainer minHeight="7.2em">
                      <Link to={category.slug}>
                        <p>
                          <strong>{category.name}</strong>
                        </p>
                        <p>{category.bodySummary}</p>
                      </Link>
                    </CardTextContainer>
                    {hasSubItems && (
                      <CardLinksContainer>
                        <CenteredBlock>
                          {category.items.map((subCategory, index) => {
                            return (
                              <CardLink key={index} to={subCategory.slug}>
                                {subCategory.name}
                              </CardLink>
                            );
                          })}
                        </CenteredBlock>
                      </CardLinksContainer>
                    )}
                  </Card>
                );
              })}
            </CardContainer>
          </RolesContainer>
        </PartialBackground>
      </ContentWrapper>
    </React.Fragment>
  );
};

PageContent.propTypes = {
  image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  header: PropTypes.object,
  sector: PropTypes.shape({
    title: PropTypes.string.isRequired
  }),
  role: PropTypes.shape({
    title: PropTypes.string.isRequired
  }),
  sectors: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      category: PropTypes.string,
      bodySummary: PropTypes.string.isRequired,
      slug: PropTypes.string.isRequired,
      items: PropTypes.arrayOf(
        PropTypes.shape({
          name: PropTypes.string.isRequired,
          category: PropTypes.string,
          bodySummary: PropTypes.string,
          slug: PropTypes.string.isRequired
        })
      )
    })
  ),
  roles: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      category: PropTypes.string,
      bodySummary: PropTypes.string.isRequired,
      slug: PropTypes.string.isRequired,
      items: PropTypes.arrayOf(
        PropTypes.shape({
          name: PropTypes.string.isRequired,
          category: PropTypes.string,
          bodySummary: PropTypes.string,
          slug: PropTypes.string.isRequired
        })
      )
    })
  ),
  services: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      bodySummary: PropTypes.string.isRequired
    })
  )
};

export const ServicesPageTemplate = ({
  image,
  title,
  header,
  seo,
  sector,
  role,
  services,
  roles,
  sectors,
  location,
  articlesWidget
}) => (
  <Layout location={location}>
    <SEO
      title={title}
      description={seo.metaDescription}
      keywords={seo.focusKeyword}
    />
    <PageContent
      image={image}
      header={header}
      sector={sector}
      role={role}
      services={services}
      sectors={sectors}
      roles={roles}
    />
    {articlesWidget && <ArticlesWidget />}
  </Layout>
);

ServicesPageTemplate.defaultProps = {
  articlesWidget: false
};

ServicesPageTemplate.propTypes = {
  articlesWidget: PropTypes.bool,
  image: PropTypes.object,
  title: PropTypes.string.isRequired,
  header: PropTypes.object,
  seo: PropTypes.shape({
    metaDescription: PropTypes.string,
    focusKeyword: PropTypes.string
  }),
  sector: PropTypes.shape({
    title: PropTypes.string.isRequired
  }),
  role: PropTypes.shape({
    title: PropTypes.string.isRequired
  }),
  sectors: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      category: PropTypes.string,
      bodySummary: PropTypes.string.isRequired,
      slug: PropTypes.string.isRequired,
      items: PropTypes.arrayOf(
        PropTypes.shape({
          name: PropTypes.string.isRequired,
          category: PropTypes.string,
          bodySummary: PropTypes.string,
          slug: PropTypes.string.isRequired
        })
      )
    })
  ),
  roles: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      category: PropTypes.string,
      bodySummary: PropTypes.string.isRequired,
      slug: PropTypes.string.isRequired,
      items: PropTypes.arrayOf(
        PropTypes.shape({
          name: PropTypes.string.isRequired,
          category: PropTypes.string,
          bodySummary: PropTypes.string,
          slug: PropTypes.string.isRequired
        })
      )
    })
  ),
  services: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      bodySummary: PropTypes.string.isRequired
    })
  ),
  location: PropTypes.string.isRequired
};

export default ServicesPageTemplate;
