import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

import Layout from 'src/components/Layout';
import SEO from 'src/components/SEO';
import NextPrevious from 'src/components/NextPrevious';
import {
  BreadCrumb,
  BreadCrumbContainer,
  BreadLink
} from 'src/components/Breadcrumb';
import {
  Header,
  HeaderTitle,
  CenteredBlock,
  Category,
  StyledContent,
  TagBlock,
  TopRightButton,
  Link,
  PartialBackground
} from 'src/components/Block';
import { IconWithBorder, CrossTextIcon } from 'src/components/TextIcon';
import Tag, { TagLink } from 'src/components/Tag';
import {
  HorizontalSocialMediaWidget,
  VerticalSocialMediaWidget
} from 'src/components/widgets/SocialMediaWidget';
import ArticlesWidget from 'src/components/widgets/ArticlesWidget/ArticlesWidget';
import { HTMLContent } from 'src/components/Content';
import theme from 'src/styles/theme';
import { getMetaImage, getAltImage } from 'src/utils';

const Background = styled(PartialBackground)`
  padding-bottom: 2em;
  @media screen and (min-width: ${({ theme }) =>
      theme.breakpoints.desktopMin}) {
    margin-top: -10em;
  }
`;

export const Page = styled.div`
  margin: 0 auto;
  @media screen and (min-width: ${({ theme }) =>
      theme.breakpoints.desktopMin}) {
    width: 90%;
  }
  ${props =>
    props.background &&
    css`
      background-color: white;
      border-radius: 3px;
      transform: rotateZ(0);
    `}
`;

export const H2 = styled.h2`
  font-size: 1em;
  color: white;
`;

export const Content = styled.div`
  padding: ${props => (props.padding ? '5em' : '0')} 1em;
  @media screen and (min-width: ${({ theme }) =>
      theme.breakpoints.desktopMin}) {
    width: 70%;
    margin: 0 auto;
  }
`;

// TODO (mihail): if this is always the same with casestudy
// it's better to move it inside HorizontalSocialMediaWidget
export const HorizontalSocialMediaWidgetWrapper = styled.div`
  margin: 0 auto;
  @media screen and (min-width: ${({ theme }) =>
      theme.breakpoints.desktopMin}) {
    width: 80%;
  }
  padding-top: 30px;
  padding-bottom: 93px;
`;

export const VerticalSocialMediaWidgetWrapper = styled.div`
  float: left;
  margin-left: -59px;
`;

const processAuthors = authors => {
  if (!authors || !authors.length) {
    return {
      display: false,
      text: ''
    };
  }

  if (authors.length === 1) {
    if (!authors[0]) {
      return {
        display: false,
        text: ''
      };
    }

    return {
      display: true,
      text: authors[0]
    };
  }

  return {
    display: true,
    text: `${authors.slice(0, -1).join(', ')} & ${authors[authors.length - 1]}`
  };
};

export const ArticleHeader = ({ image, type, title, authors }) => {
  const processedAuthors = processAuthors(authors);
  return (
    <Header
      image={image}
      blend="multiply"
      color={theme.darken}
      justify="flex-start"
      overlap
      alt={getAltImage('article', { title })}
    >
      <Content>
        <TagBlock>
          <Category>{type}</Category>
        </TagBlock>
        <HeaderTitle>{title}</HeaderTitle>
        {processedAuthors.display && <H2>by {processedAuthors.text}</H2>}
      </Content>
    </Header>
  );
};

ArticleHeader.propTypes = {
  image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  type: PropTypes.string,
  title: PropTypes.string.isRequired,
  authors: PropTypes.arrayOf(PropTypes.string)
};

export const ArticlePageTemplate = ({
  image,
  type,
  title,
  seo,
  authors,
  html,
  prev,
  next,
  location,
  articlesWidget
}) => {
  const meta = getMetaImage(image);
  return (
    <Layout location={location.pathname}>
      <SEO
        title={title}
        description={seo.metaDescription}
        keywords={seo.focusKeyword}
        meta={meta}
      />
      <BreadCrumb>
        <Page>
          <BreadCrumbContainer>
            <BreadLink text="Home" link="/" />
            /
            <BreadLink text="News & Insights" link="/articles/" />
            /
            <BreadLink text={title} basicColor />
          </BreadCrumbContainer>
        </Page>
      </BreadCrumb>
      <ArticleHeader
        image={image}
        type={type}
        title={title}
        authors={authors}
      />
      <Background color={theme.breadcrumbBgColor} y="10em">
        <Page background>
          <div>
            <VerticalSocialMediaWidgetWrapper>
              <VerticalSocialMediaWidget url={location.href} />
            </VerticalSocialMediaWidgetWrapper>
            <TopRightButton>
              <Link to="/articles/">
                <IconWithBorder>
                  <CrossTextIcon />
                </IconWithBorder>
              </Link>
            </TopRightButton>
            <Content padding>
              <StyledContent html={html} contentComponent={HTMLContent} />
            </Content>
          </div>
          <HorizontalSocialMediaWidgetWrapper>
            <HorizontalSocialMediaWidget url={location.href} />
          </HorizontalSocialMediaWidgetWrapper>
        </Page>
        <Page>
          <NextPrevious prev={prev} next={next} name="Article" />
          <CenteredBlock>
            <TagLink to="/articles/">
              <Tag rounded plain>
                All articles
              </Tag>
            </TagLink>
          </CenteredBlock>
        </Page>
      </Background>
      {articlesWidget && <ArticlesWidget />}
    </Layout>
  );
};

ArticlePageTemplate.defaultProps = {
  articlesWidget: false
};

ArticlePageTemplate.propTypes = {
  articlesWidget: PropTypes.bool,
  image: PropTypes.object,
  type: PropTypes.string,
  title: PropTypes.string.isRequired,
  seo: PropTypes.shape({
    metaDescription: PropTypes.string,
    focusKeyword: PropTypes.string
  }),
  authors: PropTypes.arrayOf(PropTypes.string),
  html: PropTypes.string.isRequired,
  prev: PropTypes.object,
  next: PropTypes.object,
  location: PropTypes.object.isRequired
};

export default ArticlePageTemplate;
