import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const PlainCTA = styled.button`
  padding: ${props =>
    !props.link ? (props.large ? '1em 1.5em' : '0.7em 1em') : 'initial'};
  margin: 1em 0 0;
  font-size: 0.9em;
  border-radius: 3px;
  font-weight: ${props => (props.primary && !props.light ? '600' : 'initial')};
  cursor: pointer;
  text-transform: uppercase;
  border: ${props =>
    props.noBorder ? 'none' : `1px solid ${props.theme.primaryColor}`};
  background-color: ${props =>
    props.primary ? props.theme.primaryColor : 'transparent'};
  color: ${props => (props.noBorder ? props.theme.primaryColor : 'initial')};
  &:not(.no-hover):hover {
    color: ${props => (props.noBorder ? props.theme.primaryColor : 'white')};
    background-color: ${props =>
      props.noBorder ? '' : props.theme.primaryColor};
  }
`;

const InvertedCTA = styled(PlainCTA)`
  color: white;
  &.no-hover {
    border-color: ${props => (props.noBorder ? 'transparent' : 'white')};
  }
  &:not(.no-hover):hover {
    background-color: ${props =>
      props.noBorder ? 'transparent' : props.theme.primaryColor};
    border-color: ${props =>
      props.noBorder
        ? 'transparent'
        : props.primary
        ? 'white'
        : props.theme.primaryColor};
  }
`;

const CTA = ({ link, inverted, noBorder, primary, light, large, ...props }) => {
  const El = inverted ? InvertedCTA : PlainCTA;
  return (
    <El
      link={link}
      inverted={inverted}
      noBorder={noBorder}
      primary={primary}
      light={light}
      large={large}
      {...props}
    />
  );
};

CTA.propTypes = {
  link: PropTypes.bool,
  inverted: PropTypes.bool,
  large: PropTypes.bool,
  noBorder: PropTypes.bool,
  primary: PropTypes.bool,
  light: PropTypes.bool
};

export default CTA;
