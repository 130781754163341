import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

import {
  Header as BaseHeader,
  HeaderPreview as BaseHeaderPreview,
  H2 as BaseH2,
  H3 as BaseH3
} from 'src/components/Block';

const headerStyle = css`
  min-height: auto;
  text-align: center;
  margin: 0 auto;
  padding: 3.5em 0;
  @media screen and (min-width: ${({ theme }) =>
      theme.breakpoints.desktopMin}) {
    padding: 3.5em;
  }
  color: white;
`;

const HeaderPreview = styled(BaseHeaderPreview)`
  ${headerStyle};
`;

const Header = styled(BaseHeader)`
  ${headerStyle};
`;

const H3 = styled(BaseH3)`
  font-weight: 500;
  font-size: 1.5em;
  margin: 0.6em;
`;

const H2 = styled(BaseH2)`
  margin-top: 0;
`;

const Banner = ({ heading, subheading, image, preview }) => {
  const content = (
    <React.Fragment>
      {subheading && <H3>{subheading}</H3>}
      <H2>{heading}</H2>
    </React.Fragment>
  );
  if (preview) {
    return <HeaderPreview image={image}>{content}</HeaderPreview>;
  }
  return <Header image={image}>{content}</Header>;
};

Banner.propTypes = {
  preview: PropTypes.bool,
  heading: PropTypes.string.isRequired,
  subheading: PropTypes.string,
  image: PropTypes.oneOfType([PropTypes.object, PropTypes.string])
};

Banner.defaultProps = {
  preview: false
};

export default Banner;
