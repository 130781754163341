import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import ReactTransitionGroup from 'react-addons-css-transition-group';

export const List = styled.ul`
  list-style: none;
  margin: -10px;
  padding: 0;
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
`;

const FilterTransition = ({ children }) => (
  <ReactTransitionGroup
    component={List}
    transitionName="filter"
    transitionEnterTimeout={500}
    transitionLeaveTimeout={300}
  >
    {children}
  </ReactTransitionGroup>
);

FilterTransition.propTypes = {
  children: PropTypes.node
};

export default FilterTransition;
