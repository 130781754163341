import { createGlobalStyle, css } from 'styled-components';

import { activeTag } from 'src/components/Tag';

export const bodyFont = css`
  font-family: 'Helvetica Neue', Helvetica, Arial, 'Lucida Grande', sans-serif;
`;

export const titleFont = css`
  ${bodyFont}
`;

export const GlobalStyle = createGlobalStyle`
  body {
    padding: 0;
    margin: 0;
    font-weight: 300;
    ${bodyFont}
    background-color: ${props =>
      props.dark ? props.theme.breadcrumbBgColor : 'initial'};
  }
  .active-link {
    border-bottom-color: ${props => props.theme.primaryColor};
  }
  .active-nav div {
    border-bottom-color: ${props => props.theme.primaryColor};
  }
  .active-tag span {
    ${activeTag};
  }
  .tooltip {
    background-color: ${props => props.theme.secondaryColor} !important;
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.25);
    opacity: 1 !important;
    position: absolute;
  }
  .filter-enter {
    opacity: 0.01;
  }
  .filter-enter.filter-enter-active {
    opacity: 1;
    transition: opacity 500ms ease-in;
  }
  .filter-leave {
    opacity: 1;
  }
  .filter-leave.filter-leave-active {
    opacity: 0.01;
    transition: opacity 300ms ease-in;
  }
  .ReactModal__Body--open,
  .ReactModal__Html--open {
    overflow: hidden;
  }
`;
