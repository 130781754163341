import React from 'react';
import PropTypes from 'prop-types';
import { TwitterShareButton } from 'react-share';

import Icon from './Icon';

const TwitterIcon = ({ url, icon }) => (
  <TwitterShareButton url={url}>
    <Icon icon={icon} tooltip="Share on Twitter" />
  </TwitterShareButton>
);

TwitterIcon.propTypes = {
  url: PropTypes.string.isRequired,
  icon: PropTypes.oneOfType([PropTypes.object, PropTypes.string])
};

export default TwitterIcon;
