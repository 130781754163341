import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';

import { tree, toHTML, getProfile } from 'src/utils';
import { WhatWeDoPageTemplate } from 'src/components/pages/WhatWeDoPageTemplate';

const RolePage = ({ data, location }) => {
  const { frontmatter, html, fields } = data.markdownRemark;
  const roles = data.roles.edges.map(item =>
    Object.assign({}, item.node.frontmatter, { slug: item.node.fields.slug })
  );

  const profiles = fields.peoples
    ? fields.peoples.map(item => getProfile(item))
    : [];

  const parentRole = roles.find(item => item.name === frontmatter.category);

  const category = frontmatter.category
    ? {
        name: frontmatter.category,
        slug: parentRole ? parentRole.slug : null
      }
    : null;

  return (
    <WhatWeDoPageTemplate
      articlesWidget={frontmatter.articlesWidget}
      withSubNavigation
      topBreadcrumb={data.rolesPage.fields.slug}
      name={frontmatter.name}
      seo={frontmatter.seo}
      html={html}
      howWeHelp={frontmatter.howWeHelp ? toHTML(frontmatter.howWeHelp) : null}
      category={category}
      items={tree(roles)}
      breadcrumb={data.title.frontmatter.role.breadcrumb}
      location={location}
      profiles={profiles}
    />
  );
};

RolePage.propTypes = {
  data: PropTypes.object,
  location: PropTypes.object
};

export default RolePage;

export const pageQuery = graphql`
  query RoleByName($id: String!) {
    markdownRemark(
      frontmatter: { templateKey: { eq: "role" } }
      id: { eq: $id }
    ) {
      html
      frontmatter {
        articlesWidget
        name
        category
        howWeHelp
        seo {
          metaDescription
          focusKeyword
        }
      }
      fields {
        peoples {
          id
          fields {
            slug
            office
            sectors {
              fields {
                slug
              }
              frontmatter {
                name
              }
            }
            roles {
              fields {
                slug
              }
              frontmatter {
                name
              }
            }
          }
          frontmatter {
            name
            position
            office
            description
            contactDetails {
              email
              phone
            }
            picture {
              childImageSharp {
                gatsbyImageData(layout: FULL_WIDTH)
              }
            }
          }
        }
      }
    }
    roles: allMarkdownRemark(
      filter: { frontmatter: { templateKey: { eq: "role" } } }
      sort: { fields: [frontmatter___name], order: ASC }
    ) {
      edges {
        node {
          fields {
            slug
          }
          frontmatter {
            name
            category
          }
        }
      }
    }
    title: markdownRemark(
      frontmatter: { templateKey: { eq: "what-we-do-overview-page" } }
    ) {
      frontmatter {
        role {
          breadcrumb
        }
      }
    }
    rolesPage: markdownRemark(
      frontmatter: { templateKey: { eq: "what-we-do-roles-page" } }
    ) {
      fields {
        slug
      }
    }
  }
`;
