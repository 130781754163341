import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';

import ServicesWidgetView from './ServicesWidgetView';

const ServicesWidget = () => {
  const data = useStaticQuery(graphql`
    query ServicesWidgetQuery {
      widget: markdownRemark(
        frontmatter: { templateKey: { eq: "services-widget" } }
      ) {
        frontmatter {
          heading
        }
      }
      services: allMarkdownRemark(
        filter: { frontmatter: { templateKey: { eq: "service" } } }
        sort: { fields: [frontmatter___name], order: ASC }
      ) {
        edges {
          node {
            fields {
              slug
            }
            frontmatter {
              name
              bodySummary
              image {
                childImageSharp {
                  gatsbyImageData(layout: FIXED, height: 50)
                }
              }
            }
          }
        }
      }
    }
  `);

  const services = data.services.edges.map(item =>
    Object.assign({}, item.node.frontmatter, { slug: item.node.fields.slug })
  );

  return (
    <ServicesWidgetView
      services={services}
      heading={data.widget.frontmatter.heading}
    />
  );
};

export default ServicesWidget;
