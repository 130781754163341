/* eslint-disable no-console, react/prop-types */

import React, { useRef, useEffect } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import {parsePhoneNumber, ParseError} from 'libphonenumber-js'
import {useMediaQuery} from 'react-responsive'
import {PreviewCompatibleImage, PreviewCompatibleLink} from 'src/components/PreviewCompatible'
import { InvertedTag } from 'src/components/Tag';
import { H4 as BaseH4, P as BaseP, ComponentId } from 'src/components/Block';
import { ListContent } from 'src/components/PageBlock';
import { CrossTextIcon } from 'src/components/TextIcon';
import { ProfileCard, ProfileCardLink } from 'src/components/ProfileCard';
import { getAltImage, sortByDate } from 'src/utils';
import { ContactButton } from 'src/components/ContactButton';
import {PortfolioList} from 'src/components/pages/PortfolioList'

const ContactDetails = styled.div`
  color: ${props => props.theme.textColor};
  opacity: 0.6;
  font-weight: normal;
`;

const NoStyleLink = styled(PreviewCompatibleLink)`
  text-decoration: none;
`;

const Link = styled(PreviewCompatibleLink)`
  color: black;
  display: block;
  margin: 15px 0;
`;

const P = styled(BaseP)`
  line-height: 1.5em;
  margin: 0;
`;

const H1 = styled.h1`
  font-weight: bold;
  margin-bottom: 10px;
  font-size: 0.9em;
  @media not screen and (min-width: ${({ theme }) =>
    theme.breakpoints.desktopMin}) {
    flex-basis: 100%;
  }
`;

const H4 = styled(BaseH4)`
  font-weight: normal;
  margin: 0 0 2px;
  font-size: 0.9em;
  @media not screen and (min-width: ${({ theme }) =>
    theme.breakpoints.desktopMin}) {
    flex-basis: 100%;
  }
`;

const ProfileContent = styled.div`
  height: calc(100% - 3em);
  display: flex;
`;

const Profile = styled.div`
  overflow: auto;
  padding: 0.8em;
  flex-grow: 1;
`;

const ProfileGrid = styled.div`
  display: flex;
  @media not screen and (min-width: ${({ theme }) =>
    theme.breakpoints.desktopMin}) {
    flex-wrap: wrap;
    position: relative;
  }
`;

const ProfileDetails = styled.div`
  @media screen and (min-width: ${({ theme }) =>
    theme.breakpoints.desktopMin}) {
    padding-left: 0.8em;
  }
  flex-basis: 70%;
  flex-grow: 1;
`;

const PictureBlock = styled.div`
  flex-basis: 100%;
  @media screen and (min-width: ${({ theme }) =>
    theme.breakpoints.desktopMin}) {
    flex-basis: 30em;
  }
  @media not screen and (min-width: ${({ theme }) =>
    theme.breakpoints.desktopMin}) {
    margin: 1em 0;
  }
`;

const Picture = styled(PreviewCompatibleImage)`
  width: 100%;
`;

const CloseButton = styled.div`
  color: ${props => props.theme.primaryColor};
  text-align: center;
  cursor: pointer;
  text-decoration: none;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 2.5em;
  height: 2.5em;
`;

const SectorsHeading = styled.div`
  font-weight: 500;
  font-size: 0.7em;
  margin-bottom: 1em;
  text-transform: uppercase;
`;

const ArticlesHeadingNoUnderline = styled.div`
  font-size: 1em;
  font-weight: 500;
  line-height: 2em;
  margin-bottom: 1.5em;
`;

const ArticlesHeading = styled.div`
  font-size: 1em;
  font-weight: 500;
  line-height: 2em;
  margin-bottom: .5em;
  border-bottom: 1px solid #ff9da2;
`;


const ArticlesLink = styled(Link)`
  margin: 2em 0 0em;
  text-align: right;
`;

const ProfileHeader = styled.div`
  background-color: ${props => props.theme.secondaryColor};
  color: white;
  display: flex;
  border-bottom: 1px solid ${props => props.theme.primaryColor};
  height: 3em;
`;

const ProfileTitle = styled.div`
  flex-grow: 1;
  display: flex;
  margin: 1em 0.8em;
  @media not screen and (min-width: ${({ theme }) =>
    theme.breakpoints.desktopMin}) {
    flex-wrap: wrap;
  }
`;

const ProfileRelated = styled.div`
  margin-top: 1em;
`;

const Tags = styled.div`
  margin: 0 -5px 2em;
`;

const Contact = styled.div`
  margin-bottom: 2em;
`;

const List = styled.div`
  flex-basis: 35%;
  flex-shrink: 0;
  overflow-y: scroll;
  background-color: ${props => props.theme.bgColor};
  @media not screen and (min-width: ${({ theme }) =>
    theme.breakpoints.desktopMin}) {
    display: none;
  }
`;

const Item = styled.div`
  &:not(:first-child) {
    border-top: 1px solid ${props => props.theme.borderColor};
  }
`;

const ArticlesBlock = styled.div`
  padding-bottom: 1em;
`;

const ArticlesList = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: 0 -5px;
  align-items: stretch;
`;

const ArticleContent = styled.div`
  margin: 5px;
  flex-shrink: 0;
  flex-basis: 45%;
  @media not screen and (min-width: ${({ theme }) =>
    theme.breakpoints.desktopMin}) {
    flex-basis: 100%;
  }
`;

const ArticleBlockLink = styled(Link)`
  text-decoration: none;
  border: 1px solid ${props => props.theme.borderColor};
  border-radius: 3px;
  height: 100%;
`;

const LatestArticleLink = ({ articles }) => {
  if (!articles || articles.length === 0) {
    return null;
  }

  sortByDate(articles);

  return (
    <ArticlesBlock> <ComponentId id='LatestArticleLink_ArticlesBlock' />
      <ArticlesHeading>Latest articles</ArticlesHeading>
      <ArticlesList  style={{marginTop: '-1em'}}>
        {articles.slice(0, 4).map((item, index) => {
          return (
            <ArticleContent key={index}>
              <ArticleBlockLink to={item.link}>
                <ListContent
                  data={item.frontmatter}
                  tag={item.frontmatter.type}
                  small
                />
              </ArticleBlockLink>
            </ArticleContent>
          );
        })}
      </ArticlesList>
      <ArticlesLink to="/articles/">View all Articles</ArticlesLink>
    </ArticlesBlock>
  )
}

LatestArticleLink.propTypes = {
  articles: PropTypes.arrayOf(
    PropTypes.shape({
      frontmatter: PropTypes.object,
      fields: PropTypes.object
    })
  )
};

const formatPhone = phone => {
  try {
    const phoneNumber = parsePhoneNumber(phone);
    return phoneNumber.formatInternational();
  } catch (error) {
    if (error instanceof ParseError) {
      // Not a phone number, non-existent country, etc.
      // console.log(error.message);
    } else {
      throw error;
    }
  }
  return null;
};

export const ProfileModal = ({data, profiles, to, onClose, onSelect, link}) => {
  const isDesktopOrLaptop = useMediaQuery({ minWidth: 768 });

  const modalTitle = isDesktopOrLaptop ? (
    <span>Works with:</span>
  ) : (
    <span>{data.name}</span>
  );

  const closeButton = (
    <CloseButton onClick={onClose}>
      <CrossTextIcon />
    </CloseButton>
  );
  let closeContainer;
  if (to) {
    closeContainer = <NoStyleLink to={to}>{closeButton}</NoStyleLink>;
  } else {
    closeContainer = <React.Fragment>{closeButton}</React.Fragment>;
  }

  const refs = profiles.reduce((acc, item) => {
    acc[item.id] = useRef(null);
    return acc;
  }, {});

  useEffect(() => {
    const profileRef = refs[data.id];
    if (profileRef && profileRef.current) {
      profileRef.current.scrollIntoView();
    }
  }, []);

  return (
    <React.Fragment>
      <ProfileHeader>
        <ProfileTitle>{modalTitle}</ProfileTitle>
        {onClose && closeContainer}
      </ProfileHeader>
      <ProfileContent>
        <List>
          {profiles.map(item => {
            let card;
            if (link) {
              card = (
                <ProfileCardLink
                  data={item}
                  small
                  secondary
                  selected={item.id === data.id}
                  ref={refs[item.id]}
                  onClick={() => onSelect(item)}
                />
              );
            } else {
              card = (
                <ProfileCard
                  {...item}
                  small
                  secondary
                  selected={item.id === data.id}
                  ref={refs[item.id]}
                  onClick={() => onSelect(item)}
                />
              );
            }
            return <Item key={item.id}>{card}</Item>;
          })}
        </List>
        <ProfileComponent {...{data, to, onClose}} />
      </ProfileContent>
    </React.Fragment>
  );
};

ProfileModal.defaultProps = {
  link: false
};

ProfileModal.propTypes = {
  onClose: PropTypes.func,
  onSelect: PropTypes.func,
  to: PropTypes.string,
  data: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string.isRequired,
    position: PropTypes.string.isRequired,
    office: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    contactDetails: PropTypes.shape({
      email: PropTypes.string,
      phone: PropTypes.string
    }),
    picture: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    articles: PropTypes.arrayOf(
      PropTypes.shape({
        frontmatter: PropTypes.object,
        fields: PropTypes.object
      })
    ),
    roles: PropTypes.arrayOf(PropTypes.object)
  }),
  profiles: PropTypes.array,
  link: PropTypes.bool
};

export const ProfileComponent = ({ data }) => {
  return (
    <Profile>
      <ProfileGrid>
        <PictureBlock>
          <Picture
            image={data.picture ? data.picture : '/img/people.png'}
            imgStyle={{ width: '100%' }}
            alt={getAltImage('profile', data)}
          />
          <ProfileRelated>
            <Contact>
              <H1>{data.name}</H1>
              <H4>
                {data.position}, {data.office}
              </H4>
              <ContactDetails>
                {data.contactDetails && data.contactDetails.phone && (
                  <div>{formatPhone(data.contactDetails.phone)}</div>
                )}
                {data.contactDetails && data.contactDetails.email && (
                  <div style={{marginTop: '.5em'}}>
                    <ContactButton primary>
                      <a href={'mailto:' + data.contactDetails.email.toLowerCase()}>
                        {data.contactDetails.email.toLowerCase()}
                      </a>
                    </ContactButton>
                  </div>
                )}
              </ContactDetails>
            </Contact>
            {data.roles?.length
              ? <>
                  <ArticlesHeading>Works across</ArticlesHeading>
                  <Tags>
                    {data.roles.map((item, index) => 
                      <PreviewCompatibleLink to={item.slug} key={index}>
                        <InvertedTag>{item.name}</InvertedTag>
                      </PreviewCompatibleLink>
                    )}
                  </Tags>
                </>
              : null}
          </ProfileRelated>
        </PictureBlock>
        <ProfileDetails> <ComponentId id='ProfileComponent_ProfileDetails' />
          <P>{data.description}</P>
        </ProfileDetails>
      </ProfileGrid>
      <LatestArticleLink articles={data.articles} />
      <PortfolioList loc={data?.slug} title='Client Stories' />
    </Profile>
  )
}

ProfileComponent.propTypes = {
  data: PropTypes.shape({
    name: PropTypes.string.isRequired,
    position: PropTypes.string.isRequired,
    office: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    contactDetails: PropTypes.shape({
      email: PropTypes.string,
      phone: PropTypes.string
    }),
    picture: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    articles: PropTypes.arrayOf(
      PropTypes.shape({
        frontmatter: PropTypes.object,
        fields: PropTypes.object
      })
    ),
    roles: PropTypes.arrayOf(PropTypes.object)
  })
};
