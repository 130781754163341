import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';

import PortfolioPageTemplate from 'src/components/pages/PortfolioPageTemplate';

const PortfolioPage = ({ data, location }) => {
  const { frontmatter } = data.markdownRemark;
  const casestudies = data.casestudies.edges.map(item =>
    Object.assign({}, item.node.frontmatter, { slug: item.node.fields.slug })
  );

  return (
    <PortfolioPageTemplate
      articlesWidget={frontmatter.articlesWidget}
      heading={frontmatter.heading}
      subheading={frontmatter.subheading}
      seo={frontmatter.seo}
      casestudies={casestudies}
      location={location.pathname}
    />
  );
};

PortfolioPage.propTypes = {
  data: PropTypes.object,
  location: PropTypes.object
};

export default PortfolioPage;

export const pageQuery = graphql`
  query PortfolioPageTemplate {
    markdownRemark(frontmatter: { templateKey: { eq: "portfolio-page" } }) {
      frontmatter {
        articlesWidget
        heading
        seo {
          metaDescription
          focusKeyword
        }
        subheading
      }
    }
    casestudies: allMarkdownRemark(
      filter: {
        frontmatter: { templateKey: { eq: "casestudy" }, publish: { eq: true } }
      }
    ) {
      edges {
        node {
          fields {
            slug
          }
          frontmatter {
            title
            location
            image {
              childImageSharp {
                gatsbyImageData(layout: FULL_WIDTH)
              }
            }
          }
        }
      }
    }
  }
`;
