import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';

import TeamWidgetView from './TeamWidgetView';

const TeamWidget = () => {
  const data = useStaticQuery(graphql`
    query TeamWidgetQuery {
      widget: markdownRemark(
        frontmatter: { templateKey: { eq: "team-widget" } }
      ) {
        frontmatter {
          title
          text
          image {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH)
            }
          }
        }
      }
    }
  `);

  return (
    <TeamWidgetView
      title={data.widget.frontmatter.title}
      text={data.widget.frontmatter.text}
      image={data.widget.frontmatter.image}
    />
  );
};

export default TeamWidget;
