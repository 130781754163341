/* eslint-disable no-console */import React from 'react';

import PropTypes from 'prop-types';
import { graphql } from 'gatsby';

import TeamPageTemplate from 'src/components/pages/TeamPageTemplate';
import { getProfile, usePathToProfile } from 'src/utils';

const TeamPage = ({ data, location }) => {
  const { frontmatter } = data.markdownRemark;
  const { index, store } = data.localSearchProfiles;

  const offices = data.offices.group.map(group =>
    Object.assign({
      region: group.fieldValue,
      cities: group.edges.map(item => ({
        ...item.node.frontmatter,
        ...item.node.fields
      }))
    })
  );
  const allCities = offices.map(office => office.cities).flat();
  const roles = data.roles.edges.map(item => item.node.frontmatter);
  const sectors = data.sectors.edges.map(item => item.node.frontmatter);
  const profiles = data.profiles.edges.map(item => getProfile(item.node));

  // console.log('teampage', {data, location, offices, allCities, roles, sectors, profiles})

  const { initialProfile, currentPath } = usePathToProfile(
    location,
    data.profile,
    profiles
  );

  const currentCity = allCities.find(
    item => `/team/locations/${item.namekey}/` === currentPath
  );

  // console.log({initialProfile, currentPath, currentCity})
  return (
    <TeamPageTemplate
      articlesWidget={frontmatter.articlesWidget}
      initialProfile={initialProfile}
      initialCity={
        currentCity && currentCity.namekey ? currentCity.namekey : null
      }
      profilesIndex={index}
      profilesStore={store}
      heading={frontmatter.heading}
      subheading={frontmatter.subheading}
      image={frontmatter.image}
      seo={frontmatter.seo}
      profiles={profiles}
      location={location.pathname}
      offices={offices}
      roles={roles}
      sectors={sectors}
    />
  );
};

TeamPage.propTypes = {
  data: PropTypes.object,
  location: PropTypes.object
};

export default TeamPage;

export const pageQuery = graphql`
  query TeamPageTemplate($slug: String) {
    markdownRemark(frontmatter: { templateKey: { eq: "about-us-team-page" } }) {
      ...TeamPageContent
    }
    profile: profileType(
      frontmatter: { templateKey: { eq: "profile" } }
      fields: { slug: { eq: $slug } }
    ) {
      ...ProfileDetails
    }
    profiles: allProfileType(
      filter: {
        frontmatter: { templateKey: { eq: "profile" } }
        fields: { profiletype: { eq: "team" } }
      }
      sort: { fields: [frontmatter___name], order: ASC }
    ) {
      edges {
        node {
          ...ProfileDetails
        }
      }
    }
    offices: allMarkdownRemark(
      filter: { frontmatter: { templateKey: { eq: "office" } } }
      sort: { fields: [frontmatter___city], order: ASC }
    ) {
      ...OfficesGroups
    }
    roles: allMarkdownRemark(
      filter: { frontmatter: { templateKey: { eq: "role" } } }
      sort: { fields: [frontmatter___name], order: ASC }
    ) {
      edges {
        node {
          ...WhatWeDoCategories
        }
      }
    }
    sectors: allMarkdownRemark(
      filter: { frontmatter: { templateKey: { eq: "sector" } } }
      sort: { fields: [frontmatter___name], order: ASC }
    ) {
      edges {
        node {
          ...WhatWeDoCategories
        }
      }
    }
    localSearchProfiles {
      index
      store
    }
  }
`;
