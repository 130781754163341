import remark from 'remark';
import remarkHTML from 'remark-html';
import { getImage } from 'gatsby-plugin-image';

export const toHTML = value =>
  remark()
    .use(remarkHTML)
    .processSync(value)
    .toString();

export const groupBy = (items, key) => {
  // Combine all items by `key`
  return items.reduce(function(allItems, currentItem) {
    (allItems[currentItem[key]] = allItems[currentItem[key]] || []).push(
      currentItem
    );
    return allItems;
  }, {});
};

export const regroup = items => {
  return items[''].map(item => {
    return {
      ...item,
      items: items[item.name]
    };
  });
};

export const tree = items => regroup(groupBy(items, 'category'));

export const getProfile = profile => {
  return Object.assign(
    {},
    { id: profile.id },
    {
      articles: profile.fields.articles
        ? profile.fields.articles.map(article => ({
            ...article,
            link: article.frontmatter.link
              ? article.frontmatter.link
              : article.fields.slug
          }))
        : []
    },
    { ...profile.frontmatter },
    { slug: profile.fields.slug },
    { office: profile.fields.office },
    { officeSlug: profile.frontmatter.office },
    {
      sectors: profile.fields.sectors
        ? profile.fields.sectors.map(item =>
            Object.assign({}, item.frontmatter, { slug: item.fields.slug })
          )
        : []
    },
    {
      roles: profile.fields.roles
        ? profile.fields.roles.map(item =>
            Object.assign({}, item.frontmatter, { slug: item.fields.slug })
          )
        : []
    }
  );
};

const getMetaImageProps = imgSrc => {
  return imgSrc
    ? [
        { property: 'og:image', content: imgSrc },
        { property: 'twitter:image', content: imgSrc }
      ]
    : [];
};

export const getMetaImage = image => {
  const { images } = getImage(image) || {};
  const imgSrc =
    images && images.fallback && images.fallback.src
      ? images.fallback.src
      : null;
  return getMetaImageProps(imgSrc);
};

export const getAltImage = (type, data) => {
  if (type === 'casestudy') {
    const titleSections = data.title.split(', ');
    const position = titleSections[0];
    const institution = titleSections[1];
    return `Marlin Hawk places ${position} at ${institution} case study`;
  }

  if (type === 'article') {
    const titleHasMarlinHawk = data.title
      ? data.title.includes('Marlin Hawk')
      : false;
    const marlinHawkText = 'a Marlin Hawk ';
    return `${data.title} ${titleHasMarlinHawk ? '' : marlinHawkText}article`;
  }

  if (type === 'service') {
    return `Marlin Hawk's ${data.name} service`;
  }

  if (type === 'profile') {
    return `Marlin Hawk ${data.office}'s ${data.position} ${data.name}`;
  }

  if (type === 'office') {
    return `Marlin Hawk's ${data.city} office`;
  }

  return '';
};

export const sortByDate = items => {
  items.sort((a, b) => {
    return new Date(b.date) - new Date(a.date);
  });
  return items;
};

export const getPagePath = path => {
  return path[path.length - 1] === '/' ? path : path + '/';
};

export const usePathToProfile = (location, stateProfile, profiles) => {
  const currentPath = getPagePath(location.pathname);

  const profile =
    stateProfile && currentPath === stateProfile.fields.slug
      ? getProfile(stateProfile)
      : profiles.find(profile => profile.slug === currentPath);

  const initialProfile = profile
    ? profile
    : location.state
    ? location.state.profile
    : null;

  return { currentPath, profile, initialProfile };
};

export const getEnvironment = () => {
  if (typeof window === 'undefined') {
    return;
  }

  const { hostname } = window.location;

  if (hostname.includes('www.marlinhawk.com')) {
    return 'production';
  }

  if (hostname.includes('localhost')) {
    return 'local';
  }

  return 'development';
};
