import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { PreviewCompatibleLink } from 'src/components/PreviewCompatible';

const color = css`
  color: ${props => props.theme.breadcrumbTextColor};
  font-weight: 500;
`;

const Link = styled(PreviewCompatibleLink)`
  ${color};
  text-decoration: none;
`;

const Text = styled.div`
  ${color};
  text-decoration: underline;
`;

export const BreadCrumb = styled.div`
  @media not screen and (min-width: ${({ theme }) =>
      theme.breakpoints.desktopMin}) {
    padding: 0 1em;
  }
  background-color: ${props => props.theme.breadcrumbBgColor};
  color: ${props => props.theme.breadcrumbTextColor};
`;

export const ExtraContainer = styled.div`
  width: 90%;
  margin: 0 auto;
`;

export const BreadCrumbContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  ${props =>
    props.margin &&
    css`
      margin: 0 auto;
      @media screen and (min-width: ${({ theme }) =>
          theme.breakpoints.desktopMin}) {
        max-width: 80%;
      }
    `}
`;

const BreadPage = styled.div`
  margin: 10px 5px;
  font-size: 0.65em;
  text-transform: uppercase;
  &:first-of-type {
    margin-left: 0;
  }
`;

export const BreadLink = props => (
  <BreadPage>
    {props.link ? (
      <Link basicColor={props.basicColor} to={props.link}>
        {props.text}
      </Link>
    ) : (
      <Text basicColor={props.basicColor}>{props.text}</Text>
    )}
  </BreadPage>
);

BreadLink.defaultProps = {
  basicColor: false
};

BreadLink.propTypes = {
  link: PropTypes.string,
  basicColor: PropTypes.bool,
  text: PropTypes.string.isRequired
};
