/* eslint-disable no-console, react/prop-types */

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled, { ThemeProvider } from 'styled-components';
import { useStaticQuery, graphql } from 'gatsby';

import { GlobalStyle } from 'src/styles/globalStyle';
import theme from 'src/styles/theme';
import { PreviewCompatibleLink } from 'src/components/PreviewCompatible';
import Header from 'src/components/Header';
import HeadContent from 'src/components/HeadContent';
import { PrimeProvider } from 'src/hooks/PrimeContext';

const Footer = styled.footer`
  background-color: ${props => props.theme.bgDarkColor};
  color: white;
  text-align: center;
  font-size: 0.8em;
  padding: 1em;
  @media screen and (min-width: ${({ theme }) =>
      theme.breakpoints.desktopMin}) {
    padding: 5em;
  }
`;

const FooterLogo = styled.div`
  margin: 2em 0;
  @media screen and (min-width: ${({ theme }) =>
      theme.breakpoints.desktopMin}) {
    margin: 2em;
  }
`;

const FooterLink = styled(PreviewCompatibleLink)`
  color: white;
  font-weight: 500;
  font-size: 0.8em;
  margin: 1em;
  display: inline-block;
  text-decoration: none;
  text-transform: uppercase;
`;

const FooterCopy = styled.div`
  margin: 2em;
  font-size: 0.9em;
`;

const FooterNav = styled.div``;

const Layout = ({ children, location, dark, hideTop }) => {
  const data = useStaticQuery(graphql`
    query NavbarQuery {
      services: allMarkdownRemark(
        filter: { frontmatter: { templateKey: { eq: "service" } } }
        sort: { fields: [frontmatter___name], order: ASC }
      ) {
        edges {
          node {
            fields {
              slug
            }
            frontmatter {
              name
            }
          }
        }
      }
      sectors: allMarkdownRemark(
        filter: { frontmatter: { templateKey: { eq: "sector" }, category: { eq: "" } } }
        sort: { fields: [frontmatter___name], order: ASC }
      ) {
        edges {
          node {
            fields {
              slug
            }
            frontmatter {
              name
            }
          }
        }
      }
      roles: allMarkdownRemark(
        filter: {
          frontmatter: { templateKey: { eq: "role" }, category: { eq: "" } }
        }
        sort: { fields: [frontmatter___name], order: ASC }
      ) {
        edges {
          node {
            fields {
              slug
            }
            frontmatter {
              name
            }
          }
        }
      }
      articles: allMarkdownRemark(
        filter: {
          frontmatter: { templateKey: { eq: "article" }, publish: { eq: true } }
        }
      ) {
        totalCount
      }
      casestudies: allMarkdownRemark(
        filter: {
          frontmatter: {
            templateKey: { eq: "casestudy" }
            publish: { eq: true }
          }
        }
      ) {
        totalCount
        edges {
          node {
            fields {
              slug
            }
            frontmatter {
              title
              location
              peopleTags
              rolesTags
              sectorsTags
              image {
                childImageSharp {
                  gatsbyImageData(layout: FULL_WIDTH)
                }
              }
            }
          }
        }
      }
      profiles: allProfileType(
        filter: {
          frontmatter: { templateKey: { eq: "profile" } }
          fields: { profiletype: { eq: "team" } }
        }
      ) {
        edges {
          node {
            ...ProfileDetails
          }
        }
      }
      vacancies: allMarkdownRemark(
        filter: {
          frontmatter: { templateKey: { eq: "vacancy" }, publish: { eq: true } }
        }
      ) {
        totalCount
      }
      terms: allMarkdownRemark(
        filter: {
          frontmatter: {
            templateKey: { eq: "terms-page" }
            publish: { eq: true }
          }
        }
      ) {
        totalCount
      }
    }
  `);

  // console.log('layout', {data})

  const displayServices = data.services.edges.map(service => {
    return {
      name: service.node.frontmatter.name,
      path: service.node.fields.slug
    };
  });

  const displaySectors = data.sectors.edges.map(sector => {
    return {
      name: sector.node.frontmatter.name,
      path: sector.node.fields.slug
    };
  });

  const displayRoles = data.roles.edges.map(role => {
    return {
      name: role.node.frontmatter.name,
      path: role.node.fields.slug
    };
  });

  // console.log('layout', {displayServices, displaySectors, displayRoles})

  const today = new Date();

  const [showDropdown, setShowDropdown] = useState(null);

  const resetDropdowns = () => {
    setShowDropdown(null);
  };

  return (
    <>
      <HeadContent />
      <PrimeProvider prime={data}>
        <ThemeProvider theme={theme}>
          <div onClick={resetDropdowns}>
            <GlobalStyle dark={dark} />
            {!hideTop && (
              <Header
                location={location}
                services={displayServices}
                roles={displayRoles}
                sectors={displaySectors}
                showDropdown={showDropdown}
                setShowDropdown={setShowDropdown}
                showArticles={data.articles.totalCount !== 0}
                showCaseStudies={data.casestudies.totalCount !== 0}
              />
            )}
            <div>
              <main>{children}</main>
              <Footer>
                <FooterNav>
                  <span>
                    <FooterLink to="/about-us/">About</FooterLink>
                  </span>
                  <span>
                    <FooterLink to="/what-we-do/services/">Services</FooterLink>
                  </span>
                  <span>
                    <FooterLink to="/what-we-do/roles/">Functions</FooterLink>
                  </span>
                  <span>
                    <FooterLink to="/what-we-do/sectors/">
                      Industries
                    </FooterLink>
                  </span>
                  <span>
                    <FooterLink to="/portfolio/">Portfolio</FooterLink>
                  </span>
                  <span>
                    <FooterLink to="/team/">Our Team</FooterLink>
                  </span>
                  <span>
                    <FooterLink to="/articles/">News & Insights</FooterLink>
                  </span>
                  <span>
                    <FooterLink to="/contact-us/">Contact Us</FooterLink>
                  </span>
                </FooterNav>
                <FooterLogo>
                  <img
                    src="/img/logo-v-dark.png"
                    style={{ width: '100%', maxWidth: '200px' }}
                  />
                </FooterLogo>
                <FooterCopy>
                  &#9400; {today.getFullYear()} Copyright Marlin Hawk
                </FooterCopy>
                <FooterNav>
                  {data.terms.totalCount !== 0 && (
                    <span>
                      <FooterLink to="/terms/">Terms & Conditions</FooterLink>
                    </span>
                  )}
                  <span>
                    <FooterLink to="/privacy/">Privacy Policy</FooterLink>
                  </span>
                </FooterNav>
              </Footer>
            </div>
          </div>
        </ThemeProvider>
      </PrimeProvider>
    </>
  );
};

Layout.defaultProps = {
  hideTop: false,
  dark: false
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  location: PropTypes.string.isRequired,
  hideTop: PropTypes.bool,
  dark: PropTypes.bool
};

export default Layout;
