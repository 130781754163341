import React from 'react';
import PropTypes from 'prop-types';
import { useStaticQuery, graphql } from 'gatsby';

import NumbersWidgetView from 'src/components/widgets/NumbersWidget/NumbersWidgetView';

const NumbersWidget = ({ overlap }) => {
  const data = useStaticQuery(graphql`
    query NumbersWidgetQuery {
      widget: markdownRemark(
        frontmatter: { templateKey: { eq: "numbers-widget" } }
      ) {
        frontmatter {
          title
          details {
            number
            text
          }
        }
      }
    }
  `);

  return (
    <NumbersWidgetView
      overlap={overlap}
      numbers={data.widget.frontmatter.details}
      title={data.widget.frontmatter.title}
    />
  );
};

NumbersWidget.propTypes = {
  overlap: PropTypes.bool
};

export default NumbersWidget;
