import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';

import { WhatWeDoPageTemplate } from 'src/components/pages/WhatWeDoPageTemplate';

const ServicesPage = ({ data, location }) => {
  const { frontmatter, html } = data.markdownRemark;
  const services = data.services.edges.map(item =>
    Object.assign({}, item.node.frontmatter, { slug: item.node.fields.slug })
  );

  return (
    <WhatWeDoPageTemplate
      articlesWidget={frontmatter.articlesWidget}
      isTopCategory
      name={frontmatter.title}
      seo={frontmatter.seo}
      html={html}
      category={null}
      items={services}
      breadcrumb={data.title.frontmatter.service.breadcrumb}
      location={location}
    />
  );
};

ServicesPage.propTypes = {
  data: PropTypes.object,
  location: PropTypes.object
};

export default ServicesPage;

export const pageQuery = graphql`
  query ServicesPage {
    markdownRemark(
      frontmatter: { templateKey: { eq: "what-we-do-services-page" } }
    ) {
      html
      frontmatter {
        articlesWidget
        title
        seo {
          metaDescription
          focusKeyword
        }
      }
    }
    services: allMarkdownRemark(
      filter: { frontmatter: { templateKey: { eq: "service" } } }
    ) {
      edges {
        node {
          fields {
            slug
          }
          frontmatter {
            name
          }
        }
      }
    }
    title: markdownRemark(
      frontmatter: { templateKey: { eq: "what-we-do-overview-page" } }
    ) {
      frontmatter {
        service {
          breadcrumb
        }
      }
    }
  }
`;
