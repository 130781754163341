import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import {
  PreviewCompatibleImage as Image,
  PreviewCompatibleLink
} from 'src/components/PreviewCompatible';
import {
  H2,
  CenteredBlockPadding,
  ContentBlock,
  Separator
} from 'src/components//Block';
import { getAltImage } from 'src/utils';

const PreviewCompatibleImage = styled(Image)`
  border-radius: 4px 4px 0 0;
`;

const Block = styled(CenteredBlockPadding)`
  background-color: ${props => props.theme.bgColor};
`;

const H4 = styled.h4`
  font-size: 1.1em;
  margin: 0.8em 0;
`;

const H5 = styled.h5`
  font-weight: 500;
  font-size: 0.9em;
  margin: 0.5em 0;
  color: ${props => props.theme.primaryColor};
  text-transform: uppercase;
`;

const LatestArticles = styled.div`
  display: flex;
  @media not screen and (min-width: ${({ theme }) =>
      theme.breakpoints.desktopMin}) {
    flex-wrap: wrap;
  }
  justify-content: flex-start;
  @media screen and (min-width: ${({ theme }) =>
      theme.breakpoints.desktopMin}) {
    > div + div {
      margin-left: 10px;
    }
  }
`;

const Article = styled.div`
  flex-basis: 60%;
  flex-grow: 1;
  flex-shrink: 1;
  font-size: 0.8em;
  width: 120px;
  background-color: white;
  border: 1px solid ${props => props.theme.borderColor};
  border-radius: 4px;
  @media not screen and (min-width: ${({ theme }) =>
      theme.breakpoints.desktopMin}) {
    margin: 1em;
  }
`;

const ArticleLink = styled(PreviewCompatibleLink)`
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  align-items: stretch;
  height: 100%;
  color: black;
  text-decoration: none;
`;

const Content = styled.div`
  padding: 1em 0.8em 2em;
  flex-grow: 1;
`;

const containerStyle = {
  height: '250px'
};

const imgStyle = {
  width: '100%',
  height: '100%',
  objectFit: 'cover'
};

const ArticlesWidgetView = ({ title, articles, separator }) => {
  if (!articles.length) {
    return null;
  }
  return (
    <React.Fragment>
      <Block>
        <H2>{title}</H2>
        <ContentBlock>
          <LatestArticles>
            {articles.map((data, index) => {
              return (
                <Article key={index}>
                  <ArticleLink to={data.link ? data.link : data.slug}>
                    {data.image && (
                      <PreviewCompatibleImage
                        style={containerStyle}
                        imgStyle={imgStyle}
                        image={data.image}
                        alt={getAltImage('article', data)}
                      />
                    )}
                    <Content>
                      <H5>{data.type}</H5>
                      <H4>{data.title}</H4>
                    </Content>
                  </ArticleLink>
                </Article>
              );
            })}
          </LatestArticles>
        </ContentBlock>
      </Block>
      {separator && <Separator />}
    </React.Fragment>
  );
};

ArticlesWidgetView.propTypes = {
  title: PropTypes.string,
  separator: PropTypes.bool,
  articles: PropTypes.array
};

export default ArticlesWidgetView;
