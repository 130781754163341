import React from 'react';
import PropTypes from 'prop-types';

import Layout from 'src/components/Layout';
import SEO from 'src/components/SEO';
import ArticlesWidget from 'src/components/widgets/ArticlesWidget/ArticlesWidget';
import {
  PageBlock,
  ListContainer,
  ListBlock,
  ListLink
} from 'src/components/PageBlock';
import { FilterBlock } from 'src/components/Block';
import { CategoryFilter } from 'src/components/Filter';
import { getAltImage } from 'src/utils';

export const Content = ({ articles }) => (
  <PageBlock>
    <ListContainer>
      {articles.map((data, index) => {
        return (
          <ListBlock key={index} border featured={data.featured}>
            <ListLink
              data={data}
              tag={data.type}
              alt={getAltImage('article', data)}
            />
          </ListBlock>
        );
      })}
    </ListContainer>
  </PageBlock>
);

Content.defaultProps = {
  articles: [
    {
      featured: false
    }
  ]
};

Content.propTypes = {
  articles: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      description: PropTypes.string,
      type: PropTypes.string.isRequired,
      featured: PropTypes.bool,
      calltoaction: PropTypes.string,
      image: PropTypes.object
    })
  )
};

const InsightPageTemplate = ({
  heading,
  seo,
  filter,
  articles,
  categories,
  location,
  articlesWidget,
  showFilter
}) => (
  <Layout location={location}>
    <SEO
      title={heading}
      description={seo.metaDescription}
      keywords={seo.focusKeyword}
    />
    {showFilter && (
      <FilterBlock>
        <CategoryFilter
          text={filter}
          categories={categories}
          link="/articles"
        />
      </FilterBlock>
    )}
    <Content articles={articles} />
    {articlesWidget && <ArticlesWidget />}
  </Layout>
);

InsightPageTemplate.defaultProps = {
  articlesWidget: false,
  showFilter: true,
  articles: [
    {
      featured: false
    }
  ]
};

InsightPageTemplate.propTypes = {
  articlesWidget: PropTypes.bool,
  showFilter: PropTypes.bool,
  image: PropTypes.object,
  heading: PropTypes.string.isRequired,
  seo: PropTypes.shape({
    metaDescription: PropTypes.string,
    focusKeyword: PropTypes.string
  }),
  filter: PropTypes.string.isRequired,
  categories: PropTypes.arrayOf(PropTypes.string),
  articles: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      description: PropTypes.string,
      type: PropTypes.string.isRequired,
      image: PropTypes.object,
      calltoaction: PropTypes.string,
      featured: PropTypes.bool
    })
  ),
  location: PropTypes.string.isRequired
};

export default InsightPageTemplate;
