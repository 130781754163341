import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import {
  H2 as BaseH2,
  P as BaseP,
  DarkCenteredBlock
} from 'src/components/Block';

const P = styled(BaseP)`
  font-weight: 500;
`;

const H2 = styled(BaseH2)`
  font-size: 2em;
  font-weight: 300;
`;

const ListNumbers = styled.ul`
  list-style: none;
  margin: 50px 0 0;
  padding: 0;
  display: flex;
  @media not screen and (min-width: ${({ theme }) =>
      theme.breakpoints.desktopMin}) {
    flex-wrap: wrap;
  }
  justify-content: center;
`;

const NumTitle = styled.h3`
  margin: 0;
  color: ${props => props.theme.primaryColor};
  font-weight: 300;
  font-size: 3em;
`;

const NumItem = styled.li`
  padding: 0 2em;
  @media not screen and (min-width: ${({ theme }) =>
      theme.breakpoints.desktopMin}) {
    width: 8em;
  }
`;

const NumbersWidgetView = ({ title, numbers, overlap }) => {
  if (!numbers.length) {
    return null;
  }
  return (
    <DarkCenteredBlock overlap={overlap}>
      <H2>{title}</H2>
      <ListNumbers>
        {numbers.map((data, index) => {
          return (
            <NumItem key={index}>
              <NumTitle>{data.number}</NumTitle>
              <P>{data.text}</P>
            </NumItem>
          );
        })}
      </ListNumbers>
    </DarkCenteredBlock>
  );
};

NumbersWidgetView.propTypes = {
  title: PropTypes.string,
  overlap: PropTypes.bool,
  numbers: PropTypes.arrayOf(
    PropTypes.shape({
      number: PropTypes.string.isRequired,
      text: PropTypes.string.isRequired
    })
  )
};

export default NumbersWidgetView;
