import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import { tree } from 'src/utils';

import ServicesPageTemplate from 'src/components/pages/ServicesPageTemplate';

const ServicesPage = ({ data, location }) => {
  const { frontmatter } = data.markdownRemark;
  const services = data.services.edges.map(item => item.node.frontmatter);
  const roles = tree(
    data.roles.edges.map(item =>
      Object.assign({}, item.node.frontmatter, { slug: item.node.fields.slug })
    )
  );
  const sectors = tree(
    data.sectors.edges.map(item =>
      Object.assign({}, item.node.frontmatter, { slug: item.node.fields.slug })
    )
  );

  return (
    <ServicesPageTemplate
      articlesWidget={frontmatter.articlesWidget}
      image={frontmatter.image}
      title={frontmatter.title}
      header={frontmatter.header}
      seo={frontmatter.seo}
      sector={frontmatter.sector}
      role={frontmatter.role}
      services={services}
      roles={roles}
      sectors={sectors}
      location={location.pathname}
    />
  );
};

ServicesPage.propTypes = {
  data: PropTypes.object,
  location: PropTypes.object
};

export default ServicesPage;

export const pageQuery = graphql`
  query ServicesPageTemplate {
    markdownRemark(
      frontmatter: { templateKey: { eq: "what-we-do-overview-page" } }
    ) {
      frontmatter {
        articlesWidget
        image {
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH)
          }
        }
        title
        header {
          heading
          subheading
        }
        seo {
          metaDescription
          focusKeyword
        }
        sector {
          title
        }
        role {
          title
        }
      }
    }
    services: allMarkdownRemark(
      filter: { frontmatter: { templateKey: { eq: "service" } } }
      sort: { fields: [frontmatter___name], order: ASC }
    ) {
      edges {
        node {
          frontmatter {
            name
            bodySummary
          }
        }
      }
    }
    roles: allMarkdownRemark(
      filter: { frontmatter: { templateKey: { eq: "role" } } }
      sort: { fields: [frontmatter___name], order: ASC }
    ) {
      edges {
        node {
          fields {
            slug
          }
          frontmatter {
            name
            category
            bodySummary
          }
        }
      }
    }
    sectors: allMarkdownRemark(
      filter: { frontmatter: { templateKey: { eq: "sector" } } }
      sort: { fields: [frontmatter___name], order: ASC }
    ) {
      edges {
        node {
          fields {
            slug
          }
          frontmatter {
            name
            category
            bodySummary
          }
        }
      }
    }
  }
`;
