import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';

import JoinUsVacancyPageTemplate from 'src/components/pages/JoinUsVacancyPageTemplate';

const JoinUsPage = ({ data }) => {
  const { frontmatter, html } = data.markdownRemark;

  return (
    <JoinUsVacancyPageTemplate
      articlesWidget={frontmatter.articlesWidget}
      image={frontmatter.image}
      subname={frontmatter.subname}
      name={frontmatter.name}
      jobType={frontmatter.jobType}
      level={frontmatter.level}
      seo={frontmatter.seo}
      html={html}
    />
  );
};

JoinUsPage.propTypes = {
  data: PropTypes.object,
  location: PropTypes.object
};

export default JoinUsPage;

export const pageQuery = graphql`
  query JobByName($id: String!) {
    markdownRemark(
      frontmatter: { templateKey: { eq: "join-marlin-hawk" } }
      id: { eq: $id }
    ) {
      html
      frontmatter {
        articlesWidget
        subname
        name
        image {
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH)
          }
        }
        jobType
        level
        seo {
          metaDescription
          focusKeyword
        }
      }
    }
  }
`;
