import React from 'react';
import PropTypes from 'prop-types';
import ReactModal from 'react-modal';
import { navigate } from 'gatsby';

import { ProfileModal } from 'src/components/ProfileComponent';

const baseModalStyle = {
  overflow: 'hidden',
  top: '100px',
  border: 0,
  borderRadius: '3px',
  padding: 0
};

const overlayStyle = {
  backgroundColor: 'rgba(0, 0, 0, 0.3)'
};

/* eslint-disable no-console, react/prop-types */

const ProfileView = ({
  modalIsOpen,
  selectProfile,
  profile,
  results,
  navigateTo
}) => {
  const navigateAndClose = to => {
    selectProfile(null);
    navigate(to);
  };

  return (
    <ReactModal
      style={{
        content: baseModalStyle,
        overlay: overlayStyle
      }}
      isOpen={modalIsOpen}
      onRequestClose={() => navigateAndClose(navigateTo)}
      ariaHideApp={false}
    >
      {profile && (
        <ProfileModal
          profiles={results}
          data={profile}
          onClose={() => selectProfile(null)}
          onSelect={item => selectProfile(item)}
          to={navigateTo}
          link={true}
        />
      )}
    </ReactModal>
  );
};

ProfileView.propTypes = {
  modalIsOpen: PropTypes.bool,
  selectProfile: PropTypes.func,
  profile: PropTypes.object,
  results: PropTypes.array,
  navigateTo: PropTypes.string
};

export default ProfileView;
