/* eslint-disable no-console, react/prop-types */

import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

import { titleFont } from 'src/styles/globalStyle';
import {
  PreviewCompatibleImage,
  PreviewCompatibleBackgroundImage,
  PreviewCompatibleLink
} from 'src/components/PreviewCompatible';
import BodyContent from 'src/components/BodyContent';

export const cardHover = css`
  &:hover {
    background-color: ${props => props.theme.primaryColor};
    cursor: pointer;
    button {
      border-color: white;
    }
  }
`;

export const headerShadow = css`
  box-shadow: 0px 2px 16px 0 rgba(0, 0, 0, 0.5);
`;

export const Category = styled.div`
  color: ${props => props.theme.primaryColor};
  text-transform: uppercase;
  font-weight: 500;
  font-size: 0.8em;
  margin-bottom: 1em;
`;

export const Link = styled(PreviewCompatibleLink)`
  text-decoration: none;
  color: initial;
`;

export const ContentBlock = styled.div`
  text-align: left;
  margin: 0 auto;
`;

export const CenteredBlock = styled.div`
  text-align: center;
  margin: 0 auto;
`;

const padding = css`
  padding: 2em 0;
  @media screen and (min-width: ${({ theme }) =>
      theme.breakpoints.desktopMin}) {
    padding: 2em 8em;
  }
`;

export const CenteredBlockPadding = styled(CenteredBlock)`
  ${padding}
`;

export const BlockPadding = styled.div`
  ${padding}
`;

export const DarkCenteredBlock = styled(CenteredBlockPadding)`
  background-color: ${props => props.theme.bgDarkColor};
  color: white;
  @media screen and (min-width: ${({ theme }) =>
      theme.breakpoints.desktopMin}) {
    border-radius: 9px;
    box-shadow: 0px 3px 6px rgba(0, 40, 85, 0.5);
    margin: ${props => (props.overlap ? '-5em 5em 0' : '0 5em')};
  }
`;

export const LimitedWidth = styled.div`
  @media screen and (min-width: ${({ theme }) =>
      theme.breakpoints.desktopMin}) {
    width: 60%;
  }
  @media not screen and (min-width: ${({ theme }) =>
      theme.breakpoints.desktopMin}) {
    padding: 1em;
  }
  margin: 0 auto 5em;
`;

export const headerStyle = css`
  padding: 5em 1em;
  @media screen and (min-width: ${({ theme }) =>
      theme.breakpoints.desktopMin}) {
    padding: 5em;
  }
  min-height: ${props => (props.height ? props.height : 'auto')};
  display: flex;
  flex-direction: column;
  justify-content: ${props => (props.justify ? props.justify : 'center')};
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  ${props =>
    props.overlap &&
    css`
      @media screen and (min-width: ${({ theme }) =>
          theme.breakpoints.desktopMin}) {
        padding: 4em 5em 15em;
      }
    `}
`;

export const BasicHeader = styled(PreviewCompatibleBackgroundImage)`
  ${headerStyle};
`;

export const Header = styled(BasicHeader)`
  background-color: ${props =>
    props.color ? props.color : 'hsla(0, 0%, 0%, 0.2)'};
  background-blend-mode: ${props => (props.blend ? props.blend : 'overlay')};
`;

export const HeaderPreview = styled.div`
  ${headerStyle};
  background-image: ${props => `url(${props.image})`};
`;

export const BaseHeaderText = styled.p`
  color: white;
  font-size: 1.2em;
`;

export const HeaderText = styled(BaseHeaderText)`
  @media screen and (min-width: ${({ theme }) =>
      theme.breakpoints.desktopMin}) {
    width: 60%;
  }
`;

export const HeadingBlock = styled.div`
  @media screen and (min-width: ${({ theme }) =>
      theme.breakpoints.desktopMin}) {
    width: 25em;
  }
`;

export const CenteredHeadingBlock = styled(HeadingBlock)`
  margin: 0 auto;
`;

export const Heading = styled.section`
  background-color: lightgray;
  @media screen and (min-width: ${({ theme }) =>
      theme.breakpoints.desktopMin}) {
    padding: 4em;
  }
`;

export const H1 = styled.h1`
  font-size: 2em;
  font-weight: 400;
  ${titleFont};
`;

export const BigH1 = styled.h1`
  margin: 0.5em 0;
  font-size: 2.5em;
  font-weight: 300;
  line-height: 1.1em;
  ${titleFont};
`;

export const H2 = styled.h2`
  margin: 0.5em 0;
  font-size: 2.5em;
  font-weight: 300;
  line-height: 1.1em;
  ${titleFont};
`;

export const H3 = styled.h3`
  font-weight: 300;
  font-size: 1.8em;
  ${titleFont};
`;

export const H4 = styled.h4`
  font-weight: 400;
`;

export const P = styled.p`
  font-size: 0.9em;
  line-height: 1.5em;
`;

export const P2 = styled.p`
  font-size: 1.2em;
  line-height: 1.2em;
`;

export const HeaderTitle = styled(H1)`
  font-size: 4em;
  @media not screen and (min-width: ${({ theme }) =>
      theme.breakpoints.desktopMin}) {
    font-size: 2em;
  }
  color: white;
  margin: 0;
  font-weight: 200;
  line-height: 1em;
`;

const ActiveLink = props => (
  <Link {...props} activeClassName="active-link">
    {props.children}
  </Link>
);

ActiveLink.defaultProps = {
  partiallyActive: true
};

ActiveLink.propTypes = {
  children: PropTypes.node.isRequired,
  partiallyActive: PropTypes.bool
};

export const NavLink = styled(ActiveLink)`
  padding: 24px 16px;
  text-decoration: none;
  color: black;
  font-size: 0.9em;
  white-space: nowrap;
  margin-bottom: -1px;
  border-bottom: 4px solid transparent;
  &.active-link {
    border-bottom-color: ${props => props.theme.navActive};
  }
`;

export const NavLinkExternal = styled.a`
  padding: 24px 16px;
  text-decoration: none;
  color: black;
  font-size: 0.9em;
  white-space: nowrap;
  margin-bottom: -1px;
  border-bottom: 4px solid transparent;
  &.active-link {
    border-bottom-color: ${props => props.theme.navActive};
  }
`;

export const NavLinkWrapper = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  &:hover {
    background-color: whitesmoke;
  }
  &.active {
    background-color: ${props => props.theme.navColor};
  }
`;

export const NavLinkInner = styled.span`
  padding: 0 5px 4px 5px;
`;

export const FlexContent = styled.div`
  display: flex;
  @media not screen and (min-width: ${({ theme }) =>
      theme.breakpoints.desktopMin}) {
    flex-wrap: wrap;
  }
`;

export const PageContent = styled.div`
  margin: 2em 0;
  h1 {
    font-size: 1.5em;
  }
  h2 {
    font-size: 1.4em;
  }
  p,
  li,
  td {
    font-size: 0.9em;
  }
  td {
    vertical-align: top;
    padding: 5px 0;
  }
  table {
    border-spacing: 1px;
    empty-cells: hide;
  }
  table th {
    background-color: ${props => props.theme.tertiaryColor};
    padding: 8px 20px 8px 5px;
  }
  table td {
    padding: 10px 20px 10px 5px;
    border: solid;
    border-color: ${props => props.theme.tertiaryColor};
    border-width: 0 0 1px 0;
  }
  table td:first-child {
    white-space: nowrap;
  }
  h1,
  h2 {
    font-weight: 300;
    margin: 2em 0 0 0;
  }
  h1 + p,
  h2 + p {
    margin-top: 5px;
  }
`;

export const SectionImage = styled(PreviewCompatibleImage)`
  @media screen and (min-width: ${({ theme }) =>
      theme.breakpoints.desktopMin}) {
    width: 49%;
  }
  @media not screen and (min-width: ${({ theme }) =>
      theme.breakpoints.desktopMin}) {
    width: 100%;
  }
  object-fit: cover;
  height: 450px;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const Image = styled(PreviewCompatibleImage)`
  object-fit: cover;
  height: 150px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
`;

export const SectionContentBlock = styled(FlexContent)`
  @media screen and (min-width: ${({ theme }) =>
      theme.breakpoints.desktopMin}) {
    padding: 0.5em 5em;

    &.right {
      flex-direction: row-reverse;
    }
    &.left {
      ${SectionImage} {
        margin-right: 2em;
      }
    }
    &.right {
      ${SectionImage} {
        margin-left: 2em;
      }
    }
  }
`;

export const SectionText = styled.div`
  margin: auto 0;
  @media not screen and (min-width: ${({ theme }) =>
      theme.breakpoints.desktopMin}) {
    padding: 1em;
  }
  @media screen and (min-width: ${({ theme }) =>
      theme.breakpoints.desktopMin}) {
    flex-basis: 51%;
  }
`;

export const SeparatorBlock = styled.div`
  background-color: ${props => props.theme.bgColor};
`;

export const Line = styled.div`
  @media screen and (min-width: ${({ theme }) =>
      theme.breakpoints.desktopMin}) {
    border-bottom: 1px solid ${props => props.theme.primaryColor};
    margin: 0 8em;
    padding-bottom: 3em;
  }
`;

export const Separator = () => (
  <SeparatorBlock>
    <Line />
  </SeparatorBlock>
);

export const PartialBackground = styled.div`
  background: linear-gradient(
    to bottom,
    transparent 0%,
    transparent ${props => (props.y ? props.y : '60%')},
    ${props => (props.color ? props.color : 'white')}
      ${props => (props.y ? props.y : '60%')},
    ${props => (props.color ? props.color : 'white')} 100%
  );
  padding-bottom: ${({ paddingBottom }) => paddingBottom || 0};
`;

export const DarkBg = styled.div`
  background-color: ${({ theme }) => theme.bgColor};
`;

export const StyledContent = styled(BodyContent)`
  h1 {
    font-size: 1em;
    font-weight: 500;
    line-height: 2em;
    margin-bottom: 0.5em;
    border-bottom: 1px solid ${props => props.theme.primaryColor};
  }
  h2 {
    font-size: 1.3em;
    font-weight: 400;
    color: ${props => props.theme.primaryColor};
  }
  blockquote {
    color: ${props => props.theme.primaryColor};
    text-transform: uppercase;
    font-size: 0.7em;
    font-weight: 600;
    margin: 0;
  }
  p {
    margin-top: 0;
    line-height: 1.5em;
  }
  a {
    color: inherit;
  }
  ul {
    line-height: 1.5em;
  }
`;

export const TagBlock = styled.div`
  margin-bottom: 5em;
`;

export const FilterBlock = styled.div`
  margin: 0 auto;
  padding: 1.5em 1em;
  @media screen and (min-width: ${({ theme }) =>
      theme.breakpoints.desktopMin}) {
    padding: 1.5em 5em;
  }
  background-color: whitesmoke;
  border-bottom: 1px solid ${props => props.theme.primaryColor};
`;

export const TopRightButton = styled.div`
  position: absolute;
  right: 1.5em;
  top: 1.5em;
`;

export const PaddingBlock = styled.div`
  @media screen and (min-width: ${({ theme }) =>
      theme.breakpoints.desktopMin}) {
    padding: 1em 5em;
  }
`;

export const Column = styled.div`
  ${props =>
    props.large &&
    css`
      width: 25%;
    `}
  display: flex;
  margin-left: 5px;
  margin-right: 25px;
  flex-direction: column;
  padding: 8px 16px;
`;

export const StyledLink = styled(PreviewCompatibleLink)`
  text-decoration: none;
  color: inherit;
  display: flex;
`;

const Id = styled.div`
  position: absolute;
  height: 0px;
  min-width: 100px;
  body:hover & {
    height: 4px;
    box-shadow: 0 0 3px 1px red, inset 0 0 3px 1px green;
  }
  body:hover &::before {
    position: absolute;
    left: 0;
    min-width: max-content;
    bottom: 100%;
    content: attr(class);
    color: #a00;
    background: #d8ffd8c0;
    font-size: 12px;
  }
`;

// export const ComponentId = ({id}) => <Id className={`COMP__${id}`} />
export const ComponentId = ({id}) => null

ComponentId.propTypes = {
  id: PropTypes.string
};
