import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import Layout from 'src/components/Layout';
import SEO from 'src/components/SEO';
import CTA from 'src/components/CTA';
import {
  ContentBlock,
  CenteredBlock,
  Link,
  Header,
  HeaderTitle,
  HeaderText,
  H2,
  P,
  cardHover
} from 'src/components/Block';
import BannerWidget from 'src/components/widgets/BannerWidget';
import ArticlesWidget from 'src/components/widgets/ArticlesWidget/ArticlesWidget';

const List = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
`;

const Item = styled.li`
  padding: 4em 0;
  @media not screen and (min-width: ${({ theme }) =>
      theme.breakpoints.desktopMin}) {
    padding: 2em 1em;
  }
  margin: 0;
  border-bottom: 2px solid white;
  color: black;
  &.darkbg {
    background-color: whitesmoke;
  }
  ${cardHover};
`;

const ContentBlockFixedWidth = styled(ContentBlock)`
  @media screen and (min-width: ${({ theme }) =>
      theme.breakpoints.desktopMin}) {
    width: 60%;
  }
`;

export const PageContent = ({
  image,
  heading,
  subheading,
  calltoaction,
  vacancies
}) => (
  <React.Fragment>
    <Header image={image}>
      <HeaderTitle>{heading}</HeaderTitle>
      <HeaderText>{subheading}</HeaderText>
    </Header>
    {vacancies.length !== 0 && (
      <CenteredBlock>
        <List>
          {vacancies.map((data, index) => {
            return (
              <Link key={index} to={data.slug}>
                <Item className={index % 2 !== 0 ? 'darkbg' : ''}>
                  <ContentBlockFixedWidth>
                    <H2>
                      {data.position} – {data.location}
                    </H2>
                    <P>{data.description}</P>
                    <CTA primary light large inverted>
                      {calltoaction}
                    </CTA>
                  </ContentBlockFixedWidth>
                </Item>
              </Link>
            );
          })}
        </List>
      </CenteredBlock>
    )}
  </React.Fragment>
);

PageContent.propTypes = {
  articlesWidget: PropTypes.bool,
  image: PropTypes.object,
  heading: PropTypes.string.isRequired,
  subheading: PropTypes.string.isRequired,
  calltoaction: PropTypes.string.isRequired,
  vacancies: PropTypes.arrayOf(
    PropTypes.shape({
      position: PropTypes.string.isRequired,
      location: PropTypes.string.isRequired,
      description: PropTypes.string.isRequired
    })
  )
};

const VacanciesPageTemplate = ({
  image,
  heading,
  subheading,
  seo,
  metaVacancies,
  vacancies,
  location,
  articlesWidget
}) => (
  <Layout location={location}>
    <SEO
      title={heading}
      description={seo.metaDescription}
      keywords={seo.focusKeyword}
    />
    <PageContent
      image={image}
      heading={heading}
      subheading={subheading}
      calltoaction={metaVacancies.calltoaction}
      vacancies={vacancies}
    />
    <BannerWidget />
    {articlesWidget && <ArticlesWidget />}
  </Layout>
);

VacanciesPageTemplate.defaultProps = {
  articlesWidget: false
};

VacanciesPageTemplate.propTypes = {
  articlesWidget: PropTypes.bool,
  image: PropTypes.object,
  heading: PropTypes.string.isRequired,
  subheading: PropTypes.string.isRequired,
  seo: PropTypes.shape({
    metaDescription: PropTypes.string,
    focusKeyword: PropTypes.string
  }),
  metaVacancies: PropTypes.shape({
    calltoaction: PropTypes.string.isRequired
  }),
  vacancies: PropTypes.arrayOf(
    PropTypes.shape({
      position: PropTypes.string.isRequired,
      location: PropTypes.string.isRequired,
      description: PropTypes.string.isRequired
    })
  ),
  location: PropTypes.string.isRequired
};

export default VacanciesPageTemplate;
