import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { Column, StyledLink as BaseStyledLink } from 'src/components/Block';

const StyledLink = styled(BaseStyledLink)`
  margin-bottom: 15px;
`;

const ColumnTitle = styled.div`
  font-size: 1.5em;
  font-weight: 400;
  margin-bottom: 20px;
  border-bottom: 4px solid transparent;
`;

const ColumnItem = styled.div`
  font-size: 0.9em;
  font-weight: 300;
  padding-bottom: 2px;
  border-bottom: 4px solid transparent;
  &:hover {
    border-color: ${props => props.theme.primaryColor};
  }
`;

const LinkItems = ({ items }) =>
  items.map(({ name, path }, index) => (
    <StyledLink key={index} to={path} activeClassName="active-nav">
      <ColumnItem>{name}</ColumnItem>
    </StyledLink>
  ));

LinkItems.propTypes = {
  items: PropTypes.array
};

const SubMenu = ({ to, items, children, large }) => (
  <Column highlight large={large}>
    {children && (
      <StyledLink
        to={to}
        activeClassName="active-nav"
        style={{ marginBottom: '0px' }}
      >
        <ColumnTitle>{children}</ColumnTitle>
      </StyledLink>
    )}
    <LinkItems items={items} />
  </Column>
);

SubMenu.propTypes = {
  large: PropTypes.bool,
  to: PropTypes.string,
  items: PropTypes.array,
  children: PropTypes.node
};

export default SubMenu;
