import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

import {
  P as BaseP,
  H2 as BaseH2,
  FlexContent,
  Image as BaseImage,
  Category
} from 'src/components/Block';

const P = styled(BaseP)`
  margin: 0;
  font-weight: 400;
  font-size: 1.2em;
  ${props =>
    props.dark &&
    css`
      color: grey;
      font-weight: 500;
    `}
`;

const Link = styled.a`
  height: 100%;
  width: 100%;
  display: block;
  text-decoration: none;
  color: initial;
`;

const Image = styled(BaseImage)`
  @media screen and (min-width: ${({ theme }) =>
      theme.breakpoints.desktopMin}) {
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
  }
`;

const H2 = styled(BaseH2)`
  margin-bottom: 0;
  ${props =>
    props.small &&
    css`
      font-size: 1.1em;
      font-weight: 700;
      color: black;
    `}
`;

export const PageBlock = styled.div`
  background-color: ${props => props.theme.breadcrumbBgColor};
  @media screen and (min-width: ${({ theme }) =>
      theme.breakpoints.desktopMin}) {
    padding: 4em 5em;
  }
`;

const ContentBlock = styled.div`
  padding: 1em 1em 2em;
`;

export const ListBlock = styled.div`
  margin: 10px 0;
  box-sizing: border-box;
  @media not screen and (min-width: ${({ theme }) =>
    theme.breakpoints.desktopMin}) {
    width: 100%;
  }
  @media screen and (min-width: ${({ theme }) =>
    theme.breakpoints.desktopMin}) {
    width: calc(50% - 20px);
    margin: 10px;
    border-radius: 3px;
    ${props =>
      props.featured &&
      css`
        width: 100%;
      `}
  }
  background-color: white;
  ${props =>
    props.featured &&
    css`
      background-color: ${props => props.theme.primaryColor};
    `}
  ${props =>
    props.border &&
    css`
      @media screen and (min-width: ${({ theme }) =>
          theme.breakpoints.desktopMin}) {
        border: 1px solid ${props => props.theme.borderColor};
      }
    `}
  ${props =>
    !props.border &&
    css`
      background-color: ${props => props.theme.primaryColor};
      color: ${props => props.theme.secondaryColor};
    `}
  ${props =>
    props.featured &&
    css`
      & ${H2}, ${P} {
        color: white;
      }
      & ${H2} {
        font-weight: 400;
      }
      & ${P} {
        font-size: 0.9em;
        font-weight: 500;
      }
      & ${TextContent} {
        display: flex;
        justify-content: space-between;
      }
      & ${P} {
        flex-basis: 50%;
      }
      & ${Category} {
        font-size: 0.7em;
        font-weight: 700;
        color: ${props => props.theme.secondaryColor};
      }
      & ${Image} {
        @media screen and (min-width: ${({ theme }) =>
            theme.breakpoints.desktopMin}) {
          height: 24em;
        }
      }
    `}
`;

export const ListContainer = styled(FlexContent)`
  flex-wrap: wrap;
  justify-content: center;
  @media screen and (min-width: ${({ theme }) =>
      theme.breakpoints.desktopMin}) {
    margin: -10px;
  }
`;

const ItemCTA = styled.div``;

const TextLink = styled.span`
  color: ${props => props.theme.secondaryColor};
  font-weight: 400;
  font-size: 0.9em;
  margin-right: 5px;
`;

export const TextContent = styled.div``;

export const ListContent = ({ data, tag, alt, small }) => (
  <React.Fragment>
    {data.image && (
      <Image
        image={data.image}
        alt={alt}
        imgStyle={{
          width: '100%',
          height: '100%',
          objectFit: 'cover'
        }}
      />
    )}
    <ContentBlock>
      {tag && <Category>{tag}</Category>}
      <TextContent>
        <H2 small={small}>{data.title}</H2>
        {data.location && <P dark>{data.location}</P>}
        {data.text && <P>{data.text}</P>}
      </TextContent>
      {data.calltoaction && (
        <ItemCTA>
          <TextLink>{data.calltoaction}</TextLink>
          <img src="/img/arrow-secondary.png" width="20" />
        </ItemCTA>
      )}
    </ContentBlock>
  </React.Fragment>
);

ListContent.defaultProps = {
  small: false
};

ListContent.propTypes = {
  tag: PropTypes.string,
  small: PropTypes.bool,
  data: PropTypes.shape({
    text: PropTypes.string,
    title: PropTypes.string,
    location: PropTypes.string,
    slug: PropTypes.string,
    image: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    calltoaction: PropTypes.string
  }),
  alt: PropTypes.string
};

export const ListLink = ({ data, tag, alt }) => (
  <Link href={data.slug}>
    <ListContent data={data} tag={tag} alt={alt} small />
  </Link>
);

ListLink.propTypes = {
  tag: PropTypes.string,
  data: PropTypes.shape({
    text: PropTypes.string,
    title: PropTypes.string,
    slug: PropTypes.string,
    image: PropTypes.oneOfType([PropTypes.string, PropTypes.object])
  }),
  alt: PropTypes.string
};
