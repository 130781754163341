import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import styled from 'styled-components';

import Layout from 'src/components/Layout';
import SEO from 'src/components/SEO';
import { Header, HeaderTitle, HeaderText } from 'src/components/Block';

const Content = styled.div`
  padding: 2em 5em;
`;

const Link = styled.a`
  margin-bottom: 5px;
  display: block;
  font-weight: 400;
  text-decoration: none;
  font-size: 1.2em;
  color: ${props => props.theme.primaryColor};
`;

const MediaItem = styled.div`
  margin-bottom: 2em;
`;

const PublishedDate = styled.div`
  color: ${props => props.theme.secondaryColor};
`;

const MediaPage = ({ data, location }) => {
  const { frontmatter } = data.markdownRemark;
  const mediaLinks = data.media.edges.map(item => ({
    ...item,
    date: new Intl.DateTimeFormat('en-GB', { dateStyle: 'full' }).format(
      new Date(item.node.frontmatter.date)
    )
  }));

  return (
    <Layout location={location.pathname}>
      <SEO
        title={frontmatter.heading}
        description={frontmatter.seo.metaDescription}
        keywords={frontmatter.seo.focusKeyword}
      />
      <Header image={frontmatter.image} height="10em">
        <HeaderTitle>{frontmatter.title}</HeaderTitle>
        <HeaderText>{frontmatter.heading}</HeaderText>
      </Header>
      <Content>
        {mediaLinks.map((item, index) => (
          <MediaItem key={index}>
            <Link href={item.node.frontmatter.link} target="_blank">
              {item.node.frontmatter.title}
            </Link>
            <PublishedDate>{item.date}</PublishedDate>
          </MediaItem>
        ))}
      </Content>
    </Layout>
  );
};

MediaPage.propTypes = {
  data: PropTypes.object,
  location: PropTypes.object
};

export default MediaPage;

export const pageQuery = graphql`
  query MediaPageTemplate {
    markdownRemark(frontmatter: { templateKey: { eq: "media-page" } }) {
      frontmatter {
        image {
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH)
          }
        }
        title
        heading
        seo {
          metaDescription
          focusKeyword
        }
      }
    }
    media: allMarkdownRemark(
      filter: { frontmatter: { templateKey: { eq: "media" } } }
      sort: { fields: [frontmatter___date], order: DESC }
    ) {
      edges {
        node {
          frontmatter {
            title
            link
            date
          }
        }
      }
    }
  }
`;
