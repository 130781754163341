import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import TextareaAutosize from 'react-textarea-autosize';

import { bodyFont } from 'src/styles/globalStyle';
import Tag from 'src/components/Tag';
import { DarkCenteredBlock as Block } from 'src/components/Block';

const DarkCenteredBlock = styled(Block)`
  margin: 5em 0 0;
  padding: 1em;
  @media screen and (min-width: ${({ theme }) =>
      theme.breakpoints.desktopMin}) {
    padding: 1em 1em 4em 1em;
    margin: 5em;
  }
`;

const widgetStyle = css`
  width: 100%;
  background-color: transparent;
  border: none;
  box-sizing: border-box;
  font-size: 0.9em;
  color: white;
  ${bodyFont};
  &::placeholder {
    color: #a0a0a0;
  }
`;

const Input = styled.input`
  ${widgetStyle};
  padding: 10px;
`;

const TextArea = styled(TextareaAutosize)`
  ${widgetStyle};
  padding: 10px;
  height: 10em;
  min-width: 100%;
  max-width: 100%;
`;

const Select = styled.select`
  ${widgetStyle};
  color: white;
  padding: 0 4px;
  height: 2.6em;
  &:invalid {
    color: #a0a0a0;
  }
`;

const Option = styled.option`
  background-color: black;
`;

const WidgetBlock = styled.div`
  border: 1px solid #a0a0a0;
  margin: 0.8em 0;
  width: 20em;
`;

const FormTitle = styled.h4`
  margin: 0.2em 0 0.6em;
  font-weight: 300;
  font-size: 1.8em;
  padding-bottom: 0.4em;
  border-bottom: 1px solid ${props => props.theme.primaryColor};
  align-self: stretch;
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const SubmitButton = styled(Tag)`
  background-color: transparent;
`;

const ContactForm = ({
  title,
  email,
  firstname,
  lastname,
  linkedin,
  location,
  message,
  calltoaction,
  offices
}) => (
  <DarkCenteredBlock>
    <Form name="contact" method="POST" data-netlify="true">
      <input type="hidden" name="form-name" value="contact" />
      <FormTitle>{title}</FormTitle>
      <WidgetBlock>
        <Input type="text" placeholder={firstname} name="firstname" required />
      </WidgetBlock>
      <WidgetBlock>
        <Input type="text" placeholder={lastname} name="lastname" required />
      </WidgetBlock>
      <WidgetBlock>
        <Input type="email" placeholder={email} name="email" required />
      </WidgetBlock>
      <WidgetBlock>
        <Input type="text" placeholder={linkedin} name="linkedin" />
      </WidgetBlock>
      <WidgetBlock>
        <Select name="location" required defaultValue="">
          <Option value="" disabled hidden>
            {location}
          </Option>
          {offices.map((office, index) => {
            return (
              <Option key={index} value={office.city}>
                {office.city}
              </Option>
            );
          })}
        </Select>
      </WidgetBlock>
      <WidgetBlock>
        <TextArea
          name="message"
          placeholder={message}
          minRows={5}
          maxRows={10}
        ></TextArea>
      </WidgetBlock>
      <SubmitButton rounded secondary type="submit" as="button">
        {calltoaction}
      </SubmitButton>
    </Form>
  </DarkCenteredBlock>
);

ContactForm.propTypes = {
  title: PropTypes.string.isRequired,
  email: PropTypes.string.isRequired,
  location: PropTypes.string.isRequired,
  message: PropTypes.string,
  linkedin: PropTypes.string,
  firstname: PropTypes.string,
  lastname: PropTypes.string,
  calltoaction: PropTypes.string.isRequired,
  offices: PropTypes.arrayOf(
    PropTypes.shape({
      city: PropTypes.string.isRequired
    })
  )
};

export default ContactForm;
