import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import { PreviewCompatibleBackgroundImage } from 'src/components/PreviewCompatible';

const StyledIcon = styled(PreviewCompatibleBackgroundImage)`
  min-width: 40px;
  min-height: 40px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  background-blend-mode: overlay;
  cursor: pointer;
`;

const Icon = ({ icon, tooltip, onClick, dataTip, dataPlace }) => {
  const props = {
    image: icon,
    title: tooltip,
    onClick: onClick
  };

  if (dataTip) {
    return <StyledIcon {...props} data-tip={dataTip} data-place={dataPlace} />;
  }

  return <StyledIcon {...props} />;
};

Icon.propTypes = {
  icon: PropTypes.oneOfType([PropTypes.object, PropTypes.string]).isRequired,
  dataTip: PropTypes.string,
  dataPlace: PropTypes.string,
  tooltip: PropTypes.string.isRequired,
  onClick: PropTypes.func
};

export default Icon;
