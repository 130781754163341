import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';

import ArticlePageTemplate from 'src/components/pages/ArticlePageTemplate';

const ArticlePage = ({ data, pageContext, location }) => {
  const { frontmatter, html } = data.markdownRemark;
  const { next, prev } = pageContext;

  return (
    <ArticlePageTemplate
      articlesWidget={frontmatter.articlesWidget}
      image={frontmatter.image}
      type={frontmatter.type}
      title={frontmatter.title}
      seo={frontmatter.seo}
      authors={frontmatter.authors}
      html={html}
      prev={prev}
      next={next}
      location={location}
    />
  );
};

ArticlePage.propTypes = {
  data: PropTypes.object,
  pageContext: PropTypes.object,
  location: PropTypes.object
};

export default ArticlePage;

export const pageQuery = graphql`
  query InsightByName($id: String!) {
    markdownRemark(
      frontmatter: { templateKey: { eq: "article" } }
      id: { eq: $id }
    ) {
      html
      frontmatter {
        articlesWidget
        image {
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH)
          }
        }
        type
        title
        seo {
          metaDescription
          focusKeyword
        }
        authors
      }
    }
  }
`;
