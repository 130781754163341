import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { toHTML } from 'src/utils';
import Layout from 'src/components/Layout';
import SEO from 'src/components/SEO';
import {
    Header,
    DarkBg,
    StyledContent
} from 'src/components/Block';
import { HTMLContent } from 'src/components/Content';
import {
    PreviewCompatibleImage,
} from 'src/components/PreviewCompatible';
import {
    ApplyButton
} from 'src/components/ApplyButton'

const ContentBlock = styled.div`
  padding: 1em;
  @media screen and (min-width: ${({ theme }) => theme.breakpoints.desktopMin}) {
    padding: 1em;
    width: 60%;
    max-width: 800px;
    margin: 0 auto;
  }
`;

const HeaderWithPrimaryLine = styled.h1`
  margin-bottom: 1em;
  border-bottom: 1px solid ${props => props.theme.primaryColor};
  font-weight: normal;
`

const HeaderWithGreyLine = styled.h1`
  margin-bottom: 1em;
  border-bottom: 1px solid ${() => 'gray'};
  padding-top: 10px;
  font-weight: normal; 
  text-align: center;
`

const CentralHeader = styled.h1`
    text-align: center;
    margin-bottom: 0;
    margin-top: 1em;
`

const WideContentBlock = styled.div`
    margin: 0 1em;  
    text-align: center;
    @media screen and (min-width: ${({ theme }) => theme.breakpoints.desktopMin}) {
        margin: 0 8em; 
        text-align: left 
    }
`

const OfferItems = styled.div`
    display: flex;
    flex-wrap: wrap;

    flex-direction: column;
    @media screen and (min-width: ${({ theme }) => theme.breakpoints.desktopMin}) {
        flex-direction: row;
    }
`

const OfferItem = styled.div`
    box-sizing: border-box;
    text-align: center;
    font-size: 1.1em;
    padding: 0.5em;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    
    p {
        margin-bottom: 0em;
    }
    
    @media screen and (min-width: ${({ theme }) => theme.breakpoints.desktopMin}) {
        width: 20%;
    }
`

const LookingDescription = styled.div`
    width: 90%;
    text-align: center;
    margin: 0 auto 1.5em;
`

const LookingItems = styled.div`
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    @media screen and (min-width: ${({ theme }) => theme.breakpoints.desktopMin}) {
        flex-direction: row;
    }
`

const LookingItem = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    font-size: 1.3em;
    margin-bottom: 2em;
    
    @media screen and (min-width: ${({ theme }) => theme.breakpoints.desktopMin}) {
        width: 50%;

        &:nth-child(2n) {
            width: 40%;
            margin-left: 10%;
        }
    }
`

const LookingItemImage = styled.div`
    min-width: 70px;
`

const LookingBlocks = styled.div`
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    @media screen and (min-width: ${({ theme }) => theme.breakpoints.desktopMin}) {
        flex-direction: row;
    }
`

const LookingBlock = styled.div`
    margin-bottom: 1em;
    
    @media screen and (min-width: ${({ theme }) => theme.breakpoints.desktopMin}) {
        width: 33%;
        padding-right: 1em;
    
        margin-bottom: 0;
        
        &:last-child {
            padding-right: 0em;
        }
    }
`

const LookingBlockImage = styled.div`
    width: 100%;
    height: 229px;
    overflow: hidden;
`

const ExploreItems = styled.div`
    display: flex;
    flex-direction: column;
    margin-bottom: 1em;
    @media screen and (min-width: ${({ theme }) => theme.breakpoints.desktopMin}) {
        flex-direction: row;    
        justify-content: stretch;
    }
`

const ExploreItem = styled.a`
    display: block;
    box-sizing: border-box;
    flex-grow: 1;
    position: relative;
    
    margin-bottom: 1em;
    
    .gatsby-image-wrapper {
        position: initial;

        img {
            opacity: .25 !important;
        }
    }

    @media screen and (min-width: ${({ theme }) => theme.breakpoints.desktopMin}) {
        margin-bottom: 0em;
        margin: 0px 0.4em;
        
        &:first-child {
            margin-left: 0;    
        }
        
        &:last-child {
            margin-right: 0;
        }
    }
`

const ExploreItemLabel = styled.div`
    background: transparent;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 1.5em;
    box-sizing: border-box;
    z-index: 1;
    color: black;
    font-size: 1.4em;
    font-weight: 500;
    
    &:hover {
        color: white;
        background: rgba(0, 40, 85, 0.5);
    }
`

const ApplyButtonContainer = styled.div`
    display: flex;
    justify-content: center;
    margin-top: 1em;
`

const VacanciesList = styled.div`
    background: rgba(255, 157, 162, 0.6);
    padding: 2em 3em;
    margin-bottom: 2em;
`

const VacancyListItem = styled.a`
    display: block;
    margin-bottom: 2em;
    
    color: black;
    text-decoration: none;
    
    &:last-child {
        margin-bottom: 0;
    }
    
    & div:first-child {
        font-size: 1.4em;
        font-weight: 700;
        &:hover {
            text-decoration: underline;
        }
    }
    
    & div:last-child {
        
    }
`

const WhatIsItLikeHeader = styled.h1`
    text-align: center;
    color: ${props => props.theme.secondaryColor};
    margin-top: 1em;
`

const WhatIsItLikeList = styled.div`
    padding-bottom: 3em;
    justify-items: center;
    margin: auto;
    @media screen and (min-width: ${({ theme }) => theme.breakpoints.desktopMin}) {
        display: flex;
        justify-content: center;
        gap: 2em;
        flex-wrap: wrap;
    }
`

const WhatIsItLikeListItem = styled.a`
    color: black;
    font-weight: 500;
    text-decoration: none;
    
    &:hover .gatsby-image-wrapper {
        opacity: 0.75;
    }

    .gatsby-image-wrapper {
        height: 100px;
        width: 100px;
    }

    display: flex;
    gap: 1em;
    margin-top: 2em;
    
    @media screen and (min-width: ${({ theme }) => theme.breakpoints.desktopMin}) {
        margin-top: 1em;
        display: unset;
        width: 13em;
        max-width: 13em;
        flex-grow: 1;

        .gatsby-image-wrapper {
            height: 13em;
            width: 13em;
        }
        
        &:first-child {
            margin-left: 0;
        }

        &:last-child {
            margin-right: 0;
        }
    }
`

const WhatIsItLikeListItemName = styled.div`
    font-size: 1em;
    font-weight: 500;
    margin: 0.5em 0px;

`

const WhatIsItLikeTextArea = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
`

const WhatIsItLikeListItemDescription = styled.div`
    font-size: 0.9em;
    font-weight: 300;
    &:last-of-type {
        margin-top: 0.5em !important;
    }
    ${props =>
        props.secondary &&
        css`
      font-size: 0.7em;
      font-weight: 600;
      text-transform: uppercase;
      color: ${props => props.theme.primaryColor};
    `}
`

const HoverState = styled.div`
    height: 3px;
    width: 100%;
    visibility: hidden;
    display: none;
    margin-top: -3px !important;
    border-bottom: 3px solid ${props => props.theme.primaryColor};
    ${WhatIsItLikeListItem}:hover & {
        visibility: visible;
    }
    @media screen and (min-width: ${({ theme }) => theme.breakpoints.desktopMin}) {
        display: block;
    }
`;


const SvgImg = styled.img`
    height: ${props => props.height};
    width: ${props => props.width};
`;

export function ImageSVG({ image, width, height }) {
    if (!image?.childImageSharp && image?.extension === 'svg') {
        return <SvgImg src={image?.publicURL} width={width} height={height} alt="" />
    }
    else {
      return null
    }
}

export const JoinUsPageContent = ({ image, whatIsItLike, contentComponent, whyContent, whyHeader, offerHeader, offerItems, vacancies, explore, looking }) => (



    <React.Fragment>
        <Header image={image} height="10em" />
        <CentralHeader>{whyHeader}</CentralHeader>
        <ContentBlock>
            <StyledContent html={whyContent} contentComponent={contentComponent} />
        </ContentBlock>
        <WideContentBlock>
            <HeaderWithPrimaryLine>{offerHeader}</HeaderWithPrimaryLine>
            <OfferItems>
                {offerItems.map((item, idx) => (
                    <OfferItem key={item.label}>
                        <div style={{ display: 'flex', alignItems: 'center', height: 130 }}><ImageSVG image={item.image} height={idx === 1 || idx === 6 || idx === 7 ? "80px" : "100px"} /></div>
                        <StyledContent html={toHTML(item.label)} contentComponent={contentComponent} />
                    </OfferItem>
                ))}
            </OfferItems>
        </WideContentBlock>

        <ContentBlock>
            <HeaderWithGreyLine>{vacancies.header}</HeaderWithGreyLine>

            <VacanciesList>
                {vacancies.items.map(vacancy => (
                    <VacancyListItem key={vacancy.link} href={vacancy.link}>
                        <div>{vacancy.title}</div>
                        <div>{vacancy.subtitle}</div>
                    </VacancyListItem>
                ))}
            </VacanciesList>

            <ApplyButtonContainer>
                <ApplyButton label="Get In Touch" />
            </ApplyButtonContainer>
        </ContentBlock>

        <WideContentBlock>
            <HeaderWithPrimaryLine>{explore.header}</HeaderWithPrimaryLine>
            <ExploreItems>
                {explore.items.map(item => (
                    <ExploreItem key={item.link} href={item.link}>
                        <ExploreItemLabel>{item.label}</ExploreItemLabel>
                        <PreviewCompatibleImage image={item.image} alt={""} className={""} />
                    </ExploreItem>
                ))}
            </ExploreItems>

        </WideContentBlock>

        <ContentBlock>
            <HeaderWithGreyLine>{looking.header}</HeaderWithGreyLine>
            <LookingDescription>{looking.description}</LookingDescription>
            <LookingItems>
                {looking.items.map(item => (
                    <LookingItem key={item.label}>
                        <LookingItemImage>
                            <PreviewCompatibleImage image={item.image} alt={""} className={""} />
                            <ImageSVG image={item.image} width="50px" />
                        </LookingItemImage>
                        <div>{item.label}</div>
                    </LookingItem>
                ))}
            </LookingItems>
        </ContentBlock>
        <WideContentBlock>
            <LookingBlocks>
                {looking.blocks.map(block => (
                    <LookingBlock key={block.header}>
                        <LookingBlockImage>
                            <PreviewCompatibleImage image={block.image} alt={""} className={""} />
                        </LookingBlockImage>
                        <h4>{block.header}</h4>
                        <div>
                            <StyledContent html={toHTML(block.content)} contentComponent={contentComponent} />
                        </div>
                    </LookingBlock>
                ))}
            </LookingBlocks>
        </WideContentBlock>

        <WideContentBlock>
            <WhatIsItLikeHeader>{whatIsItLike.header}</WhatIsItLikeHeader>
            <WhatIsItLikeList>
                {whatIsItLike.items.map(item => (
                    <WhatIsItLikeListItem key={item.link} href={item.link}>
                        <div style={{ backgroundColor: 'rgba(0, 40, 85, 1)' }}>
                            <PreviewCompatibleImage image={item.image} alt={""} className={""} />
                        </div>
                        <HoverState />
                        <WhatIsItLikeTextArea>
                            <WhatIsItLikeListItemName>{item.name}</WhatIsItLikeListItemName>
                            <WhatIsItLikeListItemDescription>{item.description}</WhatIsItLikeListItemDescription>
                            <WhatIsItLikeListItemDescription secondary>{item.office}</WhatIsItLikeListItemDescription>
                        </WhatIsItLikeTextArea>
                    </WhatIsItLikeListItem>

                ))}
            </WhatIsItLikeList>
        </WideContentBlock>
    </React.Fragment >
);

JoinUsPageContent.propTypes = {
    image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    html: PropTypes.node,
    whyContent: PropTypes.node,
    whyHeader: PropTypes.string,
    contentComponent: PropTypes.func,
    offerHeader: PropTypes.string,
    offerItems: PropTypes.object,
    vacancies: PropTypes.object,
    explore: PropTypes.object,
    looking: PropTypes.object,
    whatIsItLike: PropTypes.object,
};

const JoinUsPageTemplate = ({
    html,
    image,
    heading,
    seo,
    location,
    whyContent,
    whyHeader,
    offerHeader,
    offerItems,
    vacancies,
    explore,
    looking,
    whatIsItLike
}) => (
    <Layout location={location}>
        <SEO
            title={heading}
            description={seo.metaDescription}
            keywords={seo.focusKeyword}
        />
        <DarkBg>
            <JoinUsPageContent
                image={image}
                html={html}
                contentComponent={HTMLContent}
                whyContent={whyContent}
                whyHeader={whyHeader}
                offerHeader={offerHeader}
                offerItems={offerItems}
                vacancies={vacancies}
                explore={explore}
                looking={looking}
                whatIsItLike={whatIsItLike}
            />

        </DarkBg>
    </Layout>
);

JoinUsPageTemplate.defaultProps = {
    articlesWidget: false,
    groupWidget: false
};

JoinUsPageTemplate.propTypes = {
    articlesWidget: PropTypes.bool,
    groupWidget: PropTypes.bool,
    html: PropTypes.node,
    image: PropTypes.object,
    heading: PropTypes.string.isRequired,
    seo: PropTypes.shape({
        metaDescription: PropTypes.string,
        focusKeyword: PropTypes.string
    }),
    location: PropTypes.string.isRequired,
    whyContent: PropTypes.string,
    whyHeader: PropTypes.string,
    offerHeader: PropTypes.string,
    offerItems: PropTypes.object,
    vacancies: PropTypes.object,
    explore: PropTypes.object,
    looking: PropTypes.object,
    whatIsItLike: PropTypes.object,
};

export default JoinUsPageTemplate;
