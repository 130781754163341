import React from 'react';
import { Helmet } from 'react-helmet';
import { useStaticQuery, graphql } from 'gatsby';
import { getEnvironment } from 'src/utils';

function HeadContent() {
  const environment = getEnvironment();
  const shouldRender =
    environment === 'development' || environment === 'production';

  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            googleAnalyticsId
            cookieBotId
          }
        }
      }
    `
  );

  if (!shouldRender) {
    return null;
  }

  return (
    <Helmet>
      <script
        async
        src={`https://www.googletagmanager.com/gtag/js?id=${site.siteMetadata.googleAnalyticsId}`}
      ></script>
      {/* Cookiebot settings from */}
      {/* https://support.cookiebot.com/hc/en-us/articles/360003979074-Using-Google-Gtag-with-Cookiebot */}
      <script>
        {`
          window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('consent', 'default', {ad_storage:'denied', analytics_storage:'denied'});
          gtag('set', 'ads_data_redaction', true);
          gtag('set', 'url_passthrough', true);
          gtag('js', new Date());
          gtag('config', '${site.siteMetadata.googleAnalyticsId}');
        `}
      </script>
      <script
        id="Cookiebot"
        src="https://consent.cookiebot.com/uc.js"
        data-cbid={site.siteMetadata.cookieBotId}
        type="text/javascript"
        async
      />
    </Helmet>
  );
}

export default HeadContent;
