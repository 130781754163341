/* eslint-disable no-console */

import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';

import ArticlesWidget from 'src/components/widgets/ArticlesWidget/ArticlesWidget';
import Layout from 'src/components/Layout';
import SEO from 'src/components/SEO';
import TeamPageContent from 'src/components/TeamPageContent';

export const TeamPageTemplate = ({
  location,
  seo,
  articlesWidget,
  heading,
  subheading,
  image,
  initialProfile,
  initialCity,
  profiles,
  offices,
  roles,
  sectors,
  profilesIndex,
  profilesStore
}) => (
  <Layout location={location}>
    <SEO
      title={heading}
      description={seo.metaDescription}
      keywords={seo.focusKeyword}
    />
    <TeamPageContent
      initialProfile={initialProfile}
      initialCity={initialCity}
      heading={heading}
      subheading={subheading}
      image={image}
      profiles={profiles}
      offices={offices}
      roles={roles}
      sectors={sectors}
      profilesIndex={profilesIndex}
      profilesStore={profilesStore}
    />

    {articlesWidget && <ArticlesWidget />}
  </Layout>
);

TeamPageTemplate.defaultProps = {
  articlesWidget: false
};

TeamPageTemplate.propTypes = {
  articlesWidget: PropTypes.bool,
  initialProfile: PropTypes.object,
  initialCity: PropTypes.string,
  profilesIndex: PropTypes.string,
  profilesStore: PropTypes.object,
  heading: PropTypes.string.isRequired,
  subheading: PropTypes.string,
  image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  seo: PropTypes.shape({
    metaDescription: PropTypes.string,
    focusKeyword: PropTypes.string
  }),
  profiles: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      position: PropTypes.string.isRequired,
      office: PropTypes.string.isRequired,
      picture: PropTypes.object,
      description: PropTypes.string.isRequired,
      contactDetails: PropTypes.shape({
        email: PropTypes.string,
        phone: PropTypes.string
      }),
      roles: PropTypes.arrayOf(PropTypes.object),
      sectors: PropTypes.arrayOf(PropTypes.object),
      articles: PropTypes.arrayOf(PropTypes.object)
    })
  ),
  location: PropTypes.string.isRequired,
  offices: PropTypes.arrayOf(PropTypes.object),
  roles: PropTypes.arrayOf(PropTypes.object),
  sectors: PropTypes.arrayOf(PropTypes.object)
};

export default TeamPageTemplate;

export const TeamPageQuery = graphql`
  fragment TeamPageContent on MarkdownRemark {
    frontmatter {
      articlesWidget
      heading
      subheading
      image {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
      seo {
        metaDescription
        focusKeyword
      }
    }
  }
`;

export const OfficesQuery = graphql`
  fragment OfficesGroups on MarkdownRemarkConnection {
    group(field: frontmatter___region) {
      fieldValue
      edges {
        node {
          frontmatter {
            city
          }
          fields {
            namekey
          }
        }
      }
    }
  }
`;

export const WhatWeDoCategoriesQuery = graphql`
  fragment WhatWeDoCategories on MarkdownRemark {
    frontmatter {
      name
      category
    }
  }
`;
