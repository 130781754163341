import React from 'react';
import PropTypes from 'prop-types';
import { LinkedinShareButton } from 'react-share';

import Icon from './Icon';

const LinkedInIcon = ({ url, icon }) => (
  <LinkedinShareButton url={url}>
    <Icon icon={icon} tooltip="Share on LinkedIn" />
  </LinkedinShareButton>
);

LinkedInIcon.propTypes = {
  url: PropTypes.string.isRequired,
  icon: PropTypes.oneOfType([PropTypes.object, PropTypes.string])
};

export default LinkedInIcon;
