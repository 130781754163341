import { useState, useEffect } from 'react';
import { navigate } from 'gatsby';
import { useDebounce } from 'use-debounce';
import { useFlexSearch } from 'react-use-flexsearch';

export const doSearch = (query, results, office, role, sector, profiles) => {
  const profileIds = results.map(result => result.id);

  const filteredProfiles = query
    ? profiles.filter(data => profileIds.indexOf(data.id) !== -1)
    : profiles;

  // Get profiles filtered by office, role and sector
  return filterResults(filteredProfiles, office, role, sector);
};

export const filterResults = (profiles, office, role, sector) => {
  // Get profiles filtered by office, role and sector
  const resultsProfiles = profiles
    .filter(data => !office || data.officeSlug === office)
    .filter(
      data =>
        !role ||
        (data.roles &&
          data.roles.filter(item => item.name === role).length !== 0)
    )
    .filter(
      data =>
        !sector ||
        (data.sectors &&
          data.sectors.filter(item => item.name === sector).length !== 0)
    );

  return resultsProfiles;
};

export const useSearch = ({
  initialCity,
  profiles,
  profilesIndex,
  profilesStore
}) => {
  const [query, setQuery] = useState('');
  const [queryDebounced] = useDebounce(query, 100);
  const [office, setOffice] = useState(initialCity ? initialCity : '');
  const [role, setRole] = useState('');
  const [sector, setSector] = useState('');
  const [results, setResults] = useState(profiles);

  const queryResults = useFlexSearch(query, profilesIndex, profilesStore);

  const clearFilters = () => {
    setQuery('');
    setOffice('');
    setRole('');
    setSector('');
    navigate('/team/');
  };

  useEffect(() => {
    handleSearch();
  }, [queryDebounced, office, role, sector]);

  const handleSearch = () => {
    setResults(doSearch(query, queryResults, office, role, sector, profiles));
  };

  return {
    office,
    setOffice,
    role,
    setRole,
    sector,
    setSector,
    query,
    setQuery,
    results,
    clearFilters
  };
};
