import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import LinkedInIcon from 'src/components/icons/LinkedInIcon';
import FacebookIcon from 'src/components/icons/FacebookIcon';
import TwitterIcon from 'src/components/icons/TwitterIcon';
import WeblinkIcon from 'src/components/icons/WeblinkIcon';

const Icon = styled.div`
  margin-right: 21px;
`;

const IconWidget = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
`;

const ShareText = styled.div`
  color: ${props => props.theme.primaryColor};
  font-weight: 500;
  font-size: 24px;
  text-align: center;
  margin-bottom: 1em;
`;

const HorizontalSocialMediaWidgetView = ({
  url,
  linkedInIcon,
  facebookIcon,
  twitterIcon,
  weblinkIcon
}) => (
  <div>
    <ShareText>Share this</ShareText>
    <IconWidget>
      {linkedInIcon && (
        <Icon>
          <LinkedInIcon url={url} icon={linkedInIcon} />
        </Icon>
      )}
      {facebookIcon && (
        <Icon>
          <FacebookIcon url={url} icon={facebookIcon} />
        </Icon>
      )}
      {twitterIcon && (
        <Icon>
          <TwitterIcon url={url} icon={twitterIcon} />
        </Icon>
      )}
      {weblinkIcon && (
        <Icon style={{ marginRight: '0px' }}>
          <WeblinkIcon url={url} icon={weblinkIcon} />
        </Icon>
      )}
    </IconWidget>
  </div>
);

HorizontalSocialMediaWidgetView.propTypes = {
  url: PropTypes.string,
  linkedInIcon: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  facebookIcon: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  twitterIcon: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  weblinkIcon: PropTypes.oneOfType([PropTypes.object, PropTypes.string])
};

export default HorizontalSocialMediaWidgetView;
