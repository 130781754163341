import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import Tag, { ActiveTagLink } from 'src/components/Tag';

const FilterText = styled.span``;

const Tags = styled.span`
  margin-left: 1em;
`;

export const CategoryFilter = ({ text, categories, link }) => (
  <React.Fragment>
    <FilterText>{text}</FilterText>
    <Tags>
      <ActiveTagLink to={link + '/'}>
        <Tag rounded>All</Tag>
      </ActiveTagLink>
      {categories.map((category, index) => {
        return (
          <ActiveTagLink
            key={index}
            to={link + '/' + category.toLowerCase() + '/'}
          >
            <Tag rounded>{category}</Tag>
          </ActiveTagLink>
        );
      })}
    </Tags>
  </React.Fragment>
);

CategoryFilter.propTypes = {
  text: PropTypes.string.isRequired,
  link: PropTypes.string.isRequired,
  categories: PropTypes.arrayOf(PropTypes.string)
};
