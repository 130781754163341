import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import { CenteredBlock, H2, P } from 'src/components/Block';
import { PreviewCompatibleLink } from 'src/components/PreviewCompatible';

const Link = styled(PreviewCompatibleLink)`
  color: ${props => props.theme.primaryColor};
`;

const EmailLink = styled.a`
  color: ${props => props.theme.secondaryColor};
`;

const Block = styled(CenteredBlock)`
  padding: 4em 1em 2em;
  @media screen and (min-width: ${({ theme }) =>
      theme.breakpoints.desktopMin}) {
    padding: 4em 10em 2em;
  }
`;

const H6 = styled.h6`
  font-size: 2em;
  font-weight: 300;
  margin: 1em 0;
  color: ${props => props.theme.primaryColor};
`;

const SmallText = styled(P)`
  font-size: 0.9em;
  @media screen and (min-width: ${({ theme }) =>
      theme.breakpoints.desktopMin}) {
    max-width: 600px;
    margin: 0 auto;
  }
`;

const ContactText = styled(P)`
  font-weight: 500;
`;

const List = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
`;

const Item = styled.li`
  margin: 0 10px 10px 0;
  font-size: 0.8em;
  font-weight: 400;
  &:hover {
    a {
      color: black;
    }
  }
  a {
    text-decoration: none;
    color: snow;
  }
`;

const ItemContent = styled.div`
  width: 150px;
  text-align: left;
  border: 1px solid ${props => props.theme.primaryColor};
  border-radius: 3px;
  &:hover {
    background-color: ${props => props.theme.primaryColor};
  }
`;

const Job = styled(P)`
  text-align: center;
  margin: 0 0 0.5em 0;
  font-size: 1.1em;
  font-weight: 300;
`;

const Location = styled(P)`
  text-align: center;
  text-transform: uppercase;
  font-size: 0.8em;
  font-weight: 500;
  margin: 0;
`;

const Details = styled.div`
  background-color: ${props => props.theme.primaryColor};
  color: ${props => props.theme.secondaryColor};
  text-align: center;
  padding: 0.5em;
  text-decoration: underline;
`;

const TextContent = styled.div`
  margin: 10px;
`;

// Number of jobs to display
const NUM_JOBS = 3;

const CareersWidgetView = ({
  jobs,
  careers,
  vacancies,
  contact,
  email,
  alwaysShow
}) => (
  <React.Fragment>
    <Block>
      <H2>{careers.title}</H2>
      <SmallText>{careers.description}</SmallText>
      {jobs.length === 0 && alwaysShow && (
        <ContactText>
          {contact} <EmailLink href={'mailto:' + email}>{email}</EmailLink>
        </ContactText>
      )}
      {jobs.length !== 0 && (
        <React.Fragment>
          <H6>{vacancies.title}</H6>
          <List>
            {jobs.slice(0, NUM_JOBS).map((data, index) => {
              return (
                <Item key={index}>
                  <Link to={data.slug}>
                    <ItemContent>
                      <TextContent>
                        <Job>{data.position}</Job>
                        <Location>{data.location}</Location>
                      </TextContent>
                      <Details>Details</Details>
                    </ItemContent>
                  </Link>
                </Item>
              );
            })}
            {jobs.length > NUM_JOBS && (
              <Item>
                <Link to="/about-us/vacancies/">
                  <ItemContent>
                    <TextContent>
                      <Job>{jobs.length - NUM_JOBS} more…</Job>
                      <Location>Various</Location>
                    </TextContent>
                    <Details>View all</Details>
                  </ItemContent>
                </Link>
              </Item>
            )}
          </List>
        </React.Fragment>
      )}
    </Block>
  </React.Fragment>
);

CareersWidgetView.defaultProps = {
  alwaysShow: false
};

CareersWidgetView.propTypes = {
  jobs: PropTypes.arrayOf(
    PropTypes.shape({
      position: PropTypes.string,
      location: PropTypes.string,
      slug: PropTypes.string
    })
  ).isRequired,
  careers: PropTypes.shape({
    title: PropTypes.string,
    description: PropTypes.string
  }).isRequired,
  vacancies: PropTypes.shape({
    title: PropTypes.string,
    link: PropTypes.string
  }).isRequired,
  contact: PropTypes.string,
  email: PropTypes.string,
  alwaysShow: PropTypes.bool
};

export default CareersWidgetView;
