import React from 'react';
import PropTypes from 'prop-types';

import Layout from 'src/components/Layout';
import SEO from 'src/components/SEO';
import ArticlesWidget from 'src/components/widgets/ArticlesWidget/ArticlesWidget';
import { CategoryFilter } from 'src/components/Filter';
import { FilterBlock } from 'src/components/Block';
import OfficeItem from 'src/components/OfficeItem';
import { RegionTitle, List, Item } from 'src/components/Office';
import ContactForm from 'src/components/ContactForm';

const OfficeLocation = ({ region, cities }) => (
  <>
    <RegionTitle>{region}</RegionTitle>
    {cities
      .filter(office => office.publish)
      .map((office, index) => {
        return (
          <Item key={index}>
            <OfficeItem
              primary={index % 2 !== 0}
              data={office}
              region={region}
            />
          </Item>
        );
      })}
  </>
);

OfficeLocation.propTypes = {
  region: PropTypes.string.isRequired,
  cities: PropTypes.arrayOf(
    PropTypes.shape({
      city: PropTypes.string.isRequired,
      address: PropTypes.string.isRequired,
      phone: PropTypes.string,
      candidateEmail: PropTypes.string.isRequired,
      clientEmail: PropTypes.string.isRequired,
      image: PropTypes.object
    })
  )
};

const ContactPageTemplate = ({
  articlesWidget,
  heading,
  seo,
  email,
  location,
  message,
  linkedin,
  firstname,
  lastname,
  calltoaction,
  title,
  offices,
  officesGroups,
  office,
  regions,
  path
}) => {
  return (
    <Layout location={path} dark>
      <SEO
        title={office ? office.city : heading}
        description={seo.metaDescription}
        keywords={seo.focusKeyword}
      />
      {!office && (
        <FilterBlock>
          <CategoryFilter
            text="Filter locations:"
            categories={regions}
            link="/contact-us"
          />
        </FilterBlock>
      )}
      <List>
        {officesGroups &&
          officesGroups.map((group, groupIndex) => {
            return (
              <div key={groupIndex}>
                <OfficeLocation region={group.region} cities={group.cities} />
              </div>
            );
          })}
        {!officesGroups && office && (
          <Item>
            <OfficeItem data={office} region={office.region} />
          </Item>
        )}
      </List>
      <ContactForm
        title={title}
        email={email}
        message={message}
        linkedin={linkedin}
        location={location}
        firstname={firstname}
        lastname={lastname}
        calltoaction={calltoaction}
        offices={offices}
      />

      {articlesWidget && <ArticlesWidget />}
    </Layout>
  );
};

ContactPageTemplate.defaultProps = {
  articlesWidget: false
};

ContactPageTemplate.propTypes = {
  articlesWidget: PropTypes.bool,
  heading: PropTypes.string.isRequired,
  seo: PropTypes.shape({
    metaDescription: PropTypes.string,
    focusKeyword: PropTypes.string
  }),
  email: PropTypes.string.isRequired,
  location: PropTypes.string.isRequired,
  message: PropTypes.string,
  firstname: PropTypes.string,
  lastname: PropTypes.string,
  linkedin: PropTypes.string,
  calltoaction: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  officesGroups: PropTypes.arrayOf(
    PropTypes.shape({
      region: PropTypes.string.isRequired,
      cities: PropTypes.arrayOf(
        PropTypes.shape({
          city: PropTypes.string.isRequired,
          address: PropTypes.string.isRequired,
          phone: PropTypes.string,
          candidateEmail: PropTypes.string.isRequired,
          clientEmail: PropTypes.string.isRequired,
          image: PropTypes.object
        })
      )
    })
  ),
  office: PropTypes.shape({
    city: PropTypes.string.isRequired,
    address: PropTypes.string.isRequired,
    phone: PropTypes.string,
    region: PropTypes.string,
    candidateEmail: PropTypes.string.isRequired,
    clientEmail: PropTypes.string.isRequired,
    image: PropTypes.object
  }),
  offices: PropTypes.arrayOf(
    PropTypes.shape({
      city: PropTypes.string.isRequired
    })
  ),
  regions: PropTypes.arrayOf(PropTypes.string),
  path: PropTypes.string.isRequired
};

export default ContactPageTemplate;
