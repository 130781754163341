import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';

import ReportPageTemplate from 'src/components/pages/ReportPageTemplate';

const ReportPage = ({ data, location }) => {
  const { frontmatter, html, fields } = data.markdownRemark;

  return (
    <ReportPageTemplate
      heading={frontmatter.heading}
      subheading={frontmatter.subheading}
      document={frontmatter.document}
      image={frontmatter.image}
      html={html}
      seo={frontmatter.seo}
      calltoaction={frontmatter.calltoaction}
      firstname={frontmatter.firstname}
      lastname={frontmatter.lastname}
      company={frontmatter.company}
      job={frontmatter.job}
      email={frontmatter.email}
      terms={frontmatter.terms}
      showNav={frontmatter.showNav}
      options={frontmatter.options}
      path={location.pathname}
      slug={fields.slug}
    />
  );
};

ReportPage.propTypes = {
  data: PropTypes.object,
  location: PropTypes.object
};

export default ReportPage;

export const pageQuery = graphql`
  query ReportPageTemplate($id: String!) {
    markdownRemark(
      frontmatter: { templateKey: { eq: "report" } }
      id: { eq: $id }
    ) {
      html
      fields {
        slug
      }
      frontmatter {
        heading
        subheading
        document {
          name
          publicURL
        }
        image {
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH)
          }
        }
        seo {
          metaDescription
          focusKeyword
        }
        calltoaction
        firstname
        lastname
        email
        company
        job
        terms
        showNav
        options {
          name
          text
          required
        }
      }
    }
  }
`;
