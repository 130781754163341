import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';

import VacanciesPageTemplate from 'src/components/pages/VacanciesPageTemplate';

const JoinPage = ({ data, location }) => {
  const { frontmatter } = data.markdownRemark;
  const vacancies = data.vacancies.edges.map(item =>
    Object.assign({}, item.node.frontmatter, { slug: item.node.fields.slug })
  );

  return (
    <VacanciesPageTemplate
      articlesWidget={frontmatter.articlesWidget}
      image={frontmatter.image}
      heading={frontmatter.heading}
      subheading={frontmatter.subheading}
      seo={frontmatter.seo}
      metaVacancies={frontmatter.vacancies}
      vacancies={vacancies}
      location={location.pathname}
    />
  );
};

JoinPage.propTypes = {
  data: PropTypes.object,
  location: PropTypes.object
};

export default JoinPage;

export const pageQuery = graphql`
  query JoinPageTemplate {
    markdownRemark(
      frontmatter: { templateKey: { eq: "about-us-vacancies-page" } }
    ) {
      frontmatter {
        articlesWidget
        image {
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH)
          }
        }
        heading
        subheading
        seo {
          metaDescription
          focusKeyword
        }
        vacancies {
          calltoaction
        }
      }
    }
    vacancies: allMarkdownRemark(
      filter: {
        frontmatter: { templateKey: { eq: "vacancy" }, publish: { eq: true } }
      }
    ) {
      edges {
        node {
          fields {
            slug
          }
          frontmatter {
            position
            location
            description
          }
        }
      }
    }
  }
`;
