/* eslint-disable no-console */

import React from 'react'
import PropTypes from 'prop-types'
import {graphql} from 'gatsby'

import CaseStudyPageTemplate from 'src/components/pages/CaseStudyPageTemplate'
import {toHTML} from 'src/utils'

const CaseStudyPage = ({data, pageContext, location}) => {
  const {caseStudyData} = data ?? {}
  const {frontmatter: caseStudy, html} = caseStudyData
  const {next, prev} = pageContext
  const {peopleTags, rolesTags, sectorsTags} = caseStudy

  // console.log('caseStudy', {caseStudy})

  return (
    <CaseStudyPageTemplate
      stats={caseStudy.stats ? toHTML(caseStudy.stats) : null}
      articlesWidget={caseStudy.articlesWidget}
      title={caseStudy.title}
      area={caseStudy.location}
      seo={caseStudy.seo}
      image={caseStudy.image}
      {...{html, prev, next, location, peopleTags, rolesTags, sectorsTags}}
    />
  )
}

CaseStudyPage.propTypes = {
  data: PropTypes.object,
  pageContext: PropTypes.object,
  location: PropTypes.object
}

export default CaseStudyPage

export const pageQuery = graphql`
  query CaseStudyByName($id: String!) {
    caseStudyData: markdownRemark(
      frontmatter: { templateKey: { eq: "casestudy" } }
      id: { eq: $id }
    ) {
      html
      frontmatter {
        articlesWidget
        title
        location
        seo {
          metaDescription
          focusKeyword
        }
        description
        peopleTags
        rolesTags
        sectorsTags
        stats
        image {
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH)
          }
        }
      }
    }   
  }
`;
