import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Sticky from 'react-stickynode';

import MobileMenu from 'src/components/Header/MobileMenu';
import DesktopMenu from 'src/components/Header/DesktopMenu';
import { headerShadow } from 'src/components/Block';
import { PreviewCompatibleLink } from 'src/components/PreviewCompatible';

const HeaderWrapper = styled.header`
  ${headerShadow};
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 10px;
  position: relative;
  background-color: white;
  z-index: 1;
  border-bottom: 1px solid ${props => props.theme.navBorder};
`;

const LogoLink = styled(PreviewCompatibleLink)`
  font-size: 0;
  margin-left: 15px;
`;

const Header = ({
  location,
  services,
  roles,
  sectors,
  showDropdown,
  setShowDropdown,
  showArticles,
  showCaseStudies
}) => {
  /**
   * Both menus need to be rendered because
   * of `gatsby build` processing
   */
  return (
    <Sticky innerZ={2}>
      <HeaderWrapper id="header">
        <LogoLink to="/">
          <img src="/img/logo-h.png" style={{ width: '200px' }} />
        </LogoLink>
        <DesktopMenu
          showDropdown={showDropdown}
          setShowDropdown={setShowDropdown}
          services={services}
          roles={roles}
          sectors={sectors}
          showArticles={showArticles}
          showCaseStudies={showCaseStudies}
        />
        <MobileMenu
          location={location}
          showDropdown={showDropdown}
          setShowDropdown={setShowDropdown}
          showArticles={showArticles}
          showCaseStudies={showCaseStudies}
        />
      </HeaderWrapper>
    </Sticky>
  );
};

Header.propTypes = {
  location: PropTypes.string.isRequired,
  services: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      path: PropTypes.string
    })
  ).isRequired,
  roles: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      path: PropTypes.string
    })
  ).isRequired,
  sectors: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      path: PropTypes.string
    })
  ).isRequired,
  showDropdown: PropTypes.string,
  setShowDropdown: PropTypes.func.isRequired,
  showArticles: PropTypes.bool,
  showCaseStudies: PropTypes.bool
};

export default Header;
