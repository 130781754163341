/* eslint-disable no-console, react/prop-types */

import React from 'react';
import PropTypes from 'prop-types';

import ArticlesWidget from 'src/components/widgets/ArticlesWidget/ArticlesWidget';
import Layout from 'src/components/Layout';
import SEO from 'src/components/SEO';
import {
  PageBlock,
  ListContainer,
  ListBlock,
  ListContent,
  ListLink
} from 'src/components/PageBlock';
import { getAltImage } from 'src/utils';

export const Content = ({ casestudies, heading, subheading }) => (
  <PageBlock>
    <ListContainer>
      <ListBlock>
        <ListContent data={{ title: heading, text: subheading }} />
      </ListBlock>
      {casestudies.map((data, index) => {
        return (
          <ListBlock key={index} border>
            <ListLink
              data={data}
              tag="Case Study"
              alt={getAltImage('casestudy', data)}
            />
          </ListBlock>
        );
      })}
    </ListContainer>
  </PageBlock>
);

Content.propTypes = {
  heading: PropTypes.string.isRequired,
  subheading: PropTypes.string.isRequired,
  casestudies: PropTypes.arrayOf(
    PropTypes.shape({
      image: PropTypes.object,
      title: PropTypes.string.isRequired,
      location: PropTypes.string
    })
  )
};

export const PortfolioListTemplate = ({casestudies}) => (
  <ListContainer>
    {casestudies.map((data, index) => {
      return (
        <ListBlock key={index} border>
          <ListLink
            data={data}
            tag="Case Study"
            alt={getAltImage('casestudy', data)}
          />
        </ListBlock>
      );
    })}
</ListContainer>
)

const PortfolioPageTemplate = ({
  heading,
  subheading,
  seo,
  casestudies,
  location,
  articlesWidget
}) => (
  <Layout location={location}>
    <SEO
      title={heading}
      description={seo.metaDescription}
      keywords={seo.focusKeyword}
    />
    <Content
      casestudies={casestudies}
      heading={heading}
      subheading={subheading}
    />
    {articlesWidget && <ArticlesWidget />}
  </Layout>
);

PortfolioPageTemplate.defaultProps = {
  articlesWidget: false
};

PortfolioPageTemplate.propTypes = {
  articlesWidget: PropTypes.bool,
  heading: PropTypes.string.isRequired,
  subheading: PropTypes.string.isRequired,
  seo: PropTypes.shape({
    metaDescription: PropTypes.string,
    focusKeyword: PropTypes.string
  }),
  casestudies: PropTypes.arrayOf(
    PropTypes.shape({
      image: PropTypes.object,
      title: PropTypes.string.isRequired,
      location: PropTypes.string
    })
  ),
  location: PropTypes.string.isRequired
};

export default PortfolioPageTemplate;
