import React from 'react';
import PropTypes from 'prop-types';
import { CenteredBlock, H1, P } from 'src/components/Block';

import Layout from 'src/components/Layout';
import SEO from 'src/components/SEO';

const NotFoundPage = ({ location }) => (
  <Layout location={location.pathname}>
    <SEO title="404: Not found" />
    <CenteredBlock>
      <H1>NOT FOUND</H1>
      <P>Page not found :(</P>
    </CenteredBlock>
  </Layout>
);

NotFoundPage.propTypes = {
  location: PropTypes.object
};

export default NotFoundPage;
