import React from 'react';
import PropTypes from 'prop-types';

import { ContentBlock, LimitedWidth } from 'src/components/Block';
import Page from 'src/components/Page';

const PageTemplate = ({ title, html, contentComponent }) => {
  return (
    <LimitedWidth>
      <ContentBlock>
        <Page title={title} html={html} contentComponent={contentComponent} />
      </ContentBlock>
    </LimitedWidth>
  );
};

PageTemplate.propTypes = {
  title: PropTypes.string.isRequired,
  contentComponent: PropTypes.func,
  html: PropTypes.string
};

export default PageTemplate;
