import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import Layout from 'src/components/Layout';
import SEO from 'src/components/SEO';
import BodyContent from 'src/components/BodyContent';
import { HTMLContent } from 'src/components/Content';
import ArticlesWidget from 'src/components/widgets/ArticlesWidget/ArticlesWidget';
import {
  Header,
  HeaderTitle,
  HeaderText,
  ContentBlock
} from 'src/components/Block';

const Page = styled.div`
  padding: 1em;
  @media screen and (min-width: ${({ theme }) =>
      theme.breakpoints.desktopMin}) {
    padding: 2em 5em;
  }
`;

export const PageContent = ({
  image,
  position,
  location,
  description,
  html,
  contentComponent
}) => (
  <React.Fragment>
    <Header image={image}>
      <HeaderTitle>
        {position} – {location}
      </HeaderTitle>
      <HeaderText>{description}</HeaderText>
    </Header>
    <Page>
      <ContentBlock>
        <BodyContent html={html} contentComponent={contentComponent} />
      </ContentBlock>
    </Page>
  </React.Fragment>
);

PageContent.propTypes = {
  image: PropTypes.object,
  position: PropTypes.string.isRequired,
  location: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  html: PropTypes.node,
  contentComponent: PropTypes.func
};

export const VacancyPageTemplate = ({
  image,
  position,
  location,
  description,
  html,
  path,
  articlesWidget
}) => (
  <Layout location={path.pathname}>
    <SEO title={position} />
    <PageContent
      image={image}
      position={position}
      location={location}
      description={description}
      html={html}
      contentComponent={HTMLContent}
    />
    {articlesWidget && <ArticlesWidget />}
  </Layout>
);

VacancyPageTemplate.defaultProps = {
  articlesWidget: false
};

VacancyPageTemplate.propTypes = {
  articlesWidget: PropTypes.bool,
  image: PropTypes.object,
  position: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  location: PropTypes.string.isRequired,
  html: PropTypes.string.isRequired,
  path: PropTypes.object.isRequired
};

export default VacancyPageTemplate;
