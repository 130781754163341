const theme = {
  primaryColor: '#ff9da2',
  navColor: '#ffebec',
  secondaryColor: '#002855',
  tertiaryColor: '#eae8e4',
  breadcrumbBgColor: 'rgba(217, 217, 214, 0.3)',
  breadcrumbTextColor: 'rgba(16, 24, 32, 0.4)',
  textColor: 'rgb(16, 24, 32)',
  accentColor: '#F33E4B',
  borderColor: '#D7D2CB',
  inputColor: 'rgb(217, 217, 214)',
  bgColor: 'rgba(193, 178, 182, 0.2)',
  bgDarkColor: '#0C2340',
  tagBorderColor: '#C1B2B6',
  navBorder: '#FF9DA2',
  navActive: '#F33E4B',
  darken: 'rgba(0, 40, 85, 0.9)',
  darkenLight: 'rgba(0, 40, 85, 0.2)',
  breakpoints: {
    desktopMin: '768px',
    small: '480px'
  }
};

export default theme;
