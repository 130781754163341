import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';

import TestimonialsWidgetView from 'src/components/widgets/TestimonialsWidget/TestimonialsWidgetView';

const TestimonialsWidget = () => {
  const data = useStaticQuery(graphql`
    query TestimonialsWidgetQuery {
      widget: markdownRemark(
        frontmatter: { templateKey: { eq: "testimonials-widget" } }
      ) {
        frontmatter {
          image {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH)
            }
          }
        }
      }
      testimonials: allMarkdownRemark(
        filter: {
          frontmatter: {
            templateKey: { eq: "testimonial" }
            publish: { eq: true }
          }
        }
      ) {
        edges {
          node {
            html
            frontmatter {
              author
              title
              organization
            }
          }
        }
      }
    }
  `);

  const testimonials = data.testimonials.edges.map(item =>
    Object.assign({}, item.node.frontmatter, { body: item.node.html })
  );

  return (
    <TestimonialsWidgetView
      testimonials={testimonials}
      image={data.widget.frontmatter.image}
    />
  );
};

export default TestimonialsWidget;
