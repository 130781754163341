/* eslint-disable */
// TODO(mihail): disable children validation and double negation
import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';

const PreviewCompatibleLink = ({ to, basicColor, children, ...props }) => {
  if (typeof window !== 'undefined') {
    if (!!window.CMS_PREVIEW) {
      return (
        <a href={to} {...props}>
          {children}
        </a>
      );
    }
  }
  return (
    <Link to={to} {...props}>
      {children}
    </Link>
  );
};

PreviewCompatibleLink.propTypes = {
  to: PropTypes.string.isRequired,
  basicColor: PropTypes.bool
};

export default PreviewCompatibleLink;
