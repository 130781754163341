import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

import { NavLinkWrapper } from 'src/components/Block';

const DropdownWrapper = styled.div`
  ${props =>
    props.large &&
    css`
      width: 100%;
      padding-bottom: 20px;
    `}
  min-width: 18em;
  z-index: 100;
  background-color: ${props => props.theme.navColor};
  float: left;
  position: absolute;
  left: 0;
  display: ${props => (props.showDropdown ? 'flex' : 'none')};
  box-shadow: 0px 10px 20px -10px rgba(0, 0, 0, 0.5);
  flex-direction: row;
  padding-top: 15px;
`;

const Dropdown = styled.div`
  position: relative;
  &:hover {
    ${DropdownWrapper} {
      display: flex;
    }
    ${NavLinkWrapper} {
      background-color: ${props => props.theme.navColor};
    }
  }
`;

const handleMouseEnter = (
  setShowDropdown,
  hideTimer,
  showTimer,
  setShowTimer
) => {
  clearTimeout(hideTimer);
  clearTimeout(showTimer);
  setShowTimer(setTimeout(() => setShowDropdown(true), 100));
};

const handleMouseLeave = (
  setShowDropdown,
  hideTimer,
  showTimer,
  setHideTimer
) => {
  clearTimeout(hideTimer);
  clearTimeout(showTimer);
  setHideTimer(setTimeout(() => setShowDropdown(false), 100));
};

const HoverMenu = ({
  children,
  content,
  showDropdown,
  setShowDropdown,
  large
}) => {
  const [hideTimer, setHideTimer] = useState(null);
  const [showTimer, setShowTimer] = useState(null);

  /**
   * we stop propagation to detect clicks outside
   * the dropdown in parent elements
   */
  return (
    <Dropdown
      onClick={e => e.stopPropagation()}
      onMouseEnter={() =>
        handleMouseEnter(setShowDropdown, hideTimer, showTimer, setShowTimer)
      }
      onMouseLeave={() =>
        handleMouseLeave(setShowDropdown, hideTimer, showTimer, setHideTimer)
      }
    >
      {children}
      <DropdownWrapper showDropdown={showDropdown} large={large}>
        {content}
      </DropdownWrapper>
    </Dropdown>
  );
};

HoverMenu.propTypes = {
  children: PropTypes.node,
  content: PropTypes.node,
  large: PropTypes.bool,
  showDropdown: PropTypes.bool.isRequired,
  setShowDropdown: PropTypes.func
};

export default HoverMenu;
