import React from 'react';
import PropTypes from 'prop-types';

import { PageContent, BigH1 } from 'src/components/Block';
import BodyContent from 'src/components/BodyContent';

const Page = ({ title, html, contentComponent }) => {
  return (
    <React.Fragment>
      <BigH1>{title}</BigH1>
      <PageContent>
        <BodyContent html={html} contentComponent={contentComponent} />
      </PageContent>
    </React.Fragment>
  );
};

Page.propTypes = {
  title: PropTypes.string.isRequired,
  contentComponent: PropTypes.func,
  html: PropTypes.string
};

export default Page;
