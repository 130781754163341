import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';

import ManagementPageTemplate from 'src/components/pages/ManagementPageTemplate';
import { getProfile, usePathToProfile } from 'src/utils';

const ManagementPage = ({ data, location }) => {
  const { frontmatter } = data.markdownRemark;

  const profiles = data.profiles.edges.map(item => getProfile(item.node));

  const { initialProfile } = usePathToProfile(location, data.profile, profiles);
  const categories = [
    'executive-team',
    'practice-leaders',
    'corporate-functions-directors'
  ];

  return (
    <ManagementPageTemplate
      articlesWidget={frontmatter.articlesWidget}
      initialProfile={initialProfile}
      heading={frontmatter.heading}
      subheading={frontmatter.subheading}
      image={frontmatter.image}
      seo={frontmatter.seo}
      profiles={profiles}
      location={location.pathname}
      categories={categories}
    />
  );
};

ManagementPage.propTypes = {
  data: PropTypes.object,
  location: PropTypes.object
};

export default ManagementPage;

export const pageQuery = graphql`
  query ManagementPageTemplate($slug: String) {
    markdownRemark(
      frontmatter: { templateKey: { eq: "about-us-management-page" } }
    ) {
      ...ManagementPageContent
    }
    profile: profileType(
      frontmatter: { templateKey: { eq: "profile" } }
      fields: { slug: { eq: $slug } }
    ) {
      ...ProfileDetails
    }
    profiles: allProfileType(
      filter: {
        frontmatter: {
          templateKey: { eq: "profile" }
          category: { ne: "people" }
        }
        fields: { profiletype: { eq: "management" } }
      }
      sort: { fields: [frontmatter___name], order: ASC }
    ) {
      edges {
        node {
          ...ProfileDetails
        }
      }
    }
  }
`;
