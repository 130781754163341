import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';

import ArticlesWidget from 'src/components/widgets/ArticlesWidget/ArticlesWidget';
import CareersWidget from 'src/components/widgets/CareersWidget';
import Layout from 'src/components/Layout';
import SEO from 'src/components/SEO';
import ManagementPageContent from 'src/components/ManagementPageContent';

export const ManagementPageTemplate = ({
  location,
  seo,
  articlesWidget,
  heading,
  subheading,
  image,
  initialProfile,
  profiles,
  categories
}) => (
  <Layout location={location}>
    <SEO
      title={heading}
      description={seo.metaDescription}
      keywords={seo.focusKeyword}
    />
    <ManagementPageContent
      initialProfile={initialProfile}
      heading={heading}
      subheading={subheading}
      image={image}
      profiles={profiles}
      categories={categories}
    />

    <CareersWidget />

    {articlesWidget && <ArticlesWidget />}
  </Layout>
);

ManagementPageTemplate.defaultProps = {
  articlesWidget: false
};

ManagementPageTemplate.propTypes = {
  articlesWidget: PropTypes.bool,
  initialProfile: PropTypes.object,
  heading: PropTypes.string.isRequired,
  subheading: PropTypes.string,
  image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  seo: PropTypes.shape({
    metaDescription: PropTypes.string,
    focusKeyword: PropTypes.string
  }),
  categories: PropTypes.arrayOf(PropTypes.string),
  profiles: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      position: PropTypes.string.isRequired,
      office: PropTypes.string.isRequired,
      picture: PropTypes.object,
      description: PropTypes.string.isRequired,
      contactDetails: PropTypes.shape({
        email: PropTypes.string,
        phone: PropTypes.string
      }),
      roles: PropTypes.arrayOf(PropTypes.object),
      sectors: PropTypes.arrayOf(PropTypes.object),
      articles: PropTypes.arrayOf(PropTypes.object)
    })
  ),
  location: PropTypes.string.isRequired
};

export default ManagementPageTemplate;

export const ManagementPageQuery = graphql`
  fragment ManagementPageContent on MarkdownRemark {
    frontmatter {
      articlesWidget
      heading
      subheading
      image {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
      seo {
        metaDescription
        focusKeyword
      }
    }
  }
`;
