import React from 'react';
import PropTypes from 'prop-types';

import {
  Header,
  HeaderTitle,
  HeaderText,
  PaddingBlock
} from 'src/components/Block';
import Filters from 'src/components/Filters';
import { useSearch } from 'src/search';
import { ProfilesWithModal } from 'src/profile';
import { ProfilesWithTransition } from 'src/components/Profiles';
import ProfileView from 'src/components/ProfileView';

const TeamPageContent = ({
  heading,
  subheading,
  image,
  initialProfile,
  initialCity,
  profiles,
  profilesIndex,
  profilesStore,
  offices,
  roles,
  sectors
}) => {
  const {
    office,
    setOffice,
    role,
    setRole,
    sector,
    setSector,
    query,
    setQuery,
    results,
    clearFilters
  } = useSearch({ initialCity, profiles, profilesIndex, profilesStore });

  const handleChangeOffice = e => {
    setOffice(e.target.value);
  };

  const handleChangeRole = e => {
    setRole(e.target.value);
  };

  const handleChangeSector = e => {
    setSector(e.target.value);
  };

  const handleChangeQuery = e => {
    setQuery(e.target.value);
  };

  return (
    <ProfilesWithModal initialProfile={initialProfile}>
      {({ modalIsOpen, profile, selectProfile }) => (
        <>
          <div>
            <a name="profile" />
            <ProfileView
              modalIsOpen={modalIsOpen}
              selectProfile={selectProfile}
              results={results}
              profile={profile}
              navigateTo="/team/"
            />
          </div>
          <Header image={image}>
            <HeaderTitle>{heading}</HeaderTitle>
            <HeaderText>{subheading}</HeaderText>
          </Header>
          <Filters
            query={query}
            office={office}
            offices={offices}
            role={role}
            roles={roles}
            sector={sector}
            sectors={sectors}
            handleChangeQuery={handleChangeQuery}
            handleChangeOffice={handleChangeOffice}
            handleChangeRole={handleChangeRole}
            handleChangeSector={handleChangeSector}
            clearFilters={clearFilters}
          />
          <PaddingBlock>
            <ProfilesWithTransition
              results={results}
              selectProfile={selectProfile}
            />
          </PaddingBlock>
        </>
      )}
    </ProfilesWithModal>
  );
};

TeamPageContent.propTypes = {
  initialProfile: PropTypes.object,
  initialCity: PropTypes.string,
  profilesIndex: PropTypes.string,
  profilesStore: PropTypes.object,
  heading: PropTypes.string.isRequired,
  subheading: PropTypes.string,
  image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  profiles: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      position: PropTypes.string.isRequired,
      office: PropTypes.string.isRequired,
      picture: PropTypes.object,
      description: PropTypes.string.isRequired,
      contactDetails: PropTypes.shape({
        email: PropTypes.string,
        phone: PropTypes.string
      }),
      roles: PropTypes.arrayOf(PropTypes.object),
      sectors: PropTypes.arrayOf(PropTypes.object)
    })
  ),
  offices: PropTypes.arrayOf(PropTypes.object),
  roles: PropTypes.arrayOf(PropTypes.object),
  sectors: PropTypes.arrayOf(PropTypes.object)
};

export default TeamPageContent;
