import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import {
  Header,
  HeaderTitle,
  HeaderText,
  PaddingBlock,
  H2
} from 'src/components/Block';
import { ProfilesWithModal } from 'src/profile';
import { Profiles } from 'src/components/Profiles';
import ProfileView from 'src/components/ProfileView';

const Category = styled(H2)`
  text-transform: capitalize;
`;

const ManagementPageContent = ({
  heading,
  subheading,
  image,
  initialProfile,
  profiles,
  categories
}) => {
  return (
    <ProfilesWithModal initialProfile={initialProfile}>
      {({ modalIsOpen, profile, selectProfile }) => (
        <>
          <div>
            <a name="profile" />
            <ProfileView
              modalIsOpen={modalIsOpen}
              selectProfile={selectProfile}
              results={profiles}
              profile={profile}
              navigateTo="/management/"
            />
          </div>
          <Header image={image}>
            <HeaderTitle>{heading}</HeaderTitle>
            <HeaderText>{subheading}</HeaderText>
          </Header>
          <PaddingBlock>
            {categories.map((category, index) => (
              <React.Fragment key={index}>
                <Category>{category.replace(/-/g, ' ')}</Category>
                <Profiles
                  results={profiles.filter(
                    profile => profile.category === category
                  )}
                  selectProfile={selectProfile}
                />
              </React.Fragment>
            ))}
          </PaddingBlock>
        </>
      )}
    </ProfilesWithModal>
  );
};

ManagementPageContent.propTypes = {
  initialProfile: PropTypes.object,
  heading: PropTypes.string.isRequired,
  subheading: PropTypes.string,
  image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  categories: PropTypes.arrayOf(PropTypes.string),
  profiles: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      position: PropTypes.string.isRequired,
      office: PropTypes.string.isRequired,
      picture: PropTypes.object,
      description: PropTypes.string.isRequired,
      contactDetails: PropTypes.shape({
        email: PropTypes.string,
        phone: PropTypes.string
      }),
      roles: PropTypes.arrayOf(PropTypes.object),
      sectors: PropTypes.arrayOf(PropTypes.object)
    })
  )
};

export default ManagementPageContent;
