import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { PreviewCompatibleImage } from 'src/components/PreviewCompatible';
import ArticlesWidget from 'src/components/widgets/ArticlesWidget/ArticlesWidget';
import TeamWidget from 'src/components/widgets/TeamWidget/TeamWidget';
import Layout from 'src/components/Layout';
import SEO from 'src/components/SEO';
import CTA from 'src/components/CTA';
import {
  Header,
  HeaderTitle,
  H2 as BaseH2,
  H4 as BaseH4,
  P
} from 'src/components/Block';

const Text = styled(P)`
  font-size: 1em;
  line-height: 1.6em;
`;

const List = styled.ul`
  list-style: none;
  margin: 50px 0;
  padding: 1em;
  @media screen and (min-width: ${({ theme }) =>
      theme.breakpoints.desktopMin}) {
    padding: 2em 5em;
  }
`;

const Company = styled.li`
  text-align: left;
  display: flex;
  & + li {
    margin-top: 5em;
  }
  @media not screen and (min-width: ${({ theme }) =>
      theme.breakpoints.desktopMin}) {
    flex-wrap: wrap;
  }
  &.right {
    flex-direction: row-reverse;
  }
`;

const H2 = styled(BaseH2)`
  margin: 0.5em 0;
`;

const H4 = styled(BaseH4)`
  font-weight: 300;
  margin-top: 0;
`;

const SectionImg = styled(PreviewCompatibleImage)`
  object-fit: cover;
  width: 100%;
  height: 500px;
`;

const SectionBlock = styled.div`
  display: flex;
  @media screen and (min-width: ${({ theme }) =>
      theme.breakpoints.desktopMin}) {
    padding: 1em 5em;
  }
  @media not screen and (min-width: ${({ theme }) =>
      theme.breakpoints.desktopMin}) {
    flex-wrap: wrap;
  }
`;

const SectionImage = styled.div`
  flex-basis: 100%;
  @media screen and (min-width: ${({ theme }) =>
      theme.breakpoints.desktopMin}) {
    flex-basis: 50%;
  }
`;

const SectionContent = styled.div`
  margin: 1em;
  @media screen and (min-width: ${({ theme }) =>
      theme.breakpoints.desktopMin}) {
    margin: 2em 6em;
    flex-basis: 50%;
  }
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const ContentBlock = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex-basis: 100%;
  @media screen and (min-width: ${({ theme }) =>
      theme.breakpoints.desktopMin}) {
    flex-basis: 50%;
  }
`;

const Logo = styled(PreviewCompatibleImage)`
  width: 50%;
  text-align: center;
  margin: 0 auto;
`;

const LogoBlock = styled.div`
  text-align: center;
  margin: 0 auto;
  flex-basis: 50%;
`;

const TagLine = styled(BaseH2)`
  @media screen and (min-width: ${({ theme }) =>
      theme.breakpoints.desktopMin}) {
    margin: 1em 4em;
  }
`;

export const GroupPageContent = ({ banner, section, group }) => (
  <React.Fragment>
    <Header image={banner.image}>
      <HeaderTitle>{banner.heading}</HeaderTitle>
    </Header>
    <SectionBlock>
      {section.image && (
        <SectionImage>
          <SectionImg
            image={section.image}
            imgStyle={{ objectFit: 'cover', height: '100%', width: '100%' }}
          />
        </SectionImage>
      )}
      <SectionContent>
        <H2>{section.heading}</H2>
        <Text>{section.subheading}</Text>
      </SectionContent>
    </SectionBlock>
    <List>
      {group.companies.map((data, index) => {
        const imagePosition = index % 2 !== 0 ? 'left' : 'right';
        const logo = (
          <LogoBlock>
            <Logo
              image={data.logo}
              imgStyle={{ objectFit: 'contain', width: '100%' }}
            />
            <TagLine>{data.line}</TagLine>
          </LogoBlock>
        );
        return (
          <Company key={index} className={imagePosition}>
            {logo}
            <ContentBlock>
              <H4>{data.title}</H4>
              <P>{data.description}</P>
              {data.calltoaction && (
                <a href={data.link}>
                  <CTA noBorder link>
                    {data.calltoaction}
                  </CTA>
                </a>
              )}
            </ContentBlock>
          </Company>
        );
      })}
    </List>
  </React.Fragment>
);

GroupPageContent.propTypes = {
  banner: PropTypes.shape({
    heading: PropTypes.string.isRequired,
    image: PropTypes.object.isRequired
  }),
  section: PropTypes.shape({
    heading: PropTypes.string.isRequired,
    subheading: PropTypes.string.isRequired,
    image: PropTypes.object
  }),
  group: PropTypes.shape({
    companies: PropTypes.arrayOf(
      PropTypes.shape({
        title: PropTypes.string.isRequired,
        description: PropTypes.string.isRequired,
        line: PropTypes.string,
        logo: PropTypes.object,
        calltoaction: PropTypes.string,
        link: PropTypes.string
      })
    )
  })
};

const GroupPageTemplate = ({
  banner,
  seo,
  section,
  group,
  location,
  articlesWidget
}) => (
  <Layout location={location}>
    <SEO
      title={banner.heading}
      description={seo.metaDescription}
      keywords={seo.focusKeyword}
    />
    <GroupPageContent banner={banner} section={section} group={group} />
    <TeamWidget />
    {articlesWidget && <ArticlesWidget />}
  </Layout>
);

GroupPageTemplate.defaultProps = {
  articlesWidget: false
};

GroupPageTemplate.propTypes = {
  articlesWidget: PropTypes.bool,
  banner: PropTypes.shape({
    heading: PropTypes.string.isRequired,
    image: PropTypes.object.isRequired
  }),
  seo: PropTypes.shape({
    metaDescription: PropTypes.string,
    focusKeyword: PropTypes.string
  }),
  section: PropTypes.shape({
    heading: PropTypes.string.isRequired,
    subheading: PropTypes.string.isRequired,
    image: PropTypes.object
  }),
  group: PropTypes.shape({
    companies: PropTypes.arrayOf(
      PropTypes.shape({
        title: PropTypes.string.isRequired,
        description: PropTypes.string.isRequired,
        line: PropTypes.string,
        logo: PropTypes.object,
        calltoaction: PropTypes.string,
        link: PropTypes.string
      })
    )
  }),
  location: PropTypes.string.isRequired
};

export default GroupPageTemplate;
