import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import Tag from 'src/components/Tag';

const Button = styled(Tag)`
  background-color: transparent;
  margin: 0;
  border-color: ${props => (props.primary ? 'black' : 'white')};
  color: ${props => (props.primary ? 'black' : 'white')};
`;

export const ContactButton = ({ primary, children }) => {
  const [show, setShow] = useState(false);
  const handleClick = () => {
    setShow(true);
  };
  if (show) {
    return <React.Fragment>{children}</React.Fragment>;
  }
  return (
    <Button as="button" onClick={handleClick} primary={primary}>
      Send Email
    </Button>
  );
};

ContactButton.propTypes = {
  primary: PropTypes.bool,
  children: PropTypes.node
};
