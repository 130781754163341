import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';

import ContactPageTemplate from 'src/components/pages/ContactPageTemplate';

const ContactPage = ({ data, location }) => {
  const { frontmatter } = data.markdownRemark;
  const offices = data.offices.edges.map(item => item.node.frontmatter);

  const officesGroups = data.officesGrouped.group.map(group =>
    Object.assign({
      region: group.fieldValue,
      cities: group.edges.map(item => item.node.frontmatter)
    })
  );

  return (
    <ContactPageTemplate
      articlesWidget={frontmatter.articlesWidget}
      heading={frontmatter.heading}
      seo={frontmatter.seo}
      email={frontmatter.email}
      location={frontmatter.location}
      message={frontmatter.message}
      linkedin={frontmatter.linkedinurl}
      firstname={frontmatter.firstname}
      lastname={frontmatter.lastname}
      title={frontmatter.title}
      calltoaction={frontmatter.calltoaction}
      offices={offices}
      officesGroups={officesGroups}
      regions={data.regions.distinct}
      path={location.pathname}
    />
  );
};

ContactPage.propTypes = {
  data: PropTypes.object,
  location: PropTypes.object
};

export default ContactPage;

export const pageQuery = graphql`
  query ContactPageTemplate($region: String) {
    markdownRemark(frontmatter: { templateKey: { eq: "contact-us-page" } }) {
      frontmatter {
        articlesWidget
        heading
        seo {
          metaDescription
          focusKeyword
        }
        email
        firstname
        lastname
        location
        message
        linkedinurl
        calltoaction
        title
      }
    }
    regions: allMarkdownRemark(
      filter: {
        frontmatter: { templateKey: { eq: "office" }, publish: { eq: true } }
      }
    ) {
      distinct(field: frontmatter___region)
    }
    officesGrouped: allMarkdownRemark(
      filter: {
        frontmatter: { templateKey: { eq: "office" }, region: { eq: $region } }
      }
      sort: { fields: [frontmatter___city], order: ASC }
    ) {
      group(field: frontmatter___region) {
        fieldValue
        edges {
          node {
            frontmatter {
              city
              address
              phone
              candidateEmail
              clientEmail
              region
              publish
              image {
                childImageSharp {
                  gatsbyImageData(layout: FULL_WIDTH)
                }
              }
            }
          }
        }
      }
    }
    offices: allMarkdownRemark(
      filter: { frontmatter: { templateKey: { eq: "office" } } }
      sort: { fields: [frontmatter___city], order: ASC }
    ) {
      edges {
        node {
          frontmatter {
            city
          }
        }
      }
    }
  }
`;
