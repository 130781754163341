import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

import {
  PreviewCompatibleImage,
  PreviewCompatibleLink as BasePreviewCompatibleLink
} from 'src/components/PreviewCompatible';
import { H2 as BaseH2, P2 as BaseP } from 'src/components/Block';

const PreviewCompatibleLink = styled(BasePreviewCompatibleLink)`
  text-decoration: none;
  color: initial;
`;

const P = styled(BaseP)`
  @media not screen and (min-width: ${({ theme }) =>
      theme.breakpoints.desktopMin}) {
    margin: 0.5em 0;
  }
`;

const teamBlockStyle = css`
  margin: 1em 0;
  display: flex;
  align-items: center;
`;

const TeamBlock = styled.section`
  ${teamBlockStyle}
  @media screen and (min-width: ${({ theme }) =>
    theme.breakpoints.desktopMin}) {
    ${props =>
      !props.preview &&
      css`
        padding: 0 10em;
      `}
  }
  height: auto;
  justify-content: space-between;
  color: initial;
`;

const TeamContent = styled.div`
  @media not screen and (min-width: ${({ theme }) =>
      theme.breakpoints.desktopMin}) {
    padding: 0 1em;
  }
  @media screen and (min-width: ${({ theme }) =>
      theme.breakpoints.desktopMin}) {
    padding-right: 5em;
    flex-basis: 50%;
  }
`;

const H2 = styled(BaseH2)`
  color: black;
  @media not screen and (min-width: ${({ theme }) =>
      theme.breakpoints.desktopMin}) {
    margin: 0;
  }
`;

const BlockImage = styled(PreviewCompatibleImage)`
  flex-basis: 50%;
`;

const Arrow = styled.img`
  width: 28px;
`;

const TeamWidgetView = ({ title, text, image, imgStyle, preview }) => {
  return (
    <React.Fragment>
      <TeamBlock preview={preview}>
        <TeamContent>
          <P>{text}</P>
          <PreviewCompatibleLink to="/team">
            <H2>
              {title} <Arrow src="/img/arrow-cta.png" />
            </H2>
          </PreviewCompatibleLink>
        </TeamContent>
        <BlockImage
          image={image}
          imgStyle={imgStyle}
          alt="Marlin Hawk's Global Team"
        />
      </TeamBlock>
    </React.Fragment>
  );
};

TeamWidgetView.defaultProps = {
  preview: false
};

TeamWidgetView.propTypes = {
  title: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  preview: PropTypes.bool,
  imgStyle: PropTypes.object
};

export default TeamWidgetView;
