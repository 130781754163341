/* eslint-disable no-console, react/prop-types */

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import ReactModal from 'react-modal';
import Sticky from 'react-stickynode';
import { useMediaQuery } from 'react-responsive';
import { ProfileModal } from 'src/components/ProfileComponent';
import ArticlesWidget from 'src/components/widgets/ArticlesWidget/ArticlesWidget';
import Layout from 'src/components/Layout';
import SEO from 'src/components/SEO';
import { BigH1, H3 as BaseH3 } from 'src/components/Block';
import { PreviewCompatibleLink } from 'src/components/PreviewCompatible';
import BodyContent from 'src/components/BodyContent';
import { HTMLContent } from 'src/components/Content';
import { ProfilesWithTransition } from 'src/components/Profiles';
import { PortfolioList } from 'src/components/pages/PortfolioList';

const Page = styled.div`
  @media screen and (min-width: ${({ theme }) =>
      theme.breakpoints.desktopMin}) {
    max-width: 80%;
  }
  margin: 0 auto 5em;
`;

const Content = styled.div`
  @media screen and (min-width: ${({ theme }) =>
      theme.breakpoints.desktopMin}) {
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
  }
`;

const H1 = styled(BigH1)`
  margin: 0 0 0.5em 0;
`;

const Article = styled.section`
  flex-grow: 1;
  p {
    line-height: 1.5em;
  }
  li {
    margin: 1em 0;
  }
`;

const LeftSideMenu = styled.div`
  @media screen and (min-width: ${({ theme }) =>
      theme.breakpoints.desktopMin}) {
    flex-basis: 16em;
    flex-shrink: 0;
    flex-grow: 0.25;
  }
`;

const Items = styled.div`
  color: ${props => props.theme.secondaryColor};

  margin-top: 2em;
  @media screen and (min-width: ${({ theme }) =>
      theme.breakpoints.desktopMin}) {
    margin-top: 40px;
    margin-right: 4em;
  }
`;

const SubItems = styled.div``;

const Link = styled(PreviewCompatibleLink)`
  text-decoration: none;
  color: inherit;
  position: relative;
  display: inline-block;
`;

const StaffLink = styled(Link)`
  font-weight: 400;
`;

const LinkContainer = styled.div`
  color: ${props => props.theme.primaryColor};
  padding-left: 1em;

  @media screen and (min-width: ${({ theme }) =>
      theme.breakpoints.desktopMin}) {
    padding-left: 0em;
  }

  &.selected {
    color: ${props => props.theme.secondaryColor};
  }
`;

const H3 = styled.h3`
  font-weight: 400;
  font-size: 0.9em;
  margin: 0;
  padding: 0.9em 0;
  position: relative;
  display: flex;

  & a:hover,
  & a:active {
    color: ${props => props.theme.secondaryColor};
  }
`;

const H3Sub = styled(H3)`
  padding: 0.9em 0 0.9em 3em;
  color: ${props => props.theme.secondaryColor};

  & a:hover,
  & a:active,
  &.selected a {
    font-weight: bold;
  }
`;

const ArticleContent = styled.div`
  margin-top: 40px;
  @media screen and (min-width: ${({ theme }) =>
      theme.breakpoints.desktopMin}) {
    flex-basis: 1em;
    flex-grow: 1;
    flex-shrink: 1;
  }
  @media not screen and (min-width: ${({ theme }) =>
      theme.breakpoints.desktopMin}) {
    margin-top: 2em;
    padding: 1em;
  }
`;

const ListTitle = styled(BaseH3)`
  font-weight: 600;
  font-size: 0.9em;
  margin: 0 0 1em;
  padding: 0.5em 0;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid ${props => props.theme.primaryColor};
`;

const ProfileListTitle = styled(ListTitle)`
  font-weight: 600;
  font-size: 0.9em;
  margin: 0 0 1em;
  padding: 0.5em 0;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid ${props => props.theme.primaryColor};
  span {
    color: black;
  }
`;

const TeamBlock = styled.div`
  padding: 0 0 1em;
  margin: 1em 0;
  ${ListTitle} {
    color: ${props => props.theme.primaryColor};
  }
`;

const ContentBlock = styled.div`
  background-color: ${props => props.theme.breadcrumbBgColor};
  padding: 0 1em 1em;
  border-radius: 9px;
  margin: 1em 0;
`;

const baseModalStyle = {
  overflow: 'hidden',
  top: '100px',
  border: 0,
  borderRadius: '3px',
  padding: 0
};

const overlayStyle = {
  backgroundColor: 'rgba(0, 0, 0, 0.3)'
};

export const PageContent = ({
  name,
  html,
  howWeHelp,
  contentComponent,
  profiles,
  loc
}) => {
  const [modalIsOpen, setIsOpen] = useState(false);
  const [selectedProfile, setProfile] = useState(null);
  // console.log('PageContent', {loc, selectedProfile})

  const selectProfile = (profile, e) => {
    e.preventDefault();
    setIsOpen(true);
    setProfile(profile);
  };

  const closeModal = () => {
    setIsOpen(false);
    setProfile(null);
  };

  return (
    <ArticleContent>
      <H1>{name}</H1>
      <Article>
        <BodyContent html={html} contentComponent={contentComponent} />
        {howWeHelp && (
          <ContentBlock>
            <ListTitle>How We Help</ListTitle>
            <BodyContent html={howWeHelp} contentComponent={contentComponent} />
          </ContentBlock>
        )}
      </Article>
      {profiles && profiles.length !== 0 && (
        <TeamBlock>
          <ProfileListTitle>
            <span>Consulting Team</span>
            <span>
              <StaffLink to="/team/">
                Full Staff Directory{' '}
                <img
                  src="/img/arrow-link.png"
                  width="20"
                  style={{ verticalAlign: 'bottom' }}
                />
              </StaffLink>
            </span>
          </ProfileListTitle>
          <ProfilesWithTransition
            results={profiles}
            selectProfile={selectProfile}
          />
          <ReactModal
            style={{
              content: baseModalStyle,
              overlay: overlayStyle
            }}
            isOpen={modalIsOpen}
            onRequestClose={() => closeModal()}
            ariaHideApp={false}
          >
            {selectedProfile && (
              <ProfileModal
                profiles={profiles}
                data={selectedProfile}
                onClose={closeModal}
                onSelect={item => setProfile(item)}
              />
            )}
          </ReactModal>
        </TeamBlock>
      )}
      <PortfolioList loc={loc} title="Client Stories" />
    </ArticleContent>
  );
};

PageContent.propTypes = {
  name: PropTypes.string,
  html: PropTypes.node,
  howWeHelp: PropTypes.node,
  contentComponent: PropTypes.func,
  profiles: PropTypes.arrayOf(PropTypes.object)
};

export const WhatWeDoPageTemplate = ({
  withSubNavigation,
  isTopCategory,
  name,
  category,
  seo,
  html,
  howWeHelp,
  items,
  location,
  profiles,
  articlesWidget
}) => {
  const isDesktopOrLaptop = useMediaQuery({ minWidth: 768 });

  // console.log('WWDPageTemplate', {location, topBreadcrumb, breadcrumb, items, name})

  return (
    <Layout location={location.pathname}>
      <SEO
        title={name}
        description={seo.metaDescription}
        keywords={seo.focusKeyword}
      />
      <Page id="page">
        <Content>
          <LeftSideMenu>
            <Sticky top={60} enabled={isDesktopOrLaptop} bottomBoundary="#page">
              <Items id="side-nav">
                <LinkContainer
                  className={(isTopCategory ? 'selected' : '') + ' ' + 'first'}
                ></LinkContainer>
                {items.map((data, index) => {
                  const hasSubItems = data.items && data.items.length !== 0;
                  const showSubItems =
                    (hasSubItems && name === data.name) ||
                    (category && category.name === data.name);
                  return (
                    <React.Fragment key={index}>
                      <LinkContainer
                        className={
                          (name === data.name || showSubItems
                            ? 'selected'
                            : '') +
                          ' ' +
                          (index === items.length - 1 && !showSubItems
                            ? 'last'
                            : '')
                        }
                      >
                        <H3 className={withSubNavigation ? 'topLevel' : ''}>
                          <Link to={data.slug}>{data.name}</Link>
                        </H3>
                      </LinkContainer>
                      {showSubItems && (
                        <LinkContainer
                          className={index === items.length - 1 ? 'last' : ''}
                        >
                          <SubItems>
                            {data.items.map((item, itemIndex) => (
                              <React.Fragment key={itemIndex}>
                                <H3Sub
                                  className={
                                    (name === item.name ? 'selected' : '') +
                                    ' ' +
                                    (itemIndex === 0 ? 'first' : '') +
                                    ' ' +
                                    (itemIndex === data.items.length - 1 &&
                                    index === items.length - 1
                                      ? 'last'
                                      : '')
                                  }
                                >
                                  <Link to={item.slug}>{item.name}</Link>
                                </H3Sub>
                              </React.Fragment>
                            ))}
                          </SubItems>
                        </LinkContainer>
                      )}
                    </React.Fragment>
                  );
                })}
              </Items>
            </Sticky>
          </LeftSideMenu>
          <PageContent
            name={name}
            html={html}
            howWeHelp={howWeHelp}
            contentComponent={HTMLContent}
            {...{ loc: location?.pathname, profiles }}
          />
        </Content>
      </Page>
      {articlesWidget && <ArticlesWidget />}
    </Layout>
  );
};

WhatWeDoPageTemplate.defaultProps = {
  articlesWidget: false,
  withSubNavigation: false,
  isTopCategory: false,
  topBreadcrumb: ''
};

WhatWeDoPageTemplate.propTypes = {
  articlesWidget: PropTypes.bool,
  name: PropTypes.string.isRequired,
  withSubNavigation: PropTypes.bool,
  isTopCategory: PropTypes.bool,
  topBreadcrumb: PropTypes.string,
  category: PropTypes.shape({
    name: PropTypes.string,
    slug: PropTypes.string
  }),
  seo: PropTypes.shape({
    metaDescription: PropTypes.string,
    focusKeyword: PropTypes.string
  }),
  breadcrumb: PropTypes.string,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      category: PropTypes.string,
      slug: PropTypes.string,
      items: PropTypes.arrayOf(
        PropTypes.shape({
          name: PropTypes.string.isRequired,
          category: PropTypes.string,
          slug: PropTypes.string
        })
      )
    })
  ),
  html: PropTypes.string.isRequired,
  howWeHelp: PropTypes.string,
  location: PropTypes.object.isRequired,
  profiles: PropTypes.arrayOf(PropTypes.object)
};
