import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { PreviewCompatibleImage } from 'src/components/PreviewCompatible';
import { P, H3 } from 'src/components/Block';
import Tag from 'src/components/Tag';
import { CenteredBlockPadding, CenteredBlock } from 'src/components/Block';

const GroupBlock = styled(CenteredBlockPadding)`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 2em 0;
  @media screen and (min-width: ${({ theme }) =>
      theme.breakpoints.desktopMin}) {
    padding: 2em 5em;
  }
`;

const GroupCompanies = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  border-top: 1px solid ${props => props.theme.primaryColor};
  @media not screen and (min-width: ${({ theme }) =>
      theme.breakpoints.desktopMin}) {
    flex-wrap: wrap;
  }
`;

const BlockContent = styled.div`
  flex-basis: 50%;
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  text-align: left;
  padding: 1em;
  margin-top: 1em;
  & + div {
    @media screen and (min-width: ${({ theme }) =>
        theme.breakpoints.desktopMin}) {
      border-left: 1px solid ${props => props.theme.primaryColor};
    }
  }
`;

const CompanyLogo = styled(PreviewCompatibleImage)`
  margin: 1em auto 3em;
`;

const Link = styled.a``;

const GroupWidgetView = ({ companies, title }) => {
  return (
    <GroupBlock>
      <H3>{title}</H3>
      {companies.length !== 0 && (
        <GroupCompanies>
          {companies.map((data, index) => {
            return (
              <BlockContent key={index}>
                <CompanyLogo image={data.logo} imgStyle={{ width: '100%' }} />
                <P>{data.description}</P>
                {data.calltoaction && (
                  <CenteredBlock>
                    <Link href={data.link}>
                      <Tag rounded plain>
                        {data.calltoaction}
                      </Tag>
                    </Link>
                  </CenteredBlock>
                )}
              </BlockContent>
            );
          })}
        </GroupCompanies>
      )}
    </GroupBlock>
  );
};

GroupWidgetView.propTypes = {
  companies: PropTypes.arrayOf(
    PropTypes.shape({
      logo: PropTypes.object
    })
  ),
  title: PropTypes.string
};

export default GroupWidgetView;
