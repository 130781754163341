import React  from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const Button = styled.a`
    display: inline-block;
    margin: 0;
    border-radius: 14px;
    background: ${props => props.theme.secondaryColor};
    color: white;
    font-size: 1.5em;
    padding: 0.6em 1em;
    text-decoration:none;
    font-weight: bold;
`;

export const ApplyButton = ({ label }) => {
    return (
        <Button href="mailto:careers@marlinhawk.com">
            {label}
        </Button>
    );
};

ApplyButton.propTypes = {
    label: PropTypes.string,
};

