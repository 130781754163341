import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Controller, Scene } from 'react-scrollmagic';
import { Timeline } from 'react-gsap';
import { Waypoint } from 'react-waypoint';
import { Link } from 'react-scroll';

import Layout from 'src/components/Layout';
import SEO from 'src/components/SEO';
import OfficesMapWidget from 'src/components/widgets/OfficesMapWidget';
import ServicesWidget from 'src/components/widgets/ServicesWidget/ServicesWidget';
import ArticlesWidget from 'src/components/widgets/ArticlesWidget/ArticlesWidget';
import TestimonialsWidget from 'src/components/widgets/TestimonialsWidget/TestimonialsWidget';
import TeamWidget from 'src/components/widgets/TeamWidget/TeamWidget';
import NumbersWidget from 'src/components/widgets/NumbersWidget/NumbersWidget';
import {
  BasicHeader,
  HeaderTitle as BaseHeaderTitle,
  BaseHeaderText,
  FlexContent,
  PartialBackground,
  DarkBg
} from 'src/components/Block';

const HeaderText = styled(BaseHeaderText)`
  text-align: center;
`;

const SectionText = styled.div`
  display: table-cell;
  vertical-align: middle;
  padding-top: 3em;
`;

const SectionContentBlock = styled(FlexContent)`
  height: 100vh;
  display: table;
  padding: 0 3em 0 0;
  @media screen and (min-width: ${({ theme }) =>
      theme.breakpoints.desktopMin}) {
  }
`;

const HeaderTitle = styled(BaseHeaderTitle)`
  text-align: center;
`;

const Header = styled(BasicHeader)`
  padding: 0;
  @media screen and (min-width: ${({ theme }) =>
      theme.breakpoints.desktopMin}) {
    padding: 0;
  }
  align-items: flex-end;
  @media not screen and (min-width: ${({ theme }) =>
      theme.breakpoints.desktopMin}) {
    background-color: ${props => props.theme.secondaryColor};
  }
  @media screen and (min-width: ${({ theme }) =>
      theme.breakpoints.desktopMin}) {
    background-color: #303136;
  }
  background-position: top left;
  background-size: auto 100vh;
  &::before,
  &::after {
    background-attachment: fixed;
    @media not screen and (min-width: ${({ theme }) =>
        theme.breakpoints.desktopMin}) {
      background-attachment: scroll;
      background-image: none !important;
    }
  }
`;

const HeaderBlock = styled.div`
  @media screen and (min-width: ${({ theme }) =>
      theme.breakpoints.desktopMin}) {
    width: 50%;
  }
`;

const HeaderContent = styled.div``;

const ScrollNavigation = styled.span`
  margin: auto 0;
`;

const Navigation = styled.div`
  position: fixed;
  top: 50%;
  right: 20px;
  a {
    display: block;
    cursor: pointer;
  }
  a + a {
    margin-top: 30px;
  }
`;

const Container = styled.div`
  min-height: 100vh;
  overflow: hidden;
`;

const SectionLink = styled.div`
  border-radius: 50%;
  width: 10px;
  height: 10px;
  border: 1.5px solid ${props => props.theme.primaryColor};
  &.active {
    background-color: ${props => props.theme.navActive};
    border-color: ${props => props.theme.navActive};
  }
`;

const _handleSectionEnter = (e, section, index, setActive) => {
  if (e.currentPosition === 'inside') {
    section.active = true;
    setActive(index);
  }
};

const _handleSectionHide = (e, setShow) => {
  if (e.previousPosition !== 'above') {
    setShow(false);
  }
};

const _handleSectionShow = (e, setShow) => {
  if (!e.previousPosition || e.currentPosition === 'below') {
    setShow(true);
  }
};

export const PageContent = ({ image, sections }) => {
  const [active, setActive] = useState(null);
  const [show, setShow] = useState(null);
  return (
    <React.Fragment>
      <Header
        image={image}
        critical={true}
        fadeIn={false}
        stack={[`linear-gradient(#303136, #34373a)`]}
      >
        <HeaderBlock>
          <HeaderContent>
            <Waypoint onEnter={e => _handleSectionShow(e, setShow)}>
              <div>
                <Controller>
                  {sections.map((section, index) => {
                    return (
                      <Scene duration={1} key={index} triggerHook="onLeave" pin>
                        <Timeline wrapper={<Container />}>
                          <SectionContentBlock id={'section-' + index}>
                            <SectionText>
                              <Waypoint
                                onEnter={e =>
                                  _handleSectionEnter(
                                    e,
                                    section,
                                    index,
                                    setActive
                                  )
                                }
                              />
                              <HeaderTitle>{section.heading}</HeaderTitle>
                              <HeaderText>{section.subheading}</HeaderText>
                            </SectionText>
                          </SectionContentBlock>
                        </Timeline>
                      </Scene>
                    );
                  })}
                </Controller>
              </div>
            </Waypoint>
            <ScrollNavigation style={{ display: show ? 'block' : 'none' }}>
              <Navigation>
                {sections.map((section, index) => {
                  return (
                    <Link
                      key={index}
                      to={'section-' + index}
                      spy={true}
                      smooth={true}
                      duration={500}
                    >
                      <SectionLink
                        className={active === index ? 'active' : ''}
                      />
                    </Link>
                  );
                })}
              </Navigation>
            </ScrollNavigation>
          </HeaderContent>
        </HeaderBlock>
      </Header>
      <Waypoint
        onEnter={e => _handleSectionHide(e, setShow)}
        onLeave={e => _handleSectionShow(e, setShow)}
      />
    </React.Fragment>
  );
};

const IndexPageTemplate = ({
  seo,
  banner,
  sections,
  location,
  articlesWidget
}) => (
  <Layout location={location}>
    <SEO
      title={banner.title}
      description={seo.metaDescription}
      keywords={seo.focusKeyword}
    />
    <PageContent image={banner.image} sections={sections} />

    <DarkBg>
      <ServicesWidget />

      <PartialBackground y="30%">
        <NumbersWidget />
      </PartialBackground>
    </DarkBg>

    <TeamWidget />

    {articlesWidget && <ArticlesWidget separator />}

    <OfficesMapWidget />

    <TestimonialsWidget />

  </Layout>
);

PageContent.propTypes = {
  image: PropTypes.object,
  sections: PropTypes.arrayOf(
    PropTypes.shape({
      heading: PropTypes.string.isRequired,
      subheading: PropTypes.string.isRequired,
      image: PropTypes.object
    })
  )
};

IndexPageTemplate.defaultProps = {
  articlesWidget: false
};

IndexPageTemplate.propTypes = {
  articlesWidget: PropTypes.bool,
  seo: PropTypes.shape({
    metaDescription: PropTypes.string,
    focusKeyword: PropTypes.string
  }),
  banner: PropTypes.shape({
    title: PropTypes.string.isRequired,
    image: PropTypes.object
  }),
  sections: PropTypes.arrayOf(
    PropTypes.shape({
      heading: PropTypes.string.isRequired,
      subheading: PropTypes.string.isRequired,
      image: PropTypes.object
    })
  ),
  location: PropTypes.string.isRequired
};

export default IndexPageTemplate;
