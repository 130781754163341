import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';

import InsightPageTemplate from 'src/components/pages/InsightPageTemplate';

const InsightPage = ({ data, location }) => {
  const { frontmatter } = data.markdownRemark;
  const articles = data.articles.edges.map(item =>
    Object.assign(
      {},
      item.node.frontmatter,
      {
        slug: item.node.frontmatter.link
          ? item.node.frontmatter.link
          : item.node.fields.slug
      },
      {
        text: item.node.frontmatter.featured
          ? item.node.frontmatter.description
          : null
      }
    )
  );

  return (
    <InsightPageTemplate
      articlesWidget={frontmatter.articlesWidget}
      showFilter={frontmatter.showFilter}
      heading={frontmatter.heading}
      seo={frontmatter.seo}
      filter={frontmatter.filter}
      articles={articles}
      categories={data.categories.distinct}
      location={location.pathname}
    />
  );
};

InsightPage.propTypes = {
  data: PropTypes.object,
  location: PropTypes.object
};

export default InsightPage;

export const pageQuery = graphql`
  query InsightPageTemplate($category: String) {
    markdownRemark(frontmatter: { templateKey: { eq: "articles-page" } }) {
      frontmatter {
        articlesWidget
        showFilter
        heading
        seo {
          metaDescription
          focusKeyword
        }
        filter
      }
    }
    categories: allMarkdownRemark(
      filter: {
        frontmatter: { templateKey: { eq: "article" }, publish: { eq: true } }
      }
    ) {
      distinct(field: frontmatter___type)
    }
    articles: allMarkdownRemark(
      filter: {
        frontmatter: {
          templateKey: { eq: "article" }
          type: { eq: $category }
          publish: { eq: true }
        }
      }
      sort: {
        fields: [frontmatter___featured, frontmatter___date]
        order: [DESC, DESC]
      }
    ) {
      edges {
        node {
          fields {
            slug
          }
          frontmatter {
            link
            title
            description
            type
            featured
            calltoaction
            image {
              childImageSharp {
                gatsbyImageData(layout: FULL_WIDTH)
              }
            }
          }
        }
      }
    }
  }
`;
