import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';

import Banner from 'src/components/Banner';

const BannerWidget = () => {
  const data = useStaticQuery(graphql`
    query BannerWidgetQuery {
      widget: markdownRemark(
        frontmatter: { templateKey: { eq: "banner-widget" } }
      ) {
        frontmatter {
          image {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH)
            }
          }
          heading
          subheading
        }
      }
    }
  `);

  return (
    <Banner
      image={data.widget.frontmatter.image}
      heading={data.widget.frontmatter.heading}
      subheading={data.widget.frontmatter.subheading}
    />
  );
};

export default BannerWidget;
