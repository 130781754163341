import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import ArticlesWidget from 'src/components/widgets/ArticlesWidget/ArticlesWidget';
import TestimonialsWidget from 'src/components/widgets/TestimonialsWidget/TestimonialsWidget';
import GroupWidget from 'src/components/widgets/GroupWidget/GroupWidget';
import NumbersWidget from 'src/components/widgets/NumbersWidget/NumbersWidget';
import Layout from 'src/components/Layout';
import SEO from 'src/components/SEO';
import {
  Header,
  PartialBackground,
  DarkBg,
  StyledContent
} from 'src/components/Block';
import { HTMLContent } from 'src/components/Content';
import { PreviewCompatibleBackgroundImage } from 'src/components/PreviewCompatible';

const ContentBlock = styled.div`
  padding: 1em;
  @media screen and (min-width: ${({ theme }) =>
      theme.breakpoints.desktopMin}) {
    padding: 2em;
    width: 60%;
    max-width: 800px;
    margin: 0 auto;
  }
`;

const ValueWidgetItem = styled.div`
  & div:first:child {
    border-radius: 50%;
  }
`;

const AboutPageStyledContent = styled(StyledContent)`
  h1 {
    font-size: 0.9em;
    font-weight: 600;
    line-height: 1.2;
    margin: 0 0 1em;
    padding: 0.5em 0;
  }
`;

export const AboutPageContent = ({ image, html, contentComponent }) => (
  <React.Fragment>
    <Header image={image} height="10em" />
    <ContentBlock>
      <AboutPageStyledContent html={html} contentComponent={contentComponent} />
    </ContentBlock>
  </React.Fragment>
);

AboutPageContent.propTypes = {
  image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  html: PropTypes.node,
  contentComponent: PropTypes.func
};

/* eslint-disable no-console */

// const markdownToHtml = markdown => {
//   const [tag, className, css, text] = markdown.split('#')
//   // const cssArr = css.split(';')
//   return `<${tag} class=${className} style=${css}>${text}</${tag}>`
// }

const AboutPageTemplate = ({
  html,
  image,
  heading,
  seo,
  location,
  articlesWidget,
  groupWidget,
  valueWidget
}) => {
  const [html1, html2] = html.split('[valueWidget]');
  // console.log('AboutUsTemplate', {html1, html2, html, PreviewCompatibleBackgroundImage})

  const Image = styled(PreviewCompatibleBackgroundImage)`
    width: 100% !important;
    height: unset !important;
    aspect-ratio: 1 !important;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    border-radius: 50%;
  `;

  const ImageWrapper = styled.div`
    border-radius: 50%;
    aspect-ratio: 1;
    overflow: hidden;
    & > div {
      width: 100%;
      aspect-ratio: 1;
    }
  `;
  const oneWidgetStyle = {
    display: 'inline-block',
    width: '120px',
    aspectRatio: '1',
    margin: '0 12px 0 0',
    verticalAlign: 'top',
    textAlign: 'center'
    // boxShadow: 'inset 0 0 3px 1px black, 0 0 3px 1px red'
  };
  const values =
    valueWidget?.items.map((item, ix) => {
      const [pre, underital, post] = item.desc.split('*');
      return (
        <ValueWidgetItem className="ik,mikikik" key={ix} style={oneWidgetStyle}>
          <ImageWrapper>
            <Image image={item.image} alt="mm" />
          </ImageWrapper>
          <p style={{ fontWeight: 'normal', fontSize: '100%' }}>{item.head}</p>
          <p style={{ fontWeight: 'normal', fontSize: '87.5%' }}>
            {pre}
            <span style={{ fontStyle: 'italic', textDecoration: 'underline' }}>
              {underital}
            </span>
            {post}
          </p>
        </ValueWidgetItem>
      );
    }) || [];

  return (
    <Layout location={location}>
      <SEO
        title={heading}
        description={seo.metaDescription}
        keywords={seo.focusKeyword}
      />
      <DarkBg>
        {/* <AboutPageContent
          image={image}
          html={htmlFull}
          contentComponent={HTMLContent}
        /> */}

        <Header image={image} height="10em" />
        <ContentBlock>
          <AboutPageStyledContent html={html1} contentComponent={HTMLContent} />
          <div style={{ width: '100%' }}>{values}</div>
          <AboutPageStyledContent html={html2} contentComponent={HTMLContent} />
        </ContentBlock>

        <PartialBackground y="30%" paddingBottom={groupWidget ? null : '5em'}>
          <NumbersWidget />
        </PartialBackground>
      </DarkBg>

      <TestimonialsWidget />

      {groupWidget && <GroupWidget />}

      {articlesWidget && <ArticlesWidget />}
    </Layout>
  );
};

AboutPageTemplate.defaultProps = {
  articlesWidget: false,
  groupWidget: false
};

AboutPageTemplate.propTypes = {
  articlesWidget: PropTypes.bool,
  groupWidget: PropTypes.bool,
  html: PropTypes.node,
  image: PropTypes.object,
  heading: PropTypes.string.isRequired,
  seo: PropTypes.shape({
    metaDescription: PropTypes.string,
    focusKeyword: PropTypes.string
  }),
  location: PropTypes.string.isRequired,
  valueWidget: PropTypes.any
};

export default AboutPageTemplate;
