import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Slide } from 'react-slideshow-image';

import { PreviewCompatibleBackgroundImage } from 'src/components/PreviewCompatible';
import { P } from 'src/components/Block';

import 'react-slideshow-image/dist/styles.css';

const Quote = styled(PreviewCompatibleBackgroundImage)`
  display: flex;
  padding: 1em;
  @media screen and (min-width: ${({ theme }) =>
      theme.breakpoints.desktopMin}) {
    padding: 5em;
  }
  color: white;
  background-color: lightgray;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  background-color: hsla(0, 0%, 0%, 0.5);
  background-blend-mode: overlay;
`;

const QuoteIntro = styled.div`
  text-align: right;
  margin-right: 1em;
  @media screen and (min-width: ${({ theme }) =>
      theme.breakpoints.desktopMin}) {
    flex-grow: 3;
  }
  flex-basis: 0;
`;

const QuoteText = styled.div`
  overflow: hidden;
  font-size: 1.3em;
  flex-grow: 2;
  flex-basis: 0;
`;

const QuoteHeading = styled.div`
  font-size: 10em;
  font-family: serif;
  color: ${props => props.theme.primaryColor};
`;

const BlockQuote = styled.blockquote`
  margin: 0 0 10px 0;
`;

const QuoteBlock = styled.div``;

const StyledSlide = styled(Slide)`
  .react-slideshow-container + ul.indicators {
    justify-content: flex-start;
    padding: 0;
  }
`;

const Indicator = styled.li`
  // Specifity to override default li styles
  &&& {
    padding: 0;
    width: 100px;
    height: 3px;
    border-radius: 0;
    background-color: #a7a7a7;
    margin-right: 10px;

    &:last-child {
      margin-right: 0;
    }

    &.active {
      background-color: ${props => props.theme.primaryColor};
    }
  }
`;

const properties = {
  // eslint-disable-next-line react/display-name -- too complicated to add displayName
  indicators: () => <Indicator />,
  arrows: false
};

const TestimonialItem = ({ data }) => {
  return (
    <QuoteBlock>
      <BlockQuote dangerouslySetInnerHTML={{ __html: data.body }} />
      <P>
        - {data.author}, {data.organization}
      </P>
    </QuoteBlock>
  );
};

TestimonialItem.propTypes = {
  data: PropTypes.shape({
    author: PropTypes.string,
    organization: PropTypes.string,
    body: PropTypes.string
  })
};

const TestimonialsSlideShow = ({ testimonials }) => {
  const testimonialsList = testimonials.map((data, index) => (
    <TestimonialItem data={data} key={index} />
  ));

  if (typeof window !== 'undefined' && !!window.CMS_PREVIEW) {
    return <React.Fragment>{testimonialsList}</React.Fragment>;
  }

  return <StyledSlide {...properties}>{testimonialsList}</StyledSlide>;
};

TestimonialsSlideShow.propTypes = {
  testimonials: PropTypes.array
};

const TestimonialsBlock = ({ testimonials }) => {
  return (
    <React.Fragment>
      <QuoteIntro>
        <QuoteHeading>&#8220;</QuoteHeading>
      </QuoteIntro>
      <QuoteText>
        <TestimonialsSlideShow testimonials={testimonials} />
      </QuoteText>
    </React.Fragment>
  );
};

TestimonialsBlock.propTypes = {
  testimonials: PropTypes.array
};

const TestimonialsWidgetView = ({ testimonials, image }) => {
  if (testimonials.length === 0) {
    return null;
  }

  return (
    <Quote image={image}>
      <TestimonialsBlock testimonials={testimonials} />
    </Quote>
  );
};

TestimonialsWidgetView.propTypes = {
  image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  testimonials: PropTypes.arrayOf(
    PropTypes.shape({
      body: PropTypes.string,
      author: PropTypes.string,
      organization: PropTypes.string
    })
  )
};

export default TestimonialsWidgetView;
