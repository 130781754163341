import React, {useState, useContext} from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { H3 as BaseH3 } from 'src/components/Block';
import {ProfileModal} from 'src/components/ProfileComponent';
import ReactModal from 'react-modal';
import Layout from 'src/components/Layout';
import SEO from 'src/components/SEO';
import {PreviewCompatibleLink} from 'src/components/PreviewCompatible'
import { InvertedTag } from 'src/components/Tag';
import {
  Header,
  HeaderTitle,
  Category,
  CenteredBlock,
  StyledContent,
  TagBlock,
  TopRightButton,
  Link,
  H3,
  PartialBackground
} from 'src/components/Block';
import { IconWithBorder, CrossTextIcon } from 'src/components/TextIcon';
import NextPrevious from 'src/components/NextPrevious';
import Tag, { TagLink } from 'src/components/Tag';
import {
  BreadCrumb,
  BreadCrumbContainer,
  BreadLink
} from 'src/components/Breadcrumb';
import {
  HorizontalSocialMediaWidget,
  VerticalSocialMediaWidget
} from 'src/components/widgets/SocialMediaWidget';
import ArticlesWidget from 'src/components/widgets/ArticlesWidget/ArticlesWidget';
import { HTMLContent } from 'src/components/Content';
import theme from 'src/styles/theme';
import { getMetaImage, getAltImage } from 'src/utils';
import {ProfilesWithTransition} from 'src/components/Profiles';
import {PrimeContext} from 'src/hooks/PrimeContext'

export const StatsContent = styled(StyledContent)`
  margin-top: 4em;
  color: white;
  background-color: ${props => props.theme.secondaryColor};
  padding: 0.3em 1em 2em;
  border-radius: 3px;
  h1 {
    border-color: white;
    margin-bottom: 1em;
    padding-bottom: 0.4em;
  }
  p {
    color: ${props => props.theme.primaryColor};
    font-weight: 600;
    font-size: 1.1em;
    margin: 0;
  }
`;

const Background = styled(PartialBackground)`
  padding-bottom: 2em;
  @media screen and (min-width: ${({ theme }) =>
      theme.breakpoints.desktopMin}) {
    margin-top: -10em;
  }
`;

const Page = styled.div`
  margin: 0 auto;
  @media screen and (min-width: ${({ theme }) =>
      theme.breakpoints.desktopMin}) {
    width: 90%;
  }
  ${props =>
    props.background &&
    css`
      background-color: white;
      border-radius: 3px;
      transform: rotateZ(0);
    `}
`;

export const Content = styled.div`
  padding: ${props => (props.padding ? '5em' : '0')} 1em;
  @media screen and (min-width: ${({ theme }) =>
      theme.breakpoints.desktopMin}) {
    width: 70%;
    margin: 0 auto;
  }
`;

export const CaseStudyContent = styled.div``;

const Location = styled(H3)`
  margin: 0.5em 0;
  font-weight: 500;
  font-size: 1.6em;
  color: white;
`;

const HorizontalSocialMediaWidgetWrapper = styled.div`
  margin: 0 auto;
  @media screen and (min-width: ${({ theme }) =>
      theme.breakpoints.desktopMin}) {
    width: 80%;
  }
  padding-top: 30px;
  padding-bottom: 93px;
`;

const VerticalSocialMediaWidgetWrapper = styled.div`
  float: left;
  margin-left: -59px;
`;

/* eslint-disable no-console, react/prop-types */

// const Link = styled(PreviewCompatibleLink)`
//   text-decoration: none;
//   color: inherit;
//   position: relative;
//   display: inline-block;
// `;

const StaffLink = styled(Link)`
  font-weight: 400;
`;

const ListTitle = styled(BaseH3)`
  font-weight: 600;
  font-size: 0.9em;
  margin: 0 0 1em;
  padding: 0.5em 0;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid ${props => props.theme.primaryColor};
`;


const TeamBlock = styled.div`
  padding: 0 0 1em;
  margin: 1em 0;
  ${ListTitle} {
    color: ${props => props.theme.primaryColor};
  }
`;

const ProfileListTitle = styled(ListTitle)`
  font-weight: 600;
  font-size: 0.9em;
  margin: 0 0 1em;
  padding: 0.5em 0;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid ${props => props.theme.primaryColor};
  span {
    color: black;
  }
`;

const baseModalStyle = {
  overflow: 'hidden',
  top: '100px',
  border: 0,
  borderRadius: '3px',
  padding: 0
};

const overlayStyle = {
  backgroundColor: 'rgba(0, 0, 0, 0.3)'
};

const CaseStudyRelatedContent = ({peopleTags, rolesTags, sectorsTags}) => {
  const {prime} = useContext(PrimeContext) ?? {}
  const {
    profilesExt: primeProfiles = [], rolesExt: primeRoles = [], sectorsExt: primeSectors = []
  } = prime ?? {}

  // const {profilesData, caseStudyData, rolesData, sectorsData} = data ?? {}
  // const {frontmatter: caseStudy, html} = caseStudyData
  // const profiles = profilesData.edges?.map(item => getProfile(item.node)) ?? []
  // const roles = rolesData.edges?.map(item => item.node) ?? []
  // const sectors = sectorsData.edges?.map(item => item.node) ?? []
  // const { next, prev } = pageContext

  const profiles = peopleTags
    ?.map(slug => primeProfiles?.find(prof => prof?.slug?.includes(slug)))
    ?.filter(Boolean) ?? []

  const roles = rolesTags
    ?.map(slug => primeRoles?.find(role => role.slug.includes(slug)))
    ?.filter(Boolean) ?? []

  const sectors = sectorsTags
    ?.map(slug => primeSectors?.find(sector => sector.slug.includes(slug)))
    ?.filter(Boolean) ?? []

  // console.log('caseStudyRelated input', {peopleTags, rolesTags, sectorsTags})
  // console.log('caseStudyRelated prime', {primeProfiles, primeRoles, primeSectors})
  // console.log('caseStudyRelated filtered', {profiles, roles, sectors})

  const [modalIsOpen, setIsOpen] = useState(false)
  const [selectedProfile, setProfile] = useState(null)

  const selectProfile = (profile, e) => {
    e.preventDefault()
    setIsOpen(true);
    setProfile(profile);
  };
  const closeModal = () => {
    setIsOpen(false);
    setProfile(null);
  };

  return (
    <>
      {profiles?.length
        ? <TeamBlock>
            <ProfileListTitle>
              <span style={{fontWeight: 'normal'}}>The Team</span>
              <span>
                <StaffLink to="/team/">
                  Full Staff Directory{' '}
                  <img src="/img/arrow-link.png" width="20" style={{verticalAlign: 'bottom'}} />
                </StaffLink>
              </span>
            </ProfileListTitle>
            <ProfilesWithTransition results={profiles} selectProfile={selectProfile} />
            <ReactModal 
              style={{content: baseModalStyle, overlay: overlayStyle}}
              isOpen={modalIsOpen}
              onRequestClose={() => closeModal()}
              ariaHideApp={false}
            >
              {selectedProfile && (
                <ProfileModal
                  {...{profiles}}
                  data={selectedProfile}
                  onClose={closeModal}
                  onSelect={item => setProfile(item)}
                />
              )}
            </ReactModal>
          </TeamBlock>
        : null  
      }
      {roles?.length
        ? <>
            <StyledContent html={'<h1>Functions</h1>'} contentComponent={HTMLContent} />
            {roles.map((role, ix) =>
              <div key={ix}>
                <PreviewCompatibleLink key={ix} to={role.slug}>
                  <InvertedTag>{role.name}</InvertedTag>
                </PreviewCompatibleLink>
              </div>)
            } 
          </>  
        : null
      }
      
      {sectors?.length
        ? <>
            <StyledContent html={'<h1>Industries</h1>'} contentComponent={HTMLContent} />
            {sectors.map((sector, ix) =>
              <div key={ix}>
                <PreviewCompatibleLink key={ix} to={sector.slug}>
                  <InvertedTag>{sector.name}</InvertedTag>
                </PreviewCompatibleLink>
              </div>)
            }
          </>
        : null    
      }
    </>
  )
}

export const CaseStudyPageTemplate = ({
  title,
  area,
  seo,
  image,
  html,
  prev,
  next,
  location,
  articlesWidget,
  peopleTags,
  rolesTags,
  sectorsTags
}) => {
  const meta = getMetaImage(image);
  // console.log('caseStudy profiles, roles', {profiles, roles})

  return (
    <Layout location={location.pathname}>
      <SEO
        title={title}
        description={seo.metaDescription}
        keywords={seo.focusKeyword}
        meta={meta}
      />
      <BreadCrumb>
        <Page>
          <BreadCrumbContainer>
            <BreadLink text="Home" link="/" />
            /
            <BreadLink text="Portfolio" link="/portfolio/" />
            /
            <BreadLink text={title} basicColor />
          </BreadCrumbContainer>
        </Page>
      </BreadCrumb>
      <Header
        image={image}
        blend="multiply"
        color={theme.darken}
        justify="flex-start"
        overlap
        alt={getAltImage('casestudy', { title })}
      >
        <Content>
          <TagBlock>
            <Category>Case Study</Category>
          </TagBlock>
          <HeaderTitle>{title}</HeaderTitle>
          {area && <Location>{area}</Location>}
        </Content>
      </Header>
      <Background color={theme.breadcrumbBgColor} y="10em">
        <Page background>
          <div>
            <VerticalSocialMediaWidgetWrapper>
              <VerticalSocialMediaWidget url={location.href} />
            </VerticalSocialMediaWidgetWrapper>
            <TopRightButton>
              <Link to="/portfolio/">
                <IconWithBorder>
                  <CrossTextIcon />
                </IconWithBorder>
              </Link>
            </TopRightButton>
            <Content padding>
              <CaseStudyContent>
                <StyledContent html={html} contentComponent={HTMLContent} />
                <CaseStudyRelatedContent {...{peopleTags, rolesTags, sectorsTags}} />
              </CaseStudyContent>
            </Content>
          </div>
          <HorizontalSocialMediaWidgetWrapper>
            <HorizontalSocialMediaWidget url={location.href} />
          </HorizontalSocialMediaWidgetWrapper>
        </Page>
        <Page>
          <NextPrevious prev={prev} next={next} name="Case Study" />
          <CenteredBlock>
            <TagLink to="/portfolio/">
              <Tag rounded plain>
                All case studies
              </Tag>
            </TagLink>
          </CenteredBlock>
        </Page>
      </Background>
      {articlesWidget && <ArticlesWidget />}
    </Layout>
  );
};

CaseStudyPageTemplate.defaultProps = {
  articlesWidget: false
};

CaseStudyPageTemplate.propTypes = {
  stats: PropTypes.string,
  articlesWidget: PropTypes.bool,
  title: PropTypes.string.isRequired,
  area: PropTypes.string,
  seo: PropTypes.shape({
    metaDescription: PropTypes.string,
    focusKeyword: PropTypes.string
  }),
  image: PropTypes.object,
  html: PropTypes.string.isRequired,
  prev: PropTypes.object,
  next: PropTypes.object,
  location: PropTypes.object.isRequired
};

export default CaseStudyPageTemplate;
