import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { useFormik } from 'formik';
import { stringify } from 'query-string';
import Reaptcha from 'reaptcha';

import { bodyFont } from 'src/styles/globalStyle';
import Layout from 'src/components/Layout';
import { HTMLContent } from 'src/components/Content';
import SEO from 'src/components/SEO';
import CTA from 'src/components/CTA';
import {
  ContentBlock,
  BasicHeader,
  HeaderTitle,
  StyledContent,
  H2
} from 'src/components/Block';
import { toHTML } from 'src/utils';
import BodyContent from 'src/components/BodyContent';

const RECAPTCHA_KEY = process.env.SITE_RECAPTCHA_KEY;

const Header = styled(BasicHeader)`
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
  background-color: ${props => props.theme.secondaryColor};
`;

const ColumnContent = styled.div`
  display: flex;
  align-items: flex-start;
  background-color: #f6f6f6;
  @media not screen and (min-width: ${({ theme }) =>
      theme.breakpoints.desktopMin}) {
    flex-wrap: wrap;
  }
`;

const TextContent = styled.div`
  flex-shrink: 1;
  flex-grow: 1;
  flex-basis: 60%;
  @media not screen and (min-width: ${({ theme }) =>
      theme.breakpoints.desktopMin}) {
    flex-basis: 100%;
    margin: 1em;
  }
  @media screen and (min-width: ${({ theme }) =>
      theme.breakpoints.desktopMin}) {
    margin: 1em 5em;
  }
`;

const ActionBar = styled.div`
  text-align: right;
`;

const SubmitButton = styled(CTA)`
  margin: 1.5em 0;
  padding-right: 2em;
  padding-left: 2em;
`;

const ContentBlockFixedWidth = styled(ContentBlock)`
  width: 100%;
  margin: 0;
  @media screen and (min-width: ${({ theme }) =>
      theme.breakpoints.desktopMin}) {
    width: 20em;
    margin-right: 2em;
  }
  padding: 2em;
  border-radius: 3px;
  box-shadow: 0px 5px 20px -10px rgba(0, 0, 0, 0.5);
  background-color: white;
`;

const Terms = styled(BodyContent)`
  font-size: 0.8em;
`;

const Option = styled(BodyContent)`
  display: inline;
  p {
    display: inline;
    margin-left: 10px;
  }
`;

const Label = styled.label`
  display: block;
  margin: 5px 0;
`;

const widgetStyle = css`
  width: 100%;
  background-color: transparent;
  border: none;
  box-sizing: border-box;
  font-size: 0.9em;
  ${bodyFont};
  &::placeholder {
    color: #a0a0a0;
  }
`;

const Input = styled.input`
  ${widgetStyle};
  padding: 8px;
`;

const WidgetBlock = styled.div`
  border-bottom: 1px solid #c0c0c0;
  margin-top: 1em;
  align-self: stretch;
`;

const Form = styled.form``;

const checkStatus = response => {
  if (response.status >= 200 && response.status < 300) {
    return response;
  } else {
    let error = new Error(response.statusText);
    error.response = response;
    throw error;
  }
};

export const PageContent = ({
  slug,
  html,
  heading,
  subheading,
  document,
  image,
  firstname,
  lastname,
  job,
  company,
  email,
  terms,
  calltoaction,
  path,
  options,
  contentComponent
}) => {
  const [submitted, setSubmitted] = useState(
    path.indexOf('download') !== -1 || path.indexOf('success') !== -1
      ? true
      : false
  );
  const [verified, setVerified] = useState(null);
  let values = {
    company: '',
    email: '',
    firstname: '',
    lastname: '',
    job: ''
  };
  options.map(option => {
    values[option.name] = '';
  });
  const formik = useFormik({
    initialValues: values,
    validate: values => {
      const errors = {};
      if (!values.email) {
        errors.email = 'Required';
      }
      if (!values.firstname) {
        errors.firstname = 'Required';
      }
      if (!values.lastname) {
        errors.lastname = 'Required';
      }
      if (!values.job) {
        errors.job = 'Required';
      }

      Object.keys(options).forEach(key => {
        if (options[key].required && !values[options[key].name]) {
          errors[options[key].name] = 'Required';
        }
      });
      if (!verified) {
        if (Object.keys(errors).length === 0) {
          errors.recaptcha = 'Please complete all the required fields above';
        }
      }
      return errors;
    },
    onSubmit: (values, { setSubmitting }) => {
      if (!verified) {
        return;
      }
      values['form-name'] = 'cyber-report';
      values['g-recaptcha-response'] = verified;
      fetch(slug, {
        method: 'POST',
        mode: 'same-origin',
        cache: 'no-cache',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        },
        redirect: 'follow',
        body: stringify(values)
      })
        .then(checkStatus)
        .then(() => {
          setSubmitted(true);
          location.href = document.publicURL;
        })
        .catch(() => {})
        .finally(() => {
          setSubmitting(false);
        });
    }
  });
  const onVerify = recaptchaResponse => {
    setVerified(recaptchaResponse);
    formik.validateForm();
  };
  const onExpire = () => {
    setVerified(null);
    formik.validateForm();
  };
  return (
    <React.Fragment>
      <Header image={image}>
        <HeaderTitle>{heading}</HeaderTitle>
      </Header>
      <ColumnContent>
        <TextContent>
          <H2>{subheading}</H2>
          <StyledContent html={html} contentComponent={contentComponent} />
        </TextContent>
        <ContentBlockFixedWidth>
          {submitted && <div>Thank you for your interest.</div>}
          {!submitted && (
            <Form
              name="cyber-report"
              method="POST"
              data-netlify="true"
              onSubmit={formik.handleSubmit}
              data-netlify-recaptcha="true"
            >
              <input type="hidden" name="form-name" value="cyber-report" />
              <WidgetBlock>
                <Input
                  type="text"
                  placeholder={firstname}
                  name="firstname"
                  onChange={formik.handleChange}
                  value={formik.values.firstname}
                  required
                />
              </WidgetBlock>
              <WidgetBlock>
                <Input
                  type="text"
                  placeholder={lastname}
                  name="lastname"
                  onChange={formik.handleChange}
                  value={formik.values.lastname}
                  required
                />
              </WidgetBlock>
              <WidgetBlock>
                <Input
                  type="text"
                  placeholder={job}
                  name="job"
                  onChange={formik.handleChange}
                  value={formik.values.job}
                  required
                />
              </WidgetBlock>
              <WidgetBlock>
                <Input
                  type="text"
                  placeholder={company}
                  name="company"
                  onChange={formik.handleChange}
                  value={formik.values.company}
                />
              </WidgetBlock>
              <WidgetBlock>
                <Input
                  type="email"
                  placeholder={email}
                  name="email"
                  onChange={formik.handleChange}
                  value={formik.values.email}
                  required
                />
              </WidgetBlock>
              {options &&
                options.map((option, index) => {
                  return (
                    <Label key={index}>
                      <input
                        type="checkbox"
                        name={option.name}
                        required={option.required}
                        checked={formik.values[option.name]}
                        value="on"
                        onChange={formik.handleChange}
                      />
                      <Option
                        key={index}
                        html={toHTML(option.text)}
                        contentComponent={HTMLContent}
                      />
                    </Label>
                  );
                })}
              <Terms html={toHTML(terms)} contentComponent={HTMLContent} />
              <Reaptcha
                sitekey={RECAPTCHA_KEY}
                onVerify={onVerify}
                onExpire={onExpire}
                inject={true}
              />
              {formik.errors.recaptcha ? (
                <div>{formik.errors.recaptcha}</div>
              ) : null}
              <ActionBar>
                <SubmitButton
                  type="submit"
                  primary
                  inverted
                  light
                  noBorder
                  className="no-hover"
                  disabled={formik.isSubmitting}
                >
                  {calltoaction}
                </SubmitButton>
              </ActionBar>
            </Form>
          )}
        </ContentBlockFixedWidth>
      </ColumnContent>
    </React.Fragment>
  );
};

PageContent.propTypes = {
  slug: PropTypes.string,
  html: PropTypes.string,
  document: PropTypes.object,
  image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  heading: PropTypes.string.isRequired,
  subheading: PropTypes.string.isRequired,
  calltoaction: PropTypes.string.isRequired,
  firstname: PropTypes.string.isRequired,
  lastname: PropTypes.string.isRequired,
  job: PropTypes.string.isRequired,
  company: PropTypes.string.isRequired,
  email: PropTypes.string.isRequired,
  terms: PropTypes.string.isRequired,
  path: PropTypes.string.isRequired,
  contentComponent: PropTypes.func,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      text: PropTypes.string,
      required: PropTypes.bool
    })
  )
};

const ReportPageTemplate = ({
  html,
  heading,
  subheading,
  document,
  image,
  seo,
  calltoaction,
  firstname,
  lastname,
  job,
  company,
  email,
  terms,
  showNav,
  path,
  slug,
  options
}) => (
  <Layout location={path} hideTop={!showNav}>
    <SEO
      title={heading}
      description={seo.metaDescription}
      keywords={seo.focusKeyword}
    />
    <PageContent
      slug={slug}
      html={html}
      document={document}
      image={image}
      heading={heading}
      subheading={subheading}
      calltoaction={calltoaction}
      firstname={firstname}
      lastname={lastname}
      path={path}
      email={email}
      job={job}
      company={company}
      terms={terms}
      options={options}
      contentComponent={HTMLContent}
    />
  </Layout>
);

ReportPageTemplate.propTypes = {
  html: PropTypes.string,
  heading: PropTypes.string.isRequired,
  subheading: PropTypes.string.isRequired,
  document: PropTypes.object,
  image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  seo: PropTypes.shape({
    metaDescription: PropTypes.string,
    focusKeyword: PropTypes.string
  }),
  calltoaction: PropTypes.string.isRequired,
  firstname: PropTypes.string.isRequired,
  lastname: PropTypes.string.isRequired,
  job: PropTypes.string.isRequired,
  company: PropTypes.string,
  email: PropTypes.string.isRequired,
  terms: PropTypes.string.isRequired,
  showNav: PropTypes.bool,
  path: PropTypes.string.isRequired,
  slug: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      text: PropTypes.string,
      required: PropTypes.bool
    })
  )
};

export default ReportPageTemplate;
