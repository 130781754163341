import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';

import CareersWidgetView from 'src/components/widgets/CareersWidget/CareersWidgetView';

const CareersWidget = () => {
  const data = useStaticQuery(graphql`
    query CareersWidgetQuery {
      careersDescription: markdownRemark(
        frontmatter: { templateKey: { eq: "careers-widget" } }
      ) {
        frontmatter {
          careers {
            title
            description
          }
          vacancies {
            title
            link
          }
          alwaysShow
          contact
          email
        }
      }
      jobs: allMarkdownRemark(
        filter: {
          frontmatter: { templateKey: { eq: "vacancy" }, publish: { eq: true } }
        }
      ) {
        totalCount
        edges {
          node {
            fields {
              slug
            }
            frontmatter {
              position
              location
            }
          }
        }
      }
    }
  `);

  const frontmatter = data.careersDescription.frontmatter;

  if (!data.jobs.totalCount && !frontmatter.alwaysShow) {
    return null;
  }

  const jobs = data.jobs.edges.map(item =>
    Object.assign({}, item.node.frontmatter, { slug: item.node.fields.slug })
  );

  return (
    <CareersWidgetView
      jobs={jobs}
      careers={frontmatter.careers}
      vacancies={frontmatter.vacancies}
      contact={frontmatter.contact}
      email={frontmatter.email}
      alwaysShow={frontmatter.alwaysShow}
    />
  );
};

export default CareersWidget;
