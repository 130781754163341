import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import MobileDropdown from 'src/components/Header/Dropdown/MobileDropdown';

const MenuButton = styled.div`
  height: 55px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  cursor: pointer;
  &:hover {
    background-color: whitesmoke;
  }
  padding-right: 10px;
  padding-left: 10px;
  border-radius: 3px;
`;

const HamburgerIcon = (
  <img src="/img/hamburger-icon.png" width="20px" height="16px" />
);

const CloseIcon = <img src="/img/close-icon.png" width="20px" height="17px" />;

const MobileMenu = ({
  setShowDropdown,
  showDropdown,
  location,
  showArticles,
  showCaseStudies
}) => (
  <MobileDropdown
    showDropdown={showDropdown === 'mobile'}
    location={location}
    showArticles={showArticles}
    showCaseStudies={showCaseStudies}
  >
    <MenuButton
      onClick={() =>
        setShowDropdown(showDropdown !== 'mobile' ? 'mobile' : null)
      }
      className={showDropdown === 'mobile' ? 'active' : ''}
    >
      {showDropdown === 'mobile' ? CloseIcon : HamburgerIcon}
    </MenuButton>
  </MobileDropdown>
);

MobileMenu.propTypes = {
  location: PropTypes.string.isRequired,
  showDropdown: PropTypes.string,
  setShowDropdown: PropTypes.func.isRequired,
  showArticles: PropTypes.bool,
  showCaseStudies: PropTypes.bool
};

export default MobileMenu;
