import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';

import GroupPageTemplate from 'src/components/pages/GroupPageTemplate';

const GroupPage = ({ data, location }) => {
  const { frontmatter } = data.markdownRemark;

  return (
    <GroupPageTemplate
      articlesWidget={frontmatter.articlesWidget}
      banner={frontmatter.banner}
      seo={frontmatter.seo}
      section={frontmatter.section}
      group={frontmatter.group}
      location={location.pathname}
    />
  );
};

GroupPage.propTypes = {
  data: PropTypes.object,
  location: PropTypes.object
};

export default GroupPage;

export const pageQuery = graphql`
  query GroupPageTemplate {
    markdownRemark(
      frontmatter: { templateKey: { eq: "about-us-group-page" } }
    ) {
      frontmatter {
        articlesWidget
        banner {
          heading
          image {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH)
            }
          }
        }
        seo {
          metaDescription
          focusKeyword
        }
        section {
          heading
          subheading
          image {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH)
            }
          }
        }
        group {
          companies {
            title
            description
            line
            logo {
              childImageSharp {
                gatsbyImageData(layout: FIXED, width: 200)
              }
            }
            calltoaction
            link
          }
        }
      }
    }
  }
`;
