import React from 'react';
import PropTypes from 'prop-types';
import { writeText } from 'clipboard-polyfill';
import ReactTooltip from 'react-tooltip';

import Icon from './Icon';

const WeblinkIcon = ({ url, icon, place }) => {
  const handleCopy = () => {
    writeText(url);
  };

  return (
    <React.Fragment>
      <Icon
        icon={icon}
        dataTip="Link copied to clipboard"
        dataPlace={place}
        tooltip="Get permalink"
      />
      <ReactTooltip
        className="tooltip"
        effect="solid"
        event="click"
        eventOff="mouseleave"
        delayHide={1000}
        afterShow={handleCopy}
      />
    </React.Fragment>
  );
};

WeblinkIcon.defaultProps = {
  place: 'top'
};

WeblinkIcon.propTypes = {
  url: PropTypes.string.isRequired,
  icon: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  place: PropTypes.oneOf(['top', 'bottom', 'left', 'right'])
};

export default WeblinkIcon;
