import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { NavLinkWrapper, StyledLink } from 'src/components/Block';

const DropdownWrapper = styled.div`
  width: 100%;
  z-index: 100;
  background-color: ${props => props.theme.navColor};
  float: left;
  position: absolute;
  left: 0;
  display: ${props => (props.showDropdown ? 'flex' : 'none')};
  flex-direction: column;
  padding-top: 5px;
  padding-bottom: 5px;
  box-shadow: 0px 10px 20px -10px rgba(0, 0, 0, 0.5);
`;

const Row = styled.div`
  padding-left: 1.125em;
  padding-right: 1em;
  padding-bottom: 1em;
  padding-top: 1em;
`;

const RowItem = styled.div`
  font-size: 16px;
  border-bottom: 4px solid transparent;
  &:hover {
    border-color: ${props => props.theme.primaryColor};
  }
`;

const RowSubitem = styled(RowItem)`
  margin-left: 28px;
`;

const Dropdown = styled.div`
  display: none;
  @media not screen and (min-width: ${({ theme }) =>
      theme.breakpoints.desktopMin}) {
    display: block;
  }
  &:hover {
    ${NavLinkWrapper} {
      background-color: ${props => props.theme.navColor};
    }
  }
`;

const MobileDropdown = ({
  showDropdown,
  children,
  showArticles,
  showCaseStudies
}) => {
  /**
   * we stop propagation to detect clicks outside
   * the dropdown in parent elements
   */
  return (
    <Dropdown onClick={e => e.stopPropagation()}>
      {children}
      <DropdownWrapper showDropdown={showDropdown}>
        <Row>
          <StyledLink to="/" activeClassName="active-nav">
            <RowItem>Home</RowItem>
          </StyledLink>
        </Row>
        <Row>
          <StyledLink to="/what-we-do/" activeClassName="active-nav">
            <RowItem>What We Do</RowItem>
          </StyledLink>
        </Row>
        <Row>
          <StyledLink to="/what-we-do/services/" activeClassName="active-nav">
            <RowSubitem>Services</RowSubitem>
          </StyledLink>
        </Row>
        <Row>
          <StyledLink to="/what-we-do/sectors/" activeClassName="active-nav">
            <RowSubitem>Industries</RowSubitem>
          </StyledLink>
        </Row>
        <Row>
          <StyledLink to="/what-we-do/roles/" activeClassName="active-nav">
            <RowSubitem>Functions</RowSubitem>
          </StyledLink>
        </Row>
        {showCaseStudies && (
          <Row>
            <StyledLink to="/portfolio/" activeClassName="active-nav">
              <RowItem>Portfolio</RowItem>
            </StyledLink>
          </Row>
        )}
        <Row>
          <StyledLink to="/about-us/" activeClassName="active-nav">
            <RowItem>About Us</RowItem>
          </StyledLink>
        </Row>
        <Row>
          <StyledLink to="/team/" activeClassName="active-nav">
            <RowItem>Our Team</RowItem>
          </StyledLink>
        </Row>
        {showArticles && (
          <Row>
            <StyledLink to="/articles/" activeClassName="active-nav">
              <RowItem>News & Insights</RowItem>
            </StyledLink>
          </Row>
        )}
        <Row>
          <StyledLink to="/contact-us/" activeClassName="active-nav">
            <RowItem>Contact Us</RowItem>
          </StyledLink>
        </Row>
      </DropdownWrapper>
    </Dropdown>
  );
};

MobileDropdown.propTypes = {
  showDropdown: PropTypes.bool.isRequired,
  children: PropTypes.element.isRequired,
  showArticles: PropTypes.bool,
  showCaseStudies: PropTypes.bool
};

export default MobileDropdown;
