import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { PreviewCompatibleBackgroundImage } from 'src/components/PreviewCompatible';
import { H4 as BaseH4 } from 'src/components/Block';
import { ContactButton } from 'src/components/ContactButton';
import { getAltImage } from 'src/utils';

const BlockText = styled.div`
  padding: 0 2em;
  font-size: 1.2em;
`;

const Content = styled.div`
  min-height: 30em;
  display: flex;
  @media not screen and (min-width: ${({ theme }) =>
      theme.breakpoints.desktopMin}) {
    flex-direction: column;
  }
`;

const Image = styled(PreviewCompatibleBackgroundImage)`
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
`;

const ItemImage = styled.div`
  flex-grow: 1;
  text-align: center;
  font-size: 0;
  @media not screen and (min-width: ${({ theme }) =>
      theme.breakpoints.desktopMin}) {
    height: 155px;
  }
`;

const ItemBlock = styled.div`
  @media screen and (min-width: ${({ theme }) =>
      theme.breakpoints.desktopMin}) {
    flex-basis: 26em;
  }
  padding: 2em 0 4em;
  background-color: ${props =>
    props.primary ? props.theme.primaryColor : props.theme.secondaryColor};
  color: ${props => (props.primary ? 'black' : 'white')};
`;

const Region = styled.div`
  color: ${props =>
    props.primary ? props.theme.secondaryColor : props.theme.primaryColor};
  text-transform: uppercase;
  font-size: 0.5em;
  font-weight: 500;
`;

const H3 = styled.h3`
  margin-top: 0.5em;
  font-size: 1.1em;
  font-weight: 400;
`;

const H4 = styled(BaseH4)`
  font-size: 0.8em;
  font-weight: 500;
  margin: 1.5em 0 0;
  line-height: 1.5em;
`;

const P = styled.p`
  font-size: 0.8em;
  white-space: pre-line;
  margin: 0;
  line-height: 1.5em;
  a {
    color: inherit;
  }
`;

const OfficeItem = ({ primary, data, region }) => (
  <Content>
    <ItemImage>
      {data.image && (
        <Image image={data.image} alt={getAltImage('office', data)} />
      )}
    </ItemImage>
    <ItemBlock primary={primary}>
      <BlockText>
        <Region primary={primary}>{region}</Region>
        <H3>{data.city}</H3>
        <H4>Address</H4>
        <P>{data.address}</P>
        {data.phone && (
          <React.Fragment>
            <H4>Phone</H4>
            <P>{data.phone}</P>
          </React.Fragment>
        )}
        <H4>Client Enquiry</H4>
        <P>
          <ContactButton primary={primary}>
            <a href={'mailto:' + data.clientEmail}>{data.clientEmail}</a>
          </ContactButton>
        </P>
        <H4>Candidate Enquiry</H4>
        <P>
          <ContactButton primary={primary}>
            <a href={'mailto:' + data.candidateEmail}>{data.candidateEmail}</a>
          </ContactButton>
        </P>
      </BlockText>
    </ItemBlock>
  </Content>
);

OfficeItem.defaultProps = {
  primary: false
};

OfficeItem.propTypes = {
  data: PropTypes.object,
  primary: PropTypes.bool,
  region: PropTypes.string
};

export default OfficeItem;
