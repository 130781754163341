import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Layout from 'src/components/Layout';
import SEO from 'src/components/SEO';
import {
    Header,
    DarkBg,
    StyledContent
} from 'src/components/Block';
import { HTMLContent } from 'src/components/Content';
import {ApplyButton} from 'src/components/ApplyButton';

const StickyHeader = styled.div`
    background: rgb(243, 240, 240);
    @media screen and (min-width: ${({ theme }) => theme.breakpoints.desktopMin}) {
        position: sticky;
        top: -90px;
        
    }
`

const JobOfferHeader = styled.div`
    border-bottom: 1px solid ${props => props.theme.primaryColor};
`

const JobOfferHeaderContent = styled.div`
    margin: 1em 2em 1em;
    
    @media screen and (min-width: ${({ theme }) => theme.breakpoints.desktopMin}) {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin: 1em 4em 1em;
    }
`

const JobOfferH1 = styled.h1`
    margin: 1em 2em 0em 0;
    padding: 0px 0px 1em;
    font-size: 1.5em;
    
    & span {
        margin-top: 0.3em;
        display: block;
        font-weight: normal;
    }
`

const JobDetails = styled.div`
    font-size: 1.3em;
    margin-bottom: 1em;
    & span {
        color: ${props => props.theme.primaryColor};
        font-size: 1.4em;
    }
`

const ContentBlock = styled.div`
  padding: 1em;
  @media screen and (min-width: ${({ theme }) => theme.breakpoints.desktopMin}) {
    padding: 2em;
    width: 60%;
    max-width: 800px;
    margin: 0 auto;
  }
`;

export const StyledContentCareer = styled(StyledContent)`
  & h1 {
    font-size: 1.3em;
  }

  & h3 {
    font-size: 1.5em;
    font-weight: normal;
    line-height: 2em;
    margin-bottom: 0.5em;
    color: ${props => props.theme.secondaryColor};
  }
`


export const JoinUsVacancyPageContent = ({ name, subname, jobType, level, image, html, contentComponent }) => (
  <React.Fragment>
    <StickyHeader>
        <Header image={image} height="10em" />
        <JobOfferHeader>
          <JobOfferHeaderContent>
              <JobOfferH1>
                {name}
                {subname && <span>{subname}</span>}
              </JobOfferH1>
              <ApplyButton label={subname ? 'Get In Touch' : 'Apply'} />
          </JobOfferHeaderContent>
        </JobOfferHeader>
    </StickyHeader>
    <ContentBlock>
        {jobType && level && <JobDetails>{jobType} <span>|</span> {level}</JobDetails>}
      <StyledContentCareer html={html} contentComponent={contentComponent} />
    </ContentBlock>
  </React.Fragment>
);

JoinUsVacancyPageContent.propTypes = {
  name: PropTypes.string,
  subname: PropTypes.string,
  jobType: PropTypes.string,
  level: PropTypes.string,
  image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  html: PropTypes.node,
  contentComponent: PropTypes.func
};

const JoinUsVacancyPageTemplate = ({
  html,
  image,
  heading,
  seo,
  location,
  jobType,
  level,
  subname,
  name
}) => (
  <Layout location={location}>
     <SEO
       title={heading}
       description={seo.metaDescription}
       keywords={seo.focusKeyword}
     />
     <DarkBg>
       <JoinUsVacancyPageContent
         subname={subname}
         name={name}
         jobType={jobType}
         level={level}
         image={image}
         html={html}
         contentComponent={HTMLContent}
       />
     </DarkBg>
  </Layout>
);

JoinUsVacancyPageTemplate.defaultProps = {
  articlesWidget: false,
  groupWidget: false
};

JoinUsVacancyPageTemplate.propTypes = {
  articlesWidget: PropTypes.bool,
  groupWidget: PropTypes.bool,
  html: PropTypes.node,
  subname: PropTypes.string,
  name: PropTypes.string,
  jobType: PropTypes.string,
  level: PropTypes.string,
  image: PropTypes.object,
  heading: PropTypes.string.isRequired,
  seo: PropTypes.shape({
    metaDescription: PropTypes.string,
    focusKeyword: PropTypes.string
  }),
  location: PropTypes.string.isRequired
};

export default JoinUsVacancyPageTemplate;
