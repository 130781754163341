import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { ProfileCardLink } from 'src/components/ProfileCard';
import FilterTransition, { List } from 'src/components/FilterTransition';

const Item = styled.li`
  margin: 10px;
  width: 100%;
  @media screen and (min-width: ${({ theme }) =>
      theme.breakpoints.desktopMin}) {
    width: 13em;
  }
  a {
    text-decoration: none;
    color: black;
  }
`;

const ProfileItem = ({ data, selectProfile }) => (
  <Item>
    <ProfileCardLink
      secondary
      data={data}
      onClick={(e) => selectProfile(data, e)}
    ></ProfileCardLink>
  </Item>
);

ProfileItem.propTypes = {
  data: PropTypes.object,
  selectProfile: PropTypes.func
};

const ProfilesWithTransition = ({ results, selectProfile }) => (
  <FilterTransition>
    {results.map(data => {
      return (
        <ProfileItem
          key={data.slug}
          data={data}
          selectProfile={selectProfile}
        />
      );
    })}
  </FilterTransition>
);

ProfilesWithTransition.propTypes = {
  selectProfile: PropTypes.func,
  results: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      position: PropTypes.string.isRequired,
      office: PropTypes.string.isRequired,
      picture: PropTypes.object
    })
  )
};

const Profiles = ({ results, selectProfile }) => (
  <List>
    {results.map(data => (
      <ProfileItem key={data.slug} data={data} selectProfile={selectProfile} />
    ))}
  </List>
);

Profiles.propTypes = {
  selectProfile: PropTypes.func,
  results: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      position: PropTypes.string.isRequired,
      office: PropTypes.string.isRequired,
      picture: PropTypes.object
    })
  )
};

export { ProfilesWithTransition, ProfileItem, Profiles };
