import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

import { tree } from 'src/utils';

export const SearchBar = styled.div`
  @media screen and (min-width: ${({ theme }) =>
      theme.breakpoints.desktopMin}) {
    padding: 1em 5em;
  }
  @media not screen and (min-width: ${({ theme }) =>
      theme.breakpoints.desktopMin}) {
    margin: 1em;
    padding: 0 1em 1em;
  }
`;

const widgetStyle = css`
  box-sizing: border-box;
  font-size: 0.9em;
  height: 40px;
  max-width: 100%;
  border: 1px solid white;
  color: black;
  border-color: ${props => props.theme.inputColor};
  flex-grow: 1;
  padding: 8px;
  border-radius: 0;
  outline: none;
  @media screen and (min-width: ${({ theme }) =>
      theme.breakpoints.desktopMin}) {
    margin-right: 1em;
    margin-bottom: 1em;
  }
  @media not screen and (min-width: ${({ theme }) =>
      theme.breakpoints.desktopMin}) {
    flex-basis: 100%;
    margin: 1em 0 0;
  }
  &:focus {
    border-color: ${props => props.theme.primaryColor};
  }
`;

const inputWidgetStyle = css`
  ${widgetStyle};
`;

const Input = styled.input`
  ${inputWidgetStyle};
`;

const Select = styled.select`
  ${widgetStyle};
  &:invalid {
    color: gray;
  }
  &:not(:invalid)::-ms-value {
    color: ${props => props.theme.inputColor};
  }
  -webkit-appearance: none; /* Webkit */
  -moz-appearance: none; /* FF */
  -ms-appearance: none; /* Edge */
  appearance: none; /* Future */
  // IE11
  &::-ms-expand {
    display: none;
  }
  padding-right: 2em;
  background-repeat: no-repeat;
  background-position: right 5px center;
  background-size: 1em;
  background-image: url('/img/dropdown.png');
  &:focus {
    background-image: url('/img/dropdown-primary.png');
  }
`;

const Option = styled.option`
  background-color: white;
  &:disabled {
    color: gray;
  }
`;

const Optgroup = styled.optgroup`
  color: black;
  background-color: white;
`;

const Form = styled.form`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
`;

const ClearButton = styled.button`
  ${widgetStyle};
  text-transform: uppercase;
  border: 1px solid ${props => props.theme.inputColor};
  border-radius: 24px;
  padding: 0 1em;
  background-color: transparent;
  color: ${props => props.theme.primaryColor};
  font-size: 16px;
  line-height: 32px;
  &::before {
    content: '\u00D7';
    font-size: 32px;
    line-height: 28px;
    font-weight: 100;
    vertical-align: top;
    margin-right: 0.2em;
  }
`;

const NameField = ({ query, handleChangeQuery }) => (
  <Input
    type="text"
    placeholder="Search by name…"
    value={query}
    onChange={handleChangeQuery}
  />
);

NameField.propTypes = {
  query: PropTypes.string,
  handleChangeQuery: PropTypes.func
};

const LocationField = ({ office, handleChangeOffice, offices }) => (
  <Select value={office} onChange={handleChangeOffice} required>
    <Option value="" disabled hidden>
      Locations
    </Option>
    {offices.map((group, groupIndex) => {
      return (
        <Optgroup key={groupIndex} label={group.region}>
          {group.cities.map((item, index) => {
            return (
              <Option key={index} value={item.namekey}>
                {item.city}
              </Option>
            );
          })}
        </Optgroup>
      );
    })}
  </Select>
);

LocationField.propTypes = {
  office: PropTypes.string,
  offices: PropTypes.array,
  handleChangeOffice: PropTypes.func
};

const SelectField = ({ name, value, values, handleChange }) => (
  <Select value={value} onChange={handleChange} required>
    <Option value="" disabled hidden>
      {name}
    </Option>
    {tree(values).map((element, index) => {
      if (!element.items) {
        return (
          <Option key={index} value={element.name}>
            {element.name}
          </Option>
        );
      }
      return (
        <Optgroup key={index} value={element.name} label={element.name}>
          {element.items.map((data, index) => {
            return (
              <Option key={index} value={data.name}>
                {data.name}
              </Option>
            );
          })}
        </Optgroup>
      );
    })}
  </Select>
);

SelectField.propTypes = {
  name: PropTypes.string,
  value: PropTypes.string,
  values: PropTypes.array,
  handleChange: PropTypes.func
};

const Filters = ({
  query,
  office,
  offices,
  role,
  roles,
  sector,
  sectors,
  handleChangeQuery,
  handleChangeOffice,
  handleChangeRole,
  handleChangeSector,
  clearFilters
}) => {
  const handleSubmit = event => {
    event.preventDefault();
  };

  const handleKeyPress = event => {
    if (event.which === 13) {
      event.preventDefault();
    }
  };

  return (
    <SearchBar>
      <Form onSubmit={handleSubmit} onKeyPress={handleKeyPress}>
        <NameField query={query} handleChangeQuery={handleChangeQuery} />
        <LocationField
          office={office}
          offices={offices}
          handleChangeOffice={handleChangeOffice}
        />
        <SelectField
          name="Functions"
          value={role}
          values={roles}
          handleChange={handleChangeRole}
        />
        <SelectField
          name="Industries"
          value={sector}
          values={sectors}
          handleChange={handleChangeSector}
        />
      </Form>
      <ClearButton
        style={{
          visibility: query || office || role || sector ? 'visible' : 'hidden'
        }}
        onClick={() => clearFilters()}
      >
        Clear filters
      </ClearButton>
    </SearchBar>
  );
};

Filters.propTypes = {
  query: PropTypes.string,
  office: PropTypes.string,
  offices: PropTypes.arrayOf(PropTypes.object),
  role: PropTypes.string,
  roles: PropTypes.arrayOf(PropTypes.object),
  sector: PropTypes.string,
  sectors: PropTypes.arrayOf(PropTypes.object),
  handleChangeQuery: PropTypes.func,
  handleChangeOffice: PropTypes.func,
  handleChangeRole: PropTypes.func,
  handleChangeSector: PropTypes.func,
  clearFilters: PropTypes.func
};

export default Filters;
