import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';

import OfficesMapWidgetView from 'src/components/widgets/OfficesMapWidget/OfficesMapWidgetView';

const OfficesMapWidget = () => {
  const getDetailsForOffice = (region, city, allRegions) => {
    let office;

    for (office of allRegions[region]) {
      if (office.city === city) {
        return office;
      }
    }

    return null;
  };

  /* eslint-disable no-console */

  const filterRegions = (allRegions, regionsStructure) => {
    const filteredRegions = [];
    regionsStructure.forEach(({ region, offices }) => {
      const officesDetails = [];
      offices.forEach(office => {
        const officeDetails = getDetailsForOffice(region, office, allRegions);
        if (officeDetails !== null) {
          officesDetails.push(officeDetails);
        }
      });
      filteredRegions.push({ region: region, offices: officesDetails });
    });
    // console.log({allRegions, regionsStructure, filteredRegions})
    return filteredRegions;
  };
  
  const createManyOffices = (allRegions, regionsStructure) => {
    const manyOffices = []
    regionsStructure.forEach(({ region, offices }) => {
      offices.forEach(office => {
        const officeDetails = getDetailsForOffice(region, office, allRegions)
        officeDetails && manyOffices.push(officeDetails)
      })
    })
    console.log({allRegions, regionsStructure, manyOffices})
    return manyOffices
  }

  const data = useStaticQuery(graphql`
    query OfficesMapQuery {
      regions: allMarkdownRemark(
        filter: {
          frontmatter: { templateKey: { eq: "office" }, publish: { eq: true } }
        }
      ) {
        group(field: frontmatter___region) {
          fieldValue
          edges {
            node {
              frontmatter {
                city
                address
                phone
                gmapslink
              }
            }
          }
        }
      }
      offices: allMarkdownRemark(
        filter: { frontmatter: { templateKey: { eq: "offices-map-widget" } } }
      ) {
        edges {
          node {
            frontmatter {
              image {
                childImageSharp {
                  gatsbyImageData(layout: FULL_WIDTH)
                }
              }
              offices {
                region
                offices
              }
            }
          }
        }
      }
    }
  `);

  const allRegions = {};
  data.regions.group.forEach(region => {
    allRegions[region.fieldValue] = region.edges.map(
      edge => edge.node.frontmatter
    );
  });
  const regionsStructure = data.offices.edges[0].node.frontmatter.offices;
  const offices = filterRegions(allRegions, regionsStructure);
  const manyOffices = createManyOffices(allRegions, regionsStructure);
  const image = data.offices.edges[0].node.frontmatter.image;

  return (
    <OfficesMapWidgetView
      regions={Object.keys(allRegions)}
      offices={offices}
      manyOffices={manyOffices}
      image={image}
    />
  );
};

export default OfficesMapWidget;
