import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import {
  PreviewCompatibleImage,
  PreviewCompatibleLink
} from 'src/components/PreviewCompatible';
import BaseCTA from 'src/components/CTA';
import { CenteredBlock, H2, H4 as BaseH4, P } from 'src/components/Block';
import { getAltImage } from 'src/utils';

const H4 = styled(BaseH4)`
  font-size: 1.5em;
  font-weight: 500;
  margin: 10px 0;
  color: ${props => props.theme.textColor};
`;

const Services = styled.section`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  padding: 5px;
`;

const ServiceBlock = styled(PreviewCompatibleLink)`
  text-decoration: none;
  margin: 10px;
  text-align: center;
  padding: 20px 50px;
  width: 250px;
  &:hover {
    background-color: rgb(255, 255, 255, 0.3);
    box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.25);
    border-radius: 3px;
  }
`;

const CTA = styled(BaseCTA)`
  visibility: hidden;
  text-transform: none;
  font-weight: 600;
  color: ${props => props.theme.accentColor};

  &:not(.no-hover):hover {
    color: ${props => props.theme.accentColor};
  }

  ${ServiceBlock}:hover & {
    visibility: visible;
  }
`;

const ServiceContent = styled.div`
  color: ${props => props.theme.textColor};
`;

const ServiceImage = styled(PreviewCompatibleImage)`
  margin: 0 auto;
  width: 50px;
`;

const Content = styled.div`
  padding-top: 2em;
`;

const ServicesWidgetView = ({ services, heading }) => (
  <Content>
    <CenteredBlock>
      <H2>{heading}</H2>
    </CenteredBlock>
    <Services>
      {services.map((data, index) => {
        return (
          <ServiceBlock to={data.slug} key={index}>
            <ServiceContent>
              {data.image && data.image.childImageSharp && (
                <ServiceImage
                  image={data.image}
                  alt={getAltImage('service', data)}
                />
              )}
              <H4>{data.name}</H4>
              <P>{data.bodySummary}</P>
              <CTA noBorder>Read more</CTA>
            </ServiceContent>
          </ServiceBlock>
        );
      })}
    </Services>
  </Content>
);

ServicesWidgetView.propTypes = {
  heading: PropTypes.string,
  services: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      bodySummary: PropTypes.string.isRequired,
      image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
      slug: PropTypes.string.isRequired
    })
  )
};

export default ServicesWidgetView;
