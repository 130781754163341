import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';

import IndexPageTemplate from 'src/components/pages/IndexPageTemplate';

const IndexPage = ({ data, location }) => {
  const { frontmatter } = data.markdownRemark;

  return (
    <IndexPageTemplate
      articlesWidget={frontmatter.articlesWidget}
      seo={frontmatter.seo}
      banner={frontmatter.banner}
      sections={frontmatter.sections}
      location={location.pathname}
    />
  );
};

IndexPage.propTypes = {
  data: PropTypes.object,
  location: PropTypes.object
};

export default IndexPage;

export const pageQuery = graphql`
  query IndexPageTemplate {
    markdownRemark(frontmatter: { templateKey: { eq: "index-page" } }) {
      frontmatter {
        articlesWidget
        banner {
          title
          image {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH)
            }
          }
        }
        seo {
          metaDescription
          focusKeyword
        }
        sections {
          heading
          subheading
        }
      }
    }
  }
`;
