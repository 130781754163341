import React from 'react';
import PropTypes from 'prop-types';

import Content from 'src/components/Content';

const BodyContent = ({ html, contentComponent, ...props }) => {
  const MarkdownContent = contentComponent || Content;
  return <MarkdownContent content={html} {...props} />;
};

BodyContent.propTypes = {
  contentComponent: PropTypes.func,
  html: PropTypes.string
};

export default BodyContent;
