import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';

import VacancyPageTemplate from 'src/components/pages/VacancyPageTemplate';

const VacancyPage = ({ data, location }) => {
  const { frontmatter, html } = data.markdownRemark;

  return (
    <VacancyPageTemplate
      articlesWidget={frontmatter.articlesWidget}
      image={data.page.frontmatter.image}
      position={frontmatter.position}
      location={frontmatter.location}
      description={frontmatter.description}
      html={html}
      path={location}
    />
  );
};

VacancyPage.propTypes = {
  data: PropTypes.object,
  location: PropTypes.object
};

export default VacancyPage;

export const pageQuery = graphql`
  query VacancyByName($id: String!) {
    markdownRemark(
      frontmatter: { templateKey: { eq: "vacancy" } }
      id: { eq: $id }
    ) {
      html
      frontmatter {
        articlesWidget
        position
        location
        description
      }
    }
    page: markdownRemark(
      frontmatter: { templateKey: { eq: "about-us-vacancies-page" } }
    ) {
      frontmatter {
        image {
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH)
          }
        }
      }
    }
  }
`;
